import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Storage } from 'aws-amplify';
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import ViewButton from '../../../../components/common/buttons/ViewButton';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import { ConvertEnumToArrayKeyValue, ConvertResponseForSelect, ConvertResponseForSelectAttributes, ObjectToArray } from '../../../../components/helper/commonfunctions';
import { PARAMETER_IDs, QUARTERS_NAME } from '../../../../components/helper/enum';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { Country, Employee, Evaluation, FinancialYear, InitiateEvaluation, Relationship, RelationshipDetails, Roles, SBU, TargetDetails, Targets } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import EvaluationEmployeePopUp from '../employeeEvaluation';
import { sumFromArray } from '../../../../components/helper/maths';
import { commaSeparated } from '../../../../components/helper/maths';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'

export default function CurrentYearTeam_Report() {

    let selectedTabEvaluation = reactLocalStorage.get('selectedTabEvaluation')
    var loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId');
    let selectedYear = reactLocalStorage.getObject('selectedYear');


    const navigateTo = useNavigate()

    const [myTeam, setMyTeam] = useState([]);
    const [renderItems, setRenderItems] = useState(false);

    //Financial Year
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    // states
    const [quarter, setQuarter] = useState("");
    const [quarterList, setQuarterList] = useState("");
    const [viewEmpEvaluation, setViewEmpEvaluation] = useState(false);


    // set Employee 
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    const [selectedEmployeeName, setSelectedEmployeeName] = useState("");
    const [selectedEmployeeProfilePic, setSelectedEmployeeProfilePic] = useState("");
    const [selectedEmployeeCode, setSelectedEmployeeCode] = useState("");
    const [selectedEmployeeSBU, setSelectedEmployeeSBU] = useState("");
    const [selectedEmployeeRole, setSelectedEmployeeRole] = useState("");
    const [selectedEmployeeRegion, setSelectedEmployeeRegion] = useState("");
    const [selectedEmployeeCountry, setSelectedEmployeeCountry] = useState("");
    const [selectedEmployeeRoleId, setSelectedEmployeeRoleId] = useState([]);
    const [selectedParentEmployeeName, setSelectedParentEmployeeName] = useState([]);


    //onclick financial year
    const onFinancialSelectChange = async (e) => {
        setFinancialYear({ label: e.label, value: e.value })
    }

    async function onLoad() {
        //set financial year
        let selectedQuarter = reactLocalStorage.getObject('selectedQuarter');
        let selectedYear = reactLocalStorage.getObject('selectedYear');

        let date = moment().format("MM-DD")
        let currentYear = moment().year()
        let previousYear = moment().year() - 1
        let nextYear = moment().year() + 1
        let FinalYearResult = ""
        if ("03-31" < date) {
            FinalYearResult = currentYear + "-" + nextYear
        }
        else {
            FinalYearResult = previousYear + "-" + currentYear
        }
        // var responses = await DataStore.query(FinancialYear, (c) => c.name.contains(FinalYearResult));

        var responses = await graphQLGetAllData('listFinancialYears', [{ name: { contains: FinalYearResult } }]);

        if (selectedYear.value !== undefined) {
            setFinancialYear({ label: selectedYear.label, value: selectedYear.value })
        } else {
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
        }


        //get financial year
        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        responses = await graphQLGetAllData('listFinancialYears');

        responses = responses.sort((a, b) => a.name.localeCompare(b.name))
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);

        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }

        //setQuarter
        if (Object.keys(selectedQuarter).length !== 0) {
            setQuarter(selectedQuarter)
        }

        //Initialized QuarterList for user
        // var checkInitEvaluation = await DataStore.query(InitiateEvaluation, (c) => c.employee_id.eq(loggedEmployeeId));

        // if (checkInitEvaluation.length > 0) {
        //     let finalData = []
        //     Object.keys(checkInitEvaluation).map(async (currentItem, i) => {

        //         let loopItem = checkInitEvaluation[currentItem];
        //         let data = ObjectToArray(loopItem);

        //         var evaluationResponses = await DataStore.query(Evaluation, (c) => c.and(c => [
        //             c.financial_year_id.eq(selectedYear.value),
        //             c.id.eq(data["evaluation_id"]),
        //         ]));

        //         data["name"] = "Quarter " + evaluationResponses[0].quaterNo;
        //         data["id"] = evaluationResponses[0].quaterNo;
        //         finalData.push(data)

        //         if (i === checkInitEvaluation.length - 1) {
        //             var myArray = []; myArray = ConvertResponseForSelectAttributes(finalData);
        //             console.log(myArray)
        //             setQuarterList(myArray);
        //         }
        //     })
        // }

        //QuarterList
        var myQuarterArray = []; myQuarterArray = ConvertEnumToArrayKeyValue(QUARTERS_NAME);;
        setQuarterList(myQuarterArray)
    }

    //Table View button
    const actionBodyTemplate = (selectedEmployee) => {
        return (
            <div className="flex justify-center">
                <Link onClick={() => openEmployeeEvaluation(selectedEmployee)} ><ViewButton /></Link></div>
        )
    }

    const openEmployeeEvaluation = async (selectedEmployee) => {
        setSelectedEmployeeId(selectedEmployee.id)
        setSelectedParentEmployeeName(selectedEmployee.parentEmployeeName)
        setSelectedEmployeeName(selectedEmployee.employeeName)
        setSelectedEmployeeProfilePic(selectedEmployee.profilePic)
        setSelectedEmployeeCode(selectedEmployee.employeeId)
        setSelectedEmployeeSBU(selectedEmployee.sbu)
        setSelectedEmployeeRole(selectedEmployee.roleName)
        setSelectedEmployeeRoleId(selectedEmployee.role_id)
        // setSelectedEmployeeRegion(selectedEmployee.region)
        setViewEmpEvaluation(true)
    }


    async function getTargetOfRevenue(employeeId, parentEmployeeId) {
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        // var selectedTarget = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(employeeId),
        //     c.financial_year_id.eq(selectedYear.value),
        //     c.report_manager_employee_id.eq(employeeId)
        // ]));

        var selectedTarget = await graphQLGetAllData('listTargets', [{ employee_id: { eq: employeeId }, financial_year_id: { eq: selectedYear.value }, report_manager_employee_id: { eq: parentEmployeeId } }])


        // var getALLTarget = await DataStore.query(TargetDetails, (c) => c.and(c => [
        //     c.target_id.eq(selectedTarget[0].id),
        //     c.parameter_id.eq(PARAMETER_IDs.Revenue)
        // ]));

        var getALLTarget = await graphQLGetAllData('listTargetDetails', [{
            target_id: { eq: selectedTarget[0].id },
            parameter_id: { eq: PARAMETER_IDs.Revenue }
        }]);
        return getALLTarget
    }

    async function getPercentage(employeeId) {
        // var selectedTarget = await DataStore.query(Targets, (c) => c.and(c => [c.employee_id.eq(employeeId), c.financial_year_id.eq(selectedYear.value), c.report_manager_employee_id.eq(employeeId)]));

        var selectedTarget = await graphQLGetAllData('listTargets', [{
            employee_id: { eq: employeeId },
            financial_year_id: { eq: selectedYear.value },
            report_manager_employee_id: { eq: employeeId }
        }]);

        if (selectedTarget.length) {
            //get WEIGHTAGE ---- 
            // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [c.role_id.eq(selectedTarget[0].role_id), c.financial_year_id.eq(selectedYear.value)]));

            var relationshipResponse = await graphQLGetAllData('listRelationships', [{
                role_id: { eq: selectedTarget[0].role_id },
                financial_year_id: { eq: selectedYear.value }
            }]);

            var relationshipDetailResponses = []
            if (relationshipResponse.length > 0) {
                // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));

                relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: (relationshipResponse[0].id) } }]);
            }
            //get WEIGHTAGE ---- 

            //get Targets of Revenue , NWC DAYS, GROSS MARGIN
            // var getALLTarget = await DataStore.query(TargetDetails, (c) => c.target_id.eq(selectedTarget[0].id));

            var getALLTarget = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: (selectedTarget[0].id) } }]);

            let filterTargets = getALLTarget.filter(e => e.parameter_id === PARAMETER_IDs.Revenue || e.parameter_id === PARAMETER_IDs['NWC DAYS'] || e.parameter_id === PARAMETER_IDs['Gross Margin %'] || e.parameter_id === PARAMETER_IDs['Gross Margin $'])
            const commonEntries = relationshipDetailResponses.filter(obj1 => { return filterTargets.some(obj2 => { return obj1.parameter_id === obj2.parameter_id }); });

            var weightagePercentage = sumFromArray('weightage', commonEntries);
            return weightagePercentage + "%"
        } else {
            return 0
        }

    }

    const bindData = async () => {
        //var myTeamMembers = await DataStore.query(Employee, (c) => c.id.eq(loggedEmployeeId));

        var myTeamMembers = await graphQLGetAllData('listEmployees', [{ id: { eq: loggedEmployeeId } }]);

        let newResponse = []
        for (var i = 0; i < myTeamMembers.length; i++) {
            let profilePic = "";
            var getParentEmployee = "";
            var targetValue = 0;
            var getSbu = "";
            await Storage.get(myTeamMembers[i]["profilePic"]).then(result => { profilePic = result });

            //getParentEmployee
            if (myTeamMembers[i]["parentEmployeeId"] !== "") {
                // getParentEmployee = await DataStore.query(Employee, (c) => c.id.eq(myTeamMembers[i]["parentEmployeeId"]));

                getParentEmployee = await graphQLGetAllData('listEmployees', [{ id: { eq: myTeamMembers[i]["parentEmployeeId"] } }]);
            }

            //getRole
            //var getRole = await DataStore.query(Roles, myTeamMembers[i]["role_id"]);

            var getRole = await graphQLFindRecordById('getRoles', myTeamMembers[i]["role_id"]);


            let getRevenue = await getTargetOfRevenue(myTeamMembers[i].id, myTeamMembers[i].parentEmployeeId)
            if (getRevenue) {
                targetValue = "$" + commaSeparated(parseFloat(getRevenue[0].target_value).toFixed(2))
            }

            let getPercentageOfParameters = await getPercentage(myTeamMembers[i].id)

            //getSBU
            if (myTeamMembers[i]["param_sbu_id"] !== null && myTeamMembers[i]["param_sbu_id"] !== "") {
                //getSbu = await DataStore.query(SBU, myTeamMembers[i]["param_sbu_id"]);

                getSbu = await graphQLFindRecordById('getSBU', myTeamMembers[i]["param_sbu_id"]);
            }

            let data = {
                "id": myTeamMembers[i]["id"],
                "employeeId": myTeamMembers[i]["employeeId"],
                'employeeName': myTeamMembers[i]["firstName"] + " " + myTeamMembers[i]["lastName"],
                'profilePic': profilePic,
                "parentEmployeeName": getParentEmployee[0].firstName + " " + getParentEmployee[0].lastName,
                "roleName": getRole ? getRole.name:'',
                "at": targetValue,
                "vertical": "Distribution",
                "sbu": getSbu ? getSbu.name : '',
                "percentage": getPercentageOfParameters
            }

            newResponse.push(data);

            if (i === myTeamMembers.length - 1) {
                let sortArray = newResponse.sort((a, b) => (a.employeeName > b.employeeName ? 1 : -1))
                setMyTeam(sortArray)
                setRenderItems(true)
            }
        }
    }



    useEffect(() => {
        onLoad();
        bindData()
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="dashboard-main-wrapper target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link
                                onClick={() => { navigateTo(-1) }}
                                className="text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD]">
                                <i className="icon arrow_left_double mr-6 "></i>Back
                            </Link>
                            <div className="text-2xl font-semibold dark:text-white">Goals Evaluation</div>
                        </div>
                    </div>


                    <div className="lg:flex justify-between my-6">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link className='active'>{selectedTabEvaluation}</Link></li>
                                {/* <li><Link  >Historical</Link></li> */}
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0 flex items-center gap-4">
                            <div className="relative">
                                <input type="text" id="required-search" className=" rounded-lg border border-[#D0D5DD] focus:border-transparent w-250 py-2.5 pr-5 pl-9 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C] dark:placeholder-[#ffff]" placeholder="Search..." />
                                <button type="submit" className="absolute left-3 top-1/2 translate-y-[-50%] dark ">
                                    <i className="icon search_icon "></i>
                                </button>
                            </div>
                            <div className="w-full min-w-[200px]">
                                <Select
                                    isDisabled={true}
                                    options={financialYearList}
                                    value={financialYear}
                                    onChange={e => {
                                        onFinancialSelectChange(e)
                                        reactLocalStorage.setObject('selectedYear', e);
                                    }}
                                    className="mt-2 mb-2  my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                            <div className="w-full min-w-[200px]">
                                <Select
                                    value={quarter}
                                    options={quarterList}
                                    onChange={e => { setQuarter(e) }}
                                    className="mt-2 mb-2  my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg mt-5">
                        {
                            renderItems &&
                            <DataTable paginator
                                value={myTeam}
                                responsiveLayout="scroll" style={{ width: '93vw' }}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="employeeId" header="Employee ID" sortable filter ></Column>
                                <Column field="employeeName" header="Name" filter sortable></Column>
                                <Column field="roleName" header="Role" filter sortable></Column>
                                <Column field="vertical" header="Vertical" filter sortable></Column>
                                <Column field="sbu" header="SBU" filter sortable></Column>
                                <Column header="Percentage" filter sortable field="percentage"></Column>
                                <Column header="Annual Target Revenue(M)" filter sortable field='at' ></Column>
                                <Column alignHeader={'center'} header="Action" body={actionBodyTemplate}></Column>
                            </DataTable>
                        }

                    </div>

                </div>
            </div>


            <div id="selector" className={viewEmpEvaluation ? 'overlay' : ''}></div>
            {
                viewEmpEvaluation ?
                    <EvaluationEmployeePopUp
                        financialYearId={financialYear.value}
                        selectedEmployeeId={selectedEmployeeId}
                        selectedEmployeeCode={selectedEmployeeCode}
                        selectedEmployeeName={selectedEmployeeName}
                        selectedEmployeeProfilePic={selectedEmployeeProfilePic}
                        selectedEmployeeRole={selectedEmployeeRole}
                        selectedEmployeeRoleId={selectedEmployeeRoleId}
                        selectedParentEmployeeName={selectedParentEmployeeName}
                        selectedEmployeeCountry={selectedEmployeeCountry}
                        selectedEmployeeRegion={selectedEmployeeRegion}
                        selectedEmployeeSBU={selectedEmployeeSBU}

                        financialYear={financialYear.label}
                        quarter={quarter.label}

                        closeEmpEvaluation={() => setViewEmpEvaluation(false)}
                        openEmpEvaluation={() => setViewEmpEvaluation(true)}

                    />
                    : null
            }

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}