import { PARAMETERS_TYPES_NAMES } from "../../../components/helper/enum";

const getActualValue = (data, quarter, parameterType) => {    
    // console.log("data",data)
    try {
        let returnActual = 0

        switch (quarter) {

            case "Quarter 1":
                if (data.id !== "") {
                   
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N ) {
                        // console.log("data.m4_actual_value", data.m4_actual_value) m1_actual_value
                        // console.log("data.m5_actual_value", data.m5_actual_value)
                        // console.log("data.m6_actual_value", data.m6_actual_value)
                        returnActual = (parseFloat(data.m4_actual_value) + parseFloat(data.m5_actual_value) + parseFloat(data.m6_actual_value))
                        // console.log("returnActual", returnActual)
                    } else {
                        returnActual = (parseFloat(data.m4_actual_value) + parseFloat(data.m5_actual_value) + parseFloat(data.m6_actual_value)) / 3
                    }
                }
                return returnActual;

            case "Quarter 2":
                if (data.id !== "") {
                    let arr = [parseFloat(data.m4_actual_value), parseFloat(data.m5_actual_value), parseFloat(data.m6_actual_value), parseFloat(data.m7_actual_value), parseFloat(data.m8_actual_value), parseFloat(data.m9_actual_value)]
                    let sum = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let value = parseFloat(arr[i]);
                        if (!isNaN(value)) {
                            sum += value;
                        }
                    }

                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N ) {
                        return parseFloat(sum).toFixed(2);
                    } else {
                        return parseFloat(sum).toFixed(2) / 6;
                    }
                }
                return returnActual;

            case "Quarter 3":
                if (data.id !== "") {
                    let arr = [parseFloat(data.m4_actual_value), parseFloat(data.m5_actual_value), parseFloat(data.m6_actual_value), parseFloat(data.m7_actual_value), parseFloat(data.m8_actual_value), parseFloat(data.m9_actual_value), parseFloat(data.m10_actual_value), parseFloat(data.m11_actual_value), parseFloat(data.m12_actual_value)]
                    let sum = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let value = parseFloat(arr[i]);
                        if (!isNaN(value)) {
                            sum += value;
                        }
                    }

                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N  ) {
                        return parseFloat(sum).toFixed(2);
                    } else {
                        return parseFloat(sum).toFixed(2) / 9;
                    }
                }
                return returnActual;

            case "Quarter 4":
                
                if (data.id !== "") {
                    let arr = [parseFloat(data.m1_actual_value), parseFloat(data.m2_actual_value), parseFloat(data.m3_actual_value), parseFloat(data.m4_actual_value), parseFloat(data.m5_actual_value), parseFloat(data.m6_actual_value), parseFloat(data.m7_actual_value), parseFloat(data.m8_actual_value), parseFloat(data.m9_actual_value), parseFloat(data.m10_actual_value), parseFloat(data.m11_actual_value), parseFloat(data.m12_actual_value)]
                    let sum = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let value = parseFloat(arr[i]);
                        if (!isNaN(value)) {
                            sum += value;
                        }
                    }

                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N ) {
                        return parseFloat(sum).toFixed(2);
                    } else {
                        return parseFloat(sum).toFixed(2) / 12;
                    }
                }
                return returnActual;
            default:
            // code block
        }
    }
    catch (e) {

    }


};

export { getActualValue }
