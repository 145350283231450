import { Storage } from 'aws-amplify';
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import CircularProgressBar from '../../../../components/common/progressbar/CircularProgressbar';
import { ConvertResponseForSelect, convertArrayToObject } from '../../../../components/helper/commonfunctions';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';
import { calculateConsolidatedScore, calculateWeightageAchievement, checkIfNegativeValue } from "../../../../components/helper/dashboardCalCulations";
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES, PARAMETER_IDs } from '../../../../components/helper/enum';
import { detailViewFilter, drillDownFilter } from "../../../../components/helper/getFilterData";
import { commaSeparated, sumFromArray } from '../../../../components/helper/maths';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";
import ReactEcharts from 'echarts-for-react';
import { TabPanel, TabView } from 'primereact/tabview';
import UserImg from '../../../../assets/images/user.png';
import GaugeChart from '../../../../components/common/charts/gaugeChart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getTargetBasedOnParameterType, getTargetBasedOnParameterTypeForEvalPopup } from '../../../../components/common/getTargetBasedOnParameterType';
import { getActualValue } from "../../evaluation/getActualValue";
import { parse } from 'postcss';
import { checkEmployeeTeamType } from '../../helper';
import { queries } from '@testing-library/react';
import { listParameters } from '../../../../graphql/queries';
import { CSVLink } from "react-csv";
import * as alertify from 'alertifyjs';
import { filterArrayOnQuarter } from '../../../../components/common/filterArrayOnQuarter';




export default function ReporteeBusiness() {

    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let loggedEmployeeReportingManagerId = reactLocalStorage.get('loggedEmployeeReportingManagerId')

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearId, setFinancialYearId] = useState("");
    const [quarter, setQuarter] = useState({ label: "Quarter 1", value: "Quarter 1" });
    const [loggedEmployeeName, setLoggedEmployeeName] = useState("");
    const [loggedEmployeeCode, setLoggedEmployeeCode] = useState("");
    const [loggedEmployeeProfile, setLoggedEmployeeProfile] = useState("");
    const [loggedEmployeeRole, setLoggedEmployeeRole] = useState("");
    const [sbu, setSbu] = useState("");
    const [parameter, setParameter] = useState("");
    const [parameterName, setParameterName] = useState("");
    const [consolidatedScore, setConsolidatedScore] = useState("");
    const [consolidatedValue, setConsolidatedValue] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [isMasterDataLoaded, setIsMasterDataLoaded] = useState(false);
    const [isDetailedViewLoaded, setIsDetailedViewLoaded] = useState(false);

    const [selectedMonth, setSelectedMonth] = useState(0);

    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [parameterList, setParameterList] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [parameterDetailViewList, setParameterDetailViewList] = useState([]);
    const [quarterTargetValue, setQuarterTargetValue] = useState([]);
    const [quarterActualsValue, setQuarterActualsValue] = useState([]);
    const [brandsData, setBrandsData] = useState([]);
    const [showData, setShowData] = useState(false);

    //
    const [parametersForSelect, setParametersForSelect] = useState([]);
    const [selectedParameter, setSelectedParameter] = useState("");
    const [current_Target_Details, setCurrentTargetDetails] = useState([]);

    const [loggedEmployeeTargets, setLoggedEmployeeTargets] = useState([]);
    const [parametersList, SetParametersList] = useState([]);
    const [actualDetailsData, setActualDetailsData] = useState([]);
    const [parametersData, setParametersData] = useState([]);
    const [isDataLoadedForDetailedView, setDataLoadedForDetailedView] = useState(false)


    var monthList = [
        // { label: "All Months", value: "All" },
        { label: "Jan", value: "1" },
        { label: "Feb", value: "2" },
        { label: "March", value: "3" },
        { label: "April", value: "4" },
        { label: "May", value: "5" },
        { label: "Jun", value: "6" },
        { label: "Jul", value: "7" },
        { label: "Aug", value: "8" },
        { label: "Sept", value: "9" },
        { label: "Oct", value: "10" },
        { label: "Nov", value: "11" },
        { label: "Dec", value: "12" },
    ]

    //quarterList
    var quarterList = [
        { label: "All Quarter", value: "Quarter 4" },
        { label: "Quarter 1", value: "Quarter 1" },
        { label: "Quarter 2", value: "Quarter 2" },
        { label: "Quarter 3", value: "Quarter 3" },
        { label: "Quarter 4", value: "Quarter 4" },
    ]


    //Level List
    var levelList = [
        { label: "Select", value: "" },
        { label: "BU", value: "BU" },
        { label: "Region", value: "Region" },
        { label: "County", value: "Country" },
        { label: "Brand", value: "Brand" },
        { label: "Product Group", value: "ProductGroup" },
        // { label: "Cust. Group", value: "Cust. Group" },
        { label: "Customer", value: "Customer" },
    ]

    const getParameterName = (rowdata) => {
        let data = allParameters.find(c => c.id === rowdata.parameterName)
        return data ? data.name : '-';
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function loadSummaryData() {


        //load Logged employee response
        // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(loggedEmployeeId));
        var loggedEmployeeResponse = await graphQLFindRecordById("getEmployee", loggedEmployeeId)
        //load Summary Parameters
        let selectedYear = reactLocalStorage.getObject('selectedYear');

        if (!selectedYear.value) {
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

            selectedYear = { label: responses[0].name, value: responses[0].value }
        }

        setQuarter({ label: "Quarter 1", value: "Quarter 1" })
        LoadSummaryParametersData(selectedYear.value, loggedEmployeeId, "Quarter 1")

        if (loggedEmployeeResponse) {

            let fullName = loggedEmployeeResponse.firstName + ' ' + loggedEmployeeResponse.lastName
            setLoggedEmployeeName(fullName)
            let employeeCode = loggedEmployeeResponse.employeeId === null ? "" : loggedEmployeeResponse.employeeId
            setLoggedEmployeeCode(employeeCode)


            //set Profile
            if (loggedEmployeeResponse.profilePic) {
                await Storage.get(loggedEmployeeResponse.profilePic)
                    .then(result => {
                        setLoggedEmployeeProfile(result)
                    });
            } else { setLoggedEmployeeProfile(UserImg) }

            //set role
            let employeeRole = ""
            if (loggedEmployeeResponse.role_id !== null) {
                // var loggedEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq(loggedEmployeeResponse.role_id));
                var loggedEmployeeRole = await graphQLFindRecordById("getRoles", loggedEmployeeResponse.role_id)

                if (loggedEmployeeRole) { employeeRole = loggedEmployeeRole.name } else { employeeRole = "" }
            } else { employeeRole = "" }
            setLoggedEmployeeRole(employeeRole)

            //set SBU
            let employeeSBU = ""
            if (loggedEmployeeResponse.param_sbu_id !== null) {
                // var loggedEmployeeSbu = await DataStore.query(SBU, (c) => c.id.eq(loggedEmployeeResponse.param_sbu_id));
                var loggedEmployeeSbu = await graphQLFindRecordById("getSBU", loggedEmployeeResponse.param_sbu_id)

                if (loggedEmployeeSbu) { employeeSBU = loggedEmployeeSbu.name } else { employeeSBU = "" }
            } else { employeeSBU = "" }
            setSbu(employeeSBU)

        }
        //load detail view data
        loadSummaryDetailViewData()
    }

    const showPlanActual_valuePercentage = (rowData) => {

        let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
        let targetValue = rowData.target_value;

        let achievementPercentatage = 0;

        if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
            achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
        }
        else {
            achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
        }

        if (rowData.parameter_id === PARAMETER_IDs["Overdue Receivable"]) {
            if (achievementPercentatage <= 0) {
                achievementPercentatage = 120
            } else if (achievementPercentatage <= 5) {
                achievementPercentatage = 100
            } else if (achievementPercentatage > 5 && achievementPercentatage < 10) {
                achievementPercentatage = 80
            } else {
                achievementPercentatage = 0
            }

        }
        else {
            if (achievementPercentatage > 200) {
                achievementPercentatage = 200;
            } else if (achievementPercentatage < 50) {
                achievementPercentatage = 0;
            }
        }


        if (!achievementPercentatage) {
            achievementPercentatage = 0;
        }

        return parseFloat(achievementPercentatage).toFixed(2) + '%';
    };

    const showAchiievementWeightPercentage = (rowData) => {
        let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
        let targetValue = rowData.target_value;

        let achievementPercentatage = 0;
        if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
            achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
        }
        else {
            achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
        }

        if (achievementPercentatage > 200) {
            achievementPercentatage = 200;
        } else if (achievementPercentatage < 50) {
            achievementPercentatage = 0;
        }

        let weightPercentage = (rowData.parameter_weightage * achievementPercentatage) / 100;

        if (!weightPercentage) {
            weightPercentage = 0;
        }
        //parseFloat(NWCDaysValue).toFixed(2)
        return parseFloat(weightPercentage).toFixed(2) + '%'

    }

    //Load Summary Parameters Data
    async function LoadSummaryParametersData(financialYearId, employeeId, quarterValue) {
        if (financialYearId && employeeId && quarterValue) {
            setShowData(false)

            // //get parameters
            // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.employee_id.eq(employeeId),
            //     c.financial_year_id.eq(financialYearId)
            // ]));

            var targetsResponses = loggedEmployeeTargets.find(o => o.financial_year_id === financialYearId);
            if (targetsResponses) {
                //get parameters weightage
                // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
                //     c.role_id.eq(targetsResponses.role_id),
                //     c.financial_year_id.eq(financialYearId)
                // ]));

                var relationshipResponse = await graphQLGetAllData("listRelationships", [{ role_id: { eq: targetsResponses.role_id }, financial_year_id: { eq: financialYearId } }])


                var relationshipDetailResponses = []
                if (relationshipResponse.length > 0) {
                    // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));
                    relationshipDetailResponses = await graphQLGetAllData("listRelationshipDetails", [{ relationship_id: { eq: relationshipResponse[0].id } }])

                }
                var _tempArray = [];

                //Target Details
                //Change because of this // https://edbrix.atlassian.net/browse/RGS-93 on  8 MAY 2023
                //var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses[0].id));

                // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                //     c.target_id.eq(targetsResponses.id),
                //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
                // ]));

                var currentTargetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: targetsResponses.id }, parameter_id: { notContains: PARAMETER_IDs.DIO }, parameter_id: { notContains: PARAMETER_IDs.DPO }, parameter_id: { notContains: PARAMETER_IDs.DVRO }, parameter_id: { notContains: PARAMETER_IDs.DSO } }])

                for (let i = 0; i < currentTargetDetails.length; i++) {
                    // currentTargetDetails.forEach(async (currentItem, i) => {
                    //Status
                    let status = ""
                    if (currentTargetDetails[i].is_accepted_by_employee && !currentTargetDetails[i].is_approve) { status = "Awaiting approval" }
                    else if (currentTargetDetails[i].isSendForRevisionByReportingManage) { status = "Revision" }
                    else if (currentTargetDetails[i].is_approve) { status = "Approved" }
                    else if (currentTargetDetails[i].is_revision_by_employee) { status = "Requested for Revision" }
                    else { status = "Open" }

                    //get parameters details
                    // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));
                    var parameterResponses = parametersList.find(o => o.id === currentTargetDetails[i].parameter_id);


                    var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentTargetDetails[i].parameter_id)

                    var weightageForWtAch = getWeightage.length > 0 ? parseInt(getWeightage[0]?.weightage) : 1;

                    var parameterBaseType = parameterResponses?.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parameterResponses?.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parameterResponses?.type === PARAMETERS_TYPES.Number ? PARAMETERS_TYPES_NAMES.N : PARAMETERS_TYPES_NAMES.P

                    var actualPerformance = 0; var actualAch = 0; var wtAch = 0;

                    var planActual = getActualValue(currentTargetDetails[i], quarterValue, parameterBaseType)

                    if (planActual) { actualPerformance = parseFloat(planActual).toFixed(2) } else { actualPerformance = 0 }

                    // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentItem.id));
                    var getPlanDetails = await graphQLGetAllData("listPlans", [{ target_detail_id: { eq: currentTargetDetails[i].id } }])

                    var getTargetOnParameterType = getTargetBasedOnParameterTypeForEvalPopup(getPlanDetails, quarterValue, parameterBaseType, currentTargetDetails[i])
                    actualAch = await calculateConsolidatedScore(planActual, getTargetOnParameterType)

                    wtAch = await calculateWeightageAchievement(weightageForWtAch, actualAch)

                    let originalWtAch = wtAch
                    //Check if Negative
                    actualAch = await checkIfNegativeValue(actualAch)
                    wtAch = await checkIfNegativeValue(wtAch)
                    // actualPerformance = await checkIfNegativeValue(actualPerformance)

                    // if (getWeightage.length > 0) {
                    if (1) {

                        if (parameterResponses) {
                            let targetValue = parameterResponses?.type === "A" || parameterResponses?.type === "D" ? getTargetOnParameterType : parseFloat(getTargetOnParameterType).toFixed(2);
                            let actualValue = actualPerformance !== undefined ? parameterResponses?.type === "A" ? (actualPerformance) : actualPerformance : 0;
                            actualValue = actualValue.toString().replace(/[\(\)]/g, "")
                            let actualPercentage = 0;

                            if (currentTargetDetails[i].parameter_id === PARAMETER_IDs["NWC DAYS"]) {
                                actualPercentage = parseInt(targetValue) ? (100 * ((parseInt(targetValue) * 2) - parseInt(actualValue))) / parseInt(targetValue) : 0;
                            }
                            else if (currentTargetDetails[i].parameter_id === PARAMETER_IDs['Gross Margin %'] || currentTargetDetails[i].parameter_id === PARAMETER_IDs['Gross Margin $']) {
                                actualPercentage = targetValue ? (100 * parseInt(actualValue) * -1) / parseInt(targetValue) : 0;
                            }
                            else {
                                actualPercentage = targetValue ? (100 * parseInt(actualValue)) / parseInt(targetValue) : 0;
                            }

                            if (actualPercentage > 120) {
                                actualPercentage = 120;
                            } else if (actualPercentage < 50) {
                                actualPercentage = 0;
                            }

                            let weightPercentage = (weightageForWtAch * actualPercentage) / 100;

                            if (!weightPercentage) {
                                weightPercentage = 0;
                            }

                            let data = {
                                "target_detail_id": currentTargetDetails[i].id,
                                "parameter_id": currentTargetDetails[i].parameter_id,
                                "parameter_name": parameterResponses.name,
                                "parameter_type": parameterResponses?.type === "A" ? "$" : parameterResponses?.type === "D" ? "Days" : parameterResponses.type === "P" ? "%" : parameterResponses.type === "N" ? "Number" : "",
                                "target_value": targetValue,
                                "actualPerformance": parseFloat(actualValue).toFixed(2),
                                "actualPerformanceValue": actualValue,
                                // "actualAch": actualAch ? parseFloat(actualAch).toFixed(2) + "%" : "0%",
                                // "wtAch": wtAch ? parseFloat(wtAch).toFixed(2) + "%" : parseFloat(0).toFixed(2) + "%",
                                "actualAch": actualAch ? actualAch + "%" : "0%",
                                "wtAch": isFinite(wtAch) ? wtAch + "%" : "0%",
                                "isMarkAsComplete": currentTargetDetails[i].isMarkAsComplete,
                                "is_accepted_by_employee": currentTargetDetails[i].is_accepted_by_employee,
                                "is_approve": currentTargetDetails[i].is_approve,
                                "is_revision_by_employee": currentTargetDetails[i].is_revision_by_employee,
                                "isSendForRevisionByReportingManage": currentTargetDetails[i].isSendForRevisionByReportingManage,
                                "parameter_weightage": weightageForWtAch ? weightageForWtAch : 0,
                                "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                                //"planning_period": moment(financialYearResponses[0].fromDate).format("Do MMM YY") + " - " + moment(financialYearResponses[0].toDate).format("Do MMM YY")
                                "status": status,
                                "q1_target_value": currentTargetDetails[i].q1_target_value,
                                "q2_target_value": currentTargetDetails[i].q2_target_value,
                                "q3_target_value": currentTargetDetails[i].q3_target_value,
                                "q4_target_value": currentTargetDetails[i].q4_target_value,
                                "q1_actual_value": getActualValue(currentTargetDetails[i], "Quarter 1", parameterBaseType),
                                "q2_actual_value": getActualValue(currentTargetDetails[i], "Quarter 2", parameterBaseType),
                                "q3_actual_value": getActualValue(currentTargetDetails[i], "Quarter 3", parameterBaseType),
                                "q4_actual_value": getActualValue(currentTargetDetails[i], "Quarter 4", parameterBaseType),
                                "totalWeightage": actualAch ? parseFloat(actualAch).toFixed(2) : 0,
                                "originalWtAch": originalWtAch,
                                "weightPercentage": parseFloat(weightPercentage).toFixed(2) + '%',
                                "actualPercentage": parseFloat(actualPercentage).toFixed(2) + '%'
                            }
                            _tempArray.push(data)
                        }
                    }
                    // if (i === currentTargetDetails.length - 1) {

                    // }
                }

                let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
                let filteredArray = sortArray.filter(e => e.parameter_id === PARAMETER_IDs.Revenue || e.parameter_id === PARAMETER_IDs['NWC DAYS'] || e.parameter_id === PARAMETER_IDs['Gross Margin %'] || e.parameter_id === PARAMETER_IDs['Gross Margin $']);


                let dollerValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs["Gross Margin $"])
                let revenueValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs.Revenue)

                let gmPercentageValue = revenueValue.actualPerformanceValue ? (parseInt(dollerValue ? dollerValue?.actualPerformanceValue :0) / parseInt(revenueValue.actualPerformanceValue)) * 100 : 0;

                let filteredData = sortArray.filter(e => e.isPlanMandatory)

                let objIndex = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin %"]));

                if (objIndex >= 0) {
                    filteredData[objIndex].actualPerformance = gmPercentageValue.toFixed(2) * -1;
                    // filteredData[objIndex].actualPercentage = showPlanActual_valuePercentage(filteredData[objIndex]);
                    // filteredData[objIndex].weightPercentage = showAchiievementWeightPercentage(filteredData[objIndex]);
                    filteredData[objIndex].q1_actual_value = filteredData[objIndex].q1_actual_value * -1;
                    filteredData[objIndex].q2_actual_value = filteredData[objIndex].q2_actual_value * -1;
                    filteredData[objIndex].q3_actual_value = filteredData[objIndex].q3_actual_value * -1;
                    filteredData[objIndex].q4_actual_value = filteredData[objIndex].q4_actual_value * -1;
                }

                let objIndexforGM$ = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin $"]));

                if (objIndexforGM$ >= 0) {
                    filteredData[objIndexforGM$].actualPerformance = filteredData[objIndexforGM$].actualPerformance * -1;
                    filteredData[objIndexforGM$].actualPerformanceValue = filteredData[objIndexforGM$].actualPerformanceValue * -1;
                    // filteredData[objIndexforGM$].actualPercentage = showPlanActual_valuePercentage(filteredData[objIndexforGM$]);
                    // filteredData[objIndexforGM$].weightPercentage = showAchiievementWeightPercentage(filteredData[objIndexforGM$]);
                    filteredData[objIndexforGM$].q1_actual_value = filteredData[objIndexforGM$].q1_actual_value * -1;
                    filteredData[objIndexforGM$].q2_actual_value = filteredData[objIndexforGM$].q2_actual_value * -1;
                    filteredData[objIndexforGM$].q3_actual_value = filteredData[objIndexforGM$].q3_actual_value * -1;
                    filteredData[objIndexforGM$].q4_actual_value = filteredData[objIndexforGM$].q4_actual_value * -1;
                }

                filteredData = filteredData.map((item) => ({
                    ...item,
                    weightPercentage: showAchiievementWeightPercentage(item),
                    actualPercentage: showPlanActual_valuePercentage(item),
                }))

                let totalConsolidatedScore = filteredData.reduce((acc, book) => acc + parseFloat(book.weightPercentage), 0); // originalWtAch replaced with weightPercentage

                setConsolidatedScore(!isNaN(parseFloat(totalConsolidatedScore).toFixed(2)) ? parseFloat(totalConsolidatedScore).toFixed(2) : 0)

                let totalConsolidatedValue = filteredData.reduce((acc, book) => acc + parseFloat(book.parameter_weightage), 0);

                // setConsolidatedValue(totalConsolidatedValue)
                setConsolidatedValue(!isNaN(parseFloat(totalConsolidatedValue).toFixed(2)) ? totalConsolidatedValue : 0)


                let checkNWCIsPrsesnt = filteredData.find(c => c.parameter_id === PARAMETER_IDs["NWC DAYS"])
                if (checkNWCIsPrsesnt) {
                    filteredData = filteredData.filter(c => !(c.parameter_id === PARAMETER_IDs.DIO || c.parameter_id === PARAMETER_IDs.DPO || c.parameter_id === PARAMETER_IDs.DSO || c.parameter_id === PARAMETER_IDs.DVRO))
                } else {
                    filteredData = filteredData.filter(c =>
                       !( c.parameter_id === PARAMETER_IDs.DIO && Number(c.target_value) === 0 ||
                        c.parameter_id === PARAMETER_IDs.DPO && Number(c.target_value) === 0 ||
                        c.parameter_id === PARAMETER_IDs.DSO && Number(c.target_value) === 0 ||
                        c.parameter_id === PARAMETER_IDs.DVRO && Number(c.target_value) === 0)
                    )
                }


                setSummaryData(filteredData)
                let initialParameter = filteredData.find(c => c.parameter_name === "Revenue");

                if (initialParameter) {
                    setParameterName(initialParameter.parameter_name);
                    setShowData(true);
                    await LoadBrandsData(initialParameter);

                    setQuarterTargetValue([parseFloat(initialParameter.q1_target_value).toFixed(2), parseFloat(initialParameter.q2_target_value).toFixed(2), parseFloat(initialParameter.q3_target_value).toFixed(2), parseFloat(initialParameter.q4_target_value).toFixed(2)])
                    setQuarterActualsValue([parseFloat(initialParameter.q1_actual_value).toFixed(2), parseFloat(initialParameter.q2_actual_value).toFixed(2), parseFloat(initialParameter.q3_actual_value).toFixed(2), parseFloat(initialParameter.q4_actual_value).toFixed(2)])
                }
                else {
                    if (filteredData.length > 0) {
                        setParameterName(filteredData[0].parameter_name)
                        setShowData(true)
                        await LoadBrandsData(filteredData[0])

                        setQuarterTargetValue([parseFloat(filteredData[0].q1_target_value).toFixed(2), parseFloat(filteredData[0].q2_target_value).toFixed(2), parseFloat(filteredData[0].q3_target_value).toFixed(2), parseFloat(filteredData[0].q4_target_value).toFixed(2)])
                        setQuarterActualsValue([parseFloat(filteredData[0].q1_actual_value).toFixed(2), parseFloat(filteredData[0].q2_actual_value).toFixed(2), parseFloat(filteredData[0].q3_actual_value).toFixed(2), parseFloat(filteredData[0].q4_actual_value).toFixed(2)])
                    } else {
                        await LoadBrandsData([]);
                        setShowData(true)
                        setSummaryData([]);
                        setQuarterTargetValue([]);
                        setQuarterActualsValue([]);
                        setParameterName("");
                    }
                }
            }
            else {
                setShowData(true)
                setSummaryData([]);
                setQuarterTargetValue([]);
                setQuarterActualsValue([]);
                setParameterName("");
            }
        }
    }

    const [brandsDataSpinner, setBrandsDataSpinner] = useState(false);
    //Load Brands Data
    async function LoadBrandsData(data) {

        if (data.length === 0) {
            setBrandsDataSpinner(false);
        } else {
            // let ActualsOfParameters = await DataStore.query(ActualDetails, (c) => c.target_details_id.eq(data.target_detail_id))
            let ActualsOfParameters = await graphQLGetAllData("listActualDetails", [{ target_details_id: { eq: data.target_detail_id } }])
            if (ActualsOfParameters.length > 0) {
                setBrandsDataSpinner(true);
                const brandTotals = [];
                ActualsOfParameters.forEach(transaction => {
                    const brand = transaction.brand;
                    const actualValue = parseFloat(transaction.actual_value);
                    const brandIndex = brandTotals.findIndex(item => item.brand === brand);
                    if (brandIndex !== -1) {
                        brandTotals[brandIndex].total += actualValue;
                    } else {
                        // Add a new brand object to the array
                        brandTotals.push({
                            brand: brand,
                            total: actualValue
                        });
                    }
                });

                if (data.parameter_name === "GM $" || data.parameter_name === "GM %") {
                    if (brandTotals) {
                        const updatedData = brandTotals.map(item => ({
                            Brand: item.brand,
                            Percentage: Math.round(item.total) * -1
                        }));
                        setBrandsData(updatedData);
                    }
                } else {
                    if (brandTotals) {
                        const updatedData = brandTotals.map(item => ({
                            Brand: item.brand,
                            Percentage: Math.round(item.total)
                        }));
                        setBrandsData(updatedData);
                    }
                }
                setBrandsDataSpinner(false);
            }
        }

    }

    //Load Detail View Data
    let loadSummaryDetailViewData = async () => {

        setIsDetailedViewLoaded(false)
        let financialYearId = ""
        let employeeID = ""

        if (financialYear.value) { financialYearId = financialYear.value } else {
            let selectedYear = reactLocalStorage.getObject('selectedYear');
            financialYearId = selectedYear.value
        }


        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

        setFinancialYear({ label: responses[0].name.toString(), value: responses[0].id.toString() })


        // //get target response
        // // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        // //     c.employee_id.eq(loggedEmployeeId),
        // //     c.financial_year_id.eq(financialYearId),
        // //     c.report_manager_employee_id.notContains(loggedEmployeeId)
        // // ]));

        // // var targetsResponses = loggedEmployeeTargets.find(o => o.financial_year_id === financialYearId && o.report_manager_employee_id !== loggedEmployeeId);
        // var targetsResponses = loggedEmployeeTargets.find(o => o.financial_year_id === financialYearId);
        // if (targetsResponses) {

        //     // var targetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses.id))
        //     var targetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: targetsResponses.id } }])

        //     let finalData = [];

        //     for (let i = 0; i < targetDetails.length; i++) {
        //         // let actualsDetails = await DataStore.query(ActualDetails, (c) => c.target_details_id.eq(targetDetails[i].id))
        //         let actualsDetails = actualDetailsData.filter(c => c.target_details_id === targetDetails[i].id)

        //         let final_ActualData = []

        //         if (actualsDetails.length > 0) {
        //             for (let j = 0; j < actualsDetails.length; j++) {

        //                 let parameter = parametersList.find(p => p.id === targetDetails[i].parameter_id);
        //                 let data = {
        //                     "id": actualsDetails[j].id,
        //                     "buName": actualsDetails[j].BU,
        //                     "regionName": actualsDetails[j].region,
        //                     "countryName": actualsDetails[j].country, "brandName": actualsDetails[j].brand,
        //                     "pGName": actualsDetails[j].productGroup,
        //                     "custCatName": actualsDetails[j].customerGroupCode, "customerName": actualsDetails[j].customerGroupCode,
        //                     "target_Actual_value": Math.round(actualsDetails[j].actual_value),
        //                     "month": getMonthName(actualsDetails[j].monthNo),
        //                     "parameterName": parameter ? parameter.name : ''

        //                 }

        //                 final_ActualData.push(data)
        //                 if (j === actualsDetails.length - 1) {
        //                     finalData.push(final_ActualData)
        //                 }
        //             }
        //         }

        //         // if (i === targetDetails.length - 1) {

        //         // }
        //     }
        //     let finalParameterData = convertArrayToObject(finalData)
        //     // setParameterDetailViewList(finalParameterData)


        // } else {
        //     setParameterDetailViewList([])
        // }
        setSelectedMonth({ label: 'April', value: '4' });
        setParameter({ label: 'Revenue', value: '3fc887f7-2cf9-430a-8d8f-c48c8299431b' })

        const data = await detailViewFilter(loggedEmployeeId, PARAMETER_IDs.Revenue, responses[0].id, 4);

        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
        }
        else {
            setParameterDetailViewList([]);
        }


        setIsDetailedViewLoaded(true)
    }

    const actualPerformanceTemplate = (rowData) => {
        if (rowData.actualPerformance !== "") {
            rowData.actualPerformance = rowData.actualPerformance ? rowData.actualPerformance : '0';
            return (
                <React.Fragment>
                    {rowData.parameter_type === PARAMETERS_TYPES_NAMES.A ? (
                        <label>
                            {rowData.parameter_type}
                            {commaSeparated(parseFloat(rowData.actualPerformance))}{" "}
                        </label>
                    ) : rowData.parameter_type === PARAMETERS_TYPES_NAMES.P ? (
                        <label>
                            {rowData.actualPerformance}
                            {rowData.parameter_type}
                        </label>
                    ) : (
                        <label>
                            {rowData.actualPerformance} {rowData.parameter_type}
                        </label>
                    )}
                </React.Fragment>
            );
        }
    }

    function getMonthName(monthNumber) {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        if (monthNumber >= 1 && monthNumber <= 12) {
            return monthNames[monthNumber - 1];
        } else {
            return "Invalid month number. Please provide a number between 1 and 12.";
        }
    }

    const csvLinkRef = useRef(null);

    const handleDownload = () => {
        alertify.confirm('Confirmation', 'Do you want to download these partners?',
            async function () {
                csvLinkRef.current.link.click();
            }, function () { })
    }

    const downloadData = parameterDetailViewList.map((c) => {

        let obj = { ...c }
        return obj;
    })

    const headers = [
        { label: "BU", key: "buName" },
        { label: "Region Name", key: "regionName" },
        { label: "CountryName", key: "countryName" },
        { label: "Brand Name", key: "brandName" },
        { label: "Product Group", key: "pGName" },
        { label: "Customer Category", key: "custCatName" },
        { label: "Cusomer ", key: "customerName" },
        { label: "Month", key: "month" },
        { label: "Parameters", key: "parameterName" },
        { label: "Actual Value", key: "target_Actual_value" },


    ];

    const csvReport = {
        data: downloadData,
        headers: headers,
        filename: 'Business - DetailedView.csv'
    };

    const drillDownFilterData = async (financialYearDetails) => {
        setIsDetailedViewLoaded(false)
        //__________Load Summary Parameter Data____________//
        LoadSummaryParametersData(financialYearDetails.value, loggedEmployeeId, quarter)
        //__________Load Summary Parameter Data____________//

        const data = await detailViewFilter(loggedEmployeeId, parameter.value, financialYearDetails.value)
        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
        }
        else {
            setParameterDetailViewList([]);
        }

        setIsDetailedViewLoaded(true)

        //_________________________set drilldown analysis_______________________//


        const drillDownData = await drillDownFilter(loggedEmployeeId, financialYearDetails.value)

        setDrillDownData([])
        setSelectedParameter("");
        setShowDrillDownChart(false);
        setLevel1("");
        setLevel2("");
        setLevel3("");
        setLevel4("");

        setCurrentTargetDetails(drillDownData)

        let parametersForSelectedYear = []

        for (var i = 0; i < drillDownData.length; i++) {
            // var parameterResponses = await DataStore.query(Parameters, drillDownData[i]["parameter_id"])
            var parameterResponses = parametersList.find(o => o.id === drillDownData[i]["parameter_id"]);
            let data = { "label": parameterResponses.name, "value": parameterResponses.id }
            //* Hide NWC days  07-06-2023
            if (parameterResponses.name !== "NWC Days") {
                parametersForSelectedYear.push(data)
            }

            // if (i === drillDownData.length - 1) {

            // }
        }

        setParametersForSelect(parametersForSelectedYear)

        if (drillDownData.length === 0) {
            setParametersForSelect([])
        }
    }
    //onClick Financial Year
    const handleClickFinanCialYearDropdown = async (event) => {
        // loadDrillDownData(financialYearDetails.value)
        reactLocalStorage.setObject('selectedYear', event);
        setFinancialYear(event);
        drillDownFilterData(event)
    }

    //onClick Parameters
    const handleClickParameterDropdown = async (event) => {
        setIsDetailedViewLoaded(false)
        setParameter(event)

        const data = await detailViewFilter(loggedEmployeeId, event.value, financialYear.value, selectedMonth.value);

        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
        }
        else {
            setParameterDetailViewList([]);
        }
        setIsDetailedViewLoaded(true)
    }

    //onClick Financial Year
    const handleClickQuarterDropdown = (event) => {
        setShowData(true)
        //__________Load Summary Parameter Data____________//
        LoadSummaryParametersData(financialYear.value, loggedEmployeeId, event.value)
        //__________Load Summary Parameter Data____________//
    }

    // onclick monthDropdown?
    const handleClickMonthDropDown = async (event) => {
        setIsDetailedViewLoaded(false)
        const data = await detailViewFilter(loggedEmployeeId, parameter.value, financialYear.value, event.value)
        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
        }
        else {
            setParameterDetailViewList([]);
        }
        setIsDetailedViewLoaded(true)
    }

    const [allParameters, setAllParameters] = useState([]);
    const getMasterData = async () => {


        let allParameterResponses = await graphQLGetAllData("listParameters");
        setAllParameters(allParameterResponses)

        let sbuArray = await graphQLGetAllData("listSBUS")
        sbuArray = sbuArray.sort((a, b) => a.name.localeCompare(b.name));
        setSbuList(sbuArray)

        let BIUnitArray = await graphQLGetAllData("listBIUnits")
        BIUnitArray = BIUnitArray.sort((a, b) => a.name.localeCompare(b.name));
        setBuList(BIUnitArray)

        let regionsArray = await graphQLGetAllData("listRegions")
        regionsArray = regionsArray.sort((a, b) => a.name.localeCompare(b.name));
        setRegionList(regionsArray)

        let countryArray = await graphQLGetAllData("listCountries")
        countryArray = countryArray.sort((a, b) => a.name.localeCompare(b.name));
        setCountryList(countryArray)

        let brandArray = await graphQLGetAllData("listBrands")
        setBrandList(brandArray)

        // let GTMArray = await graphQLGetAllData("listGTMS")
        // setAllGTM(GTMArray)

        // let subGTMArray = await graphQLGetAllData("listSubGTMS")
        // setAllSubGTM(subGTMArray)

        let productGroupArray = await graphQLGetAllData("listProductGroups")
        productGroupArray = productGroupArray.sort((a, b) => a.name.localeCompare(b.name));
        setProductGroupList(productGroupArray)

        let customerCategoryArray = await graphQLGetAllData("listCustomerCategories")
        customerCategoryArray = customerCategoryArray.sort((a, b) => a.name.localeCompare(b.name));
        setCustomerCategoryList(customerCategoryArray)

        // let customerArray = await graphQLGetAllData("listCustomers")
        // customerArray = customerArray.sort((a, b) => a.name.localeCompare(b.name));
        // setCustomerList(customerArray)

        let parameterResponses = await graphQLGetAllData("listParameters")
        SetParametersList(parameterResponses)

        // let profitCenterArray = await graphQLGetAllData("listProfitCenters")
        // setAllProfitCenter(profitCenterArray)

        let financialYearResponses = await graphQLGetAllData("listFinancialYears")
        financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(financialYearResponses); setFinancialYearList(myArray);

        let loggedEmployeeTargetList = await graphQLGetAllData("listTargets", [{ employee_id: { eq: loggedEmployeeId } }])
        setLoggedEmployeeTargets(loggedEmployeeTargetList)

        setIsMasterDataLoaded(true)
    }

    useEffect(() => {
        onLoad();
        getMasterData();
        loadDataForDetailedView();
    }, []);

    useEffect(() => {
        if (isMasterDataLoaded === true) {
            bindList();
        }
    }, [isMasterDataLoaded]);


    useEffect(() => {
        if (financialYear.value && loggedEmployeeTargets.length > 0)
            LoadSummaryParametersData(financialYear.value, loggedEmployeeId, quarter.value)
    }, [financialYear, loggedEmployeeTargets]);

    async function bindList() {

        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        let yearId = '';
        if (Object.keys(selectedYear).length !== 0) {
            yearId = selectedYear.value;
            setFinancialYear(selectedYear)
            // drillDownFilterData(selectedYear)
            setFinancialYearId(selectedYear.value)
            loadDrillDownData(selectedYear.value)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData("listFinancialYears", [{ fromDate: { contains: moment().format("YYYY") } }])
            yearId = responses[0].id;
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            // drillDownFilterData({ label: responses[0].name, value: responses[0].id })
            setFinancialYearId(responses[0].id)
            loadDrillDownData(responses[0].id)
        }

        //get Parameters List
        // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.report_manager_employee_id.contains(loggedEmployeeId),
        //     c.financial_year_id.eq(selectedYear.value)
        // ]));

        // var targetsResponses = loggedEmployeeTargets.find(o => o.financial_year_id === selectedYear.value && o.report_manager_employee_id === loggedEmployeeReportingManagerId);

        var targetsResponses = loggedEmployeeTargets.find(o => o.financial_year_id === yearId);


        if (targetsResponses) {
            // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses.id));
            var currentTargetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: targetsResponses.id } }])

            let _tempArray = [];
            if (currentTargetDetails.length > 0) {
                currentTargetDetails.forEach(async (currentItem, i) => {

                    //get parameters details
                    // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));
                    var parameterResponses = parametersList.find(o => o.id === currentItem.parameter_id);

                    if (parameterResponses) {
                        let data = {
                            "id": currentItem.parameter_id,
                            "name": parameterResponses.name,
                        }
                        _tempArray.push(data)
                    }

                    if (i === currentTargetDetails.length - 1) {
                        let sortArray = _tempArray.sort((a, b) => (a.name > b.name ? 1 : -1))
                        var myParameterArray = []; myParameterArray = ConvertResponseForSelect(sortArray);
                        // let zeroPositionParameterObject = { label: "All", value: 0 }; myParameterArray.unshift(zeroPositionParameterObject);
                        setParameterList(myParameterArray);
                    }
                })


                // let parameters = await graphQLGetAllData("listParameters");
                // setParametersData(parameters);
                // let actualsDetails = await graphQLGetAllData("listActualDetails");
                // setActualDetailsData(actualsDetails);

                loadSummaryData()
            } else {
                setParameterList([])
            }
        } else {
            setParameterList([])
            setShowData(true)
        }


    }

    const loadDataForDetailedView = async () => {
        setDataLoadedForDetailedView(false)
        // let parameters = await graphQLGetAllData("listParameters");
        // setParametersData(parameters);
        let actualsDetails = await graphQLGetAllData("listActualDetails");
        setActualDetailsData(actualsDetails);
        setDataLoadedForDetailedView(true)
    }

    const loadDrillDownData = async (financialYearId) => {
        setSelectedParameter("")
        try {
            // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.employee_id.eq(loggedEmployeeId),
            //     c.report_manager_employee_id.notContains(loggedEmployeeId),
            //     c.financial_year_id.eq(financialYearId),
            //     c.isAssignedToEmployee.eq(true)
            // ]));

            var targetsResponses = loggedEmployeeTargets.find(o => o.financial_year_id === financialYearId && o.report_manager_employee_id !== loggedEmployeeReportingManagerId && o.isAssignedToEmployee === true);


            if (targetsResponses) {
                //Target Details
                // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                //     c.target_id.eq(targetsResponses.id),
                //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
                // ]));

                var currentTargetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: targetsResponses.id }, parameter_id: { notContains: PARAMETER_IDs.DIO }, parameter_id: { notContains: PARAMETER_IDs.DPO }, parameter_id: { notContains: PARAMETER_IDs.DVRO }, parameter_id: { notContains: PARAMETER_IDs.DSO } }])

                setCurrentTargetDetails(currentTargetDetails)

                let parametersForSelectedYear = []
                for (var i = 0; i < currentTargetDetails.length; i++) {
                    // var parameterResponses = await DataStore.query(Parameters, currentTargetDetails[i]["parameter_id"])
                    var parameterResponses = parametersList.find(o => o.id === currentTargetDetails[i]["parameter_id"]);
                    let data = { "label": parameterResponses.name, "value": parameterResponses.id }
                    //* Hide NWC days  07-06-2023
                    if (parameterResponses.name !== "NWC Days") {
                        parametersForSelectedYear.push(data)
                    }

                    if (i === currentTargetDetails.length - 1) {
                        setParametersForSelect(parametersForSelectedYear)
                    }
                }
            }
            else {
                setParameterList([])
                setShowData(true)
            }
        } catch (e) {
        }
    }

    //Show DrillDown Chart
    var [drillDownData, setDrillDownData] = useState([]);
    var [showDrillDownChart, setShowDrillDownChart] = useState(false);

    var [level1, setLevel1] = useState("");
    var [level2, setLevel2] = useState("");
    var [level3, setLevel3] = useState("");
    var [level4, setLevel4] = useState("");

    var excludeValues = [];

    if (level1) {
        excludeValues.push(level1.label);
    }
    if (level2) {
        excludeValues.push(level2.label);
    }
    if (level3) {
        excludeValues.push(level3.label);
    }
    if (level4) {
        excludeValues = [level4.label];
    }
    if (excludeValues) {
        let levelValue = excludeValues[0]
        if (levelValue !== "Select") {
            levelList = levelList.filter(function (option) {
                return excludeValues.indexOf(option.label) === -1;
            });
        }
    }

    //get sbu
    var [sbuList, setSbuList] = useState([]);
    const getSBULabel = (id) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get bu
    var [buList, setBuList] = useState([]);
    const getBULabel = (id) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get region 
    var [regionList, setRegionList] = useState([]);
    const getRegionLabel = (id) => {
        let _regionList = [...regionList];
        var returnRecord = _regionList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get country
    var [countryList, setCountryList] = useState([]);
    // const getCountryLabel = (id) => {
    //     let _countryList = [...countryList];
    //     var returnRecord = _countryList.find(o => o.id === id);
    //     if (returnRecord != null) {
    //         return returnRecord.name;
    //     }
    // }
    const getCountryLabel = (id) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.REF_CODE === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get Brand 
    var [brandList, setBrandList] = useState([]);
    const getBrandLabel = (id) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get PG
    var [productGroupList, setProductGroupList] = useState([]);
    const getProductGroupLabel = (id) => {
        let _productGroupList = [...productGroupList];
        var returnRecord = _productGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get Customer Category
    var [customerCategoryList, setCustomerCategoryList] = useState([]);
    const getCustomerCategoryLabel = (id) => {
        let _productGroupList = [...customerCategoryList];
        var returnRecord = _productGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get customer
    var [customerList, setCustomerList] = useState([]);
    // const getCustomerLabel = (id) => {
    //     let _customerList = [...customerList];
    //     var returnRecord = _customerList.find(o => o.id === id);
    //     if (returnRecord != null) {
    //         return returnRecord.name;
    //     }
    // }
    const filtereUpdateDataOnQuarter = (data) => {

        switch (quarter.value) {
            case "Quarter 1":
                return data = data.filter(c => Number(c.monthNo) >= 4 && Number(c.monthNo) <= 6)
            case "Quarter 2":
                return data = data.filter(c => Number(c.monthNo) >= 7 && Number(c.monthNo) <= 9)
            case "Quarter 3":
                return data = data.filter(c => Number(c.monthNo) >= 10 && Number(c.monthNo) <= 12)
            case "Quarter 4":
                return data;
            default:
                return data;
        }

    }

    // const [calculateDrilldownData, setCalculateDrilldownData] = useState([]);

    // let drillDownTargetData = async () => {

    //     let financialYearId = ""

    //     if (financialYear.value) { financialYearId = financialYear.value } else {
    //         let selectedYear = reactLocalStorage.getObject('selectedYear');
    //         financialYearId = selectedYear.value
    //     }

    //     var targetsResponses = loggedEmployeeTargets.find(o => o.financial_year_id === financialYearId);
    //     if (targetsResponses) {
    //         var targetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: targetsResponses.id } }])

    //         let finalDrilldownData = [];

    //         for (let i = 0; i < targetDetails.length; i++) {

    //             let actualsDetails = actualDetailsData.filter(c => c.target_details_id === targetDetails[i].id)

    //             let finalDrilldownTargetData = []

    //             if (actualsDetails.length > 0) {
    //                 for (let j = 0; j < actualsDetails.length; j++) {


    //                     let parameter = parametersList.find(p => p.id === targetDetails[i].parameter_id);
    //                     let data = {
    //                         "id": actualsDetails[j].id,
    //                         "buName": actualsDetails[j].BU,
    //                         "regionName": actualsDetails[j].region,
    //                         "countryName": actualsDetails[j].country, "brandName": actualsDetails[j].brand,
    //                         "pGName": actualsDetails[j].productGroup,
    //                         "custCatName": actualsDetails[j].customerGroupCode, "customerName": actualsDetails[j].customerGroupCode,
    //                         "target_Actual_value": Math.round(actualsDetails[j].actual_value),
    //                         "month": getMonthName(actualsDetails[j].monthNo),
    //                         "parameterName": parameter ? parameter.name : ''
    //                     }

    //                     finalDrilldownTargetData.push(data)
    //                     if (j === actualsDetails.length - 1) {
    //                         finalDrilldownData.push(finalDrilldownTargetData)
    //                     }
    //                 }
    //             }

    //             // if (i === targetDetails.length - 1) {

    //             // }
    //         }
    //         let finalDrilldownArray = convertArrayToObject(finalDrilldownData)
    //         setCalculateDrilldownData(finalDrilldownArray)

    //     } else {
    //         setCalculateDrilldownData([]);
    //     }
    // }

    // useEffect(() => {
    //     if (calculateDrilldownData.length > 0) {
    //         show_DrillDownChart();
    //     }
    // }, [calculateDrilldownData])

    const show_DrillDownChart = async () => {
        // if (calculateDrilldownData.length > 0) {
        if (level1.value === "" || level1.value === undefined) {
            toast.error('Please Select Level One.');
            let convertTree = {
                "name": selectedParameter.label,
                "children": [],
            }
            setDrillDownData(convertTree)
            setShowDrillDownChart(true)
        } else {
            let getTargetDetailId = current_Target_Details.find(e => e.parameter_id === selectedParameter.value)


            // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(getTargetDetailId.id))
            var getPlanDetails = await graphQLGetAllData("listActualDetails", [{ target_details_id: { eq: getTargetDetailId.id } }])

            // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(loggedEmployeeId));
            var loggedEmployeeResponse = await graphQLFindRecordById("getEmployee", loggedEmployeeId)

            if (loggedEmployeeResponse) {
                var param_sbu_id = loggedEmployeeResponse.param_sbu_id;
            }
            let updateData = []

            for (let i = 0; i < getPlanDetails.length; i++) {

                let SBU = ""
                var getSBU = await getSBULabel(param_sbu_id);
                if (getSBU !== null && getSBU !== "" && getSBU !== undefined) { SBU = getSBU }

                let BU = ""
                var getBU = await getBULabel(getPlanDetails[i].bu_id);
                if (getBU !== "" && getBU !== undefined) { BU = getBU }

                let Region = ""
                var getRegion = await getRegionLabel(getPlanDetails[i].region_id);
                if (getRegion !== "" && getRegion !== undefined) { Region = getRegion }

                let Country = ""
                var getCountry = await getCountryLabel(getPlanDetails[i].country);
                if (getCountry !== "" && getCountry !== undefined) { Country = getCountry }

                let Brand = ""
                var getBrand = await getBrandLabel(getPlanDetails[i].brand_id);
                if (getBrand !== "" && getBrand !== undefined) { Brand = getBrand }

                let ProductGroup = ""
                var getProductGroup = await getProductGroupLabel(getPlanDetails[i].product_group_id);
                if (getProductGroup !== "" && getProductGroup !== undefined) { ProductGroup = getProductGroup }

                let CustomerGroup = ""
                var getCustomerCategory = await getCustomerCategoryLabel(getPlanDetails[i].customer_group_id);
                if (getCustomerCategory !== "" && getCustomerCategory !== undefined) { CustomerGroup = getCustomerCategory }

                let Customer = ""
                // var getCustomer = await getCustomerLabel(getPlanDetails[i].customer_id);
                var getCustomer = await graphQLFindRecordById("getCustomer", getPlanDetails[i].customer_id)


                if (getCustomer !== "" && getCustomer !== undefined) { Customer = getCustomer }

                // let filteredDrilldownData = calculateDrilldownData.filter(c => c.parameterName === selectedParameter.label);

                // let targetValue = 0;
                // if (filteredDrilldownData.length > 0) {
                //     targetValue = filteredDrilldownData.reduce((sum, item) => {
                //         return sum + Number(item.target_Actual_value);
                //     }, 0);
                // }

                let data = {
                    "id": getPlanDetails[i].id,
                    "target_detail_id": getPlanDetails[i].target_details_id,
                    "target_value": getPlanDetails[i].actual_value,
                    "SBU": getPlanDetails[i].SBU,
                    "BU": getPlanDetails[i].BU,
                    "Region": getPlanDetails[i].region,
                    "Country": Country,
                    "Brand": getPlanDetails[i].brand,
                    "ProductGroup": getPlanDetails[i].productGroup,
                    "CustomerGroup": getPlanDetails[i].customerGroupCode,
                    "Customer": getPlanDetails[i].customerCode,
                    "monthNo": getPlanDetails[i].monthNo,
                    // "id": getPlanDetails[i].id,
                    // "target_detail_id": getPlanDetails[i].target_detail_id,
                    // "target_value": targetValue,
                    "sbu_id": getPlanDetails[i].sbu_id,
                    "region_id": getPlanDetails[i].region_id,
                    "country_id": getPlanDetails[i].country_id,
                    "brandId": getPlanDetails[i].brand_id,
                    "product_group_id": getPlanDetails[i].product_group_id,
                    "customer_group_id": getPlanDetails[i].customer_group_id,
                    "customer_id": getPlanDetails[i].customer_id,
                    "q1_target_value": getPlanDetails[i].q1_target_value,
                    "q2_target_value": getPlanDetails[i].q2_target_value,
                    "q3_target_value": getPlanDetails[i].q3_target_value,
                    "q4_target_value": getPlanDetails[i].q4_target_value,
                    "m1_target_value": getPlanDetails[i].m1_target_value,
                    "m2_target_value": getPlanDetails[i].m2_target_value,
                    "m3_target_value": getPlanDetails[i].m3_target_value,
                    "m4_target_value": getPlanDetails[i].m4_target_value,
                    "m5_target_value": getPlanDetails[i].m5_target_value,
                    "m6_target_value": getPlanDetails[i].m6_target_value,
                    "m7_target_value": getPlanDetails[i].m7_target_value,
                    "m8_target_value": getPlanDetails[i].m8_target_value,
                    "m9_target_value": getPlanDetails[i].m9_target_value,
                    "m10_target_value": getPlanDetails[i].m10_target_value,
                    "m11_target_value": getPlanDetails[i].m11_target_value,
                    "m12_target_value": getPlanDetails[i].m12_target_value,
                    "sub_REF_CODE": getPlanDetails[i].sub_REF_CODE,
                    "region_REF_CODE": getPlanDetails[i].region_REF_CODE,
                    "country_REF_CODE": getPlanDetails[i].country_REF_CODE,
                    "brand_REF_CODE": getPlanDetails[i].brand_REF_CODE,
                    "product_group_REF_CODE": getPlanDetails[i].product_group_REF_CODE,
                    "customer_group_REF_CODE": getPlanDetails[i].customer_group_REF_CODE,
                    "customer_REF_CODE": getPlanDetails[i].customer_REF_CODE,
                    "m1_plan_actual_value": getPlanDetails[i].m1_plan_actual_value,
                    "m2_plan_actual_value": getPlanDetails[i].m2_plan_actual_value,
                    "m3_plan_actual_value": getPlanDetails[i].m3_plan_actual_value,
                    "m4_plan_actual_value": getPlanDetails[i].m4_plan_actual_value,
                    "m5_plan_actual_value": getPlanDetails[i].m5_plan_actual_value,
                    "m6_plan_actual_value": getPlanDetails[i].m6_plan_actual_value,
                    "m7_plan_actual_value": getPlanDetails[i].m7_plan_actual_value,
                    "m8_plan_actual_value": getPlanDetails[i].m8_plan_actual_value,
                    "m9_plan_actual_value": getPlanDetails[i].m9_plan_actual_value,
                    "m10_plan_actual_value": getPlanDetails[i].m10_plan_actual_value,
                    "m11_plan_actual_value": getPlanDetails[i].m11_plan_actual_value,
                    "m12_plan_actual_value": getPlanDetails[i].m12_plan_actual_value,
                    "bu_id": getPlanDetails[i].bu_id,
                    "bu_REF_CODE": getPlanDetails[i].bu_REF_CODE,
                    // "SBU": SBU,
                    // "BU": BU,
                    // "Region": Region,
                    // "Country": Country,
                    // "Brand": Brand,
                    // "ProductGroup": ProductGroup,
                    // "CustomerGroup": CustomerGroup,
                    // "Customer": Customer
                }
                updateData.push(data)

                if (i === getPlanDetails.length - 1) {
                    let filteredData = filtereUpdateDataOnQuarter(updateData);
                    const tree = await buildTree(filteredData, [level1.value, level2.value, level3.value, level4.value]);
                    let convertTree = {
                        "name": selectedParameter.label,
                        "children": tree,
                    }
                    setDrillDownData(convertTree)
                    setShowDrillDownChart(true)
                }
            }
        }


    }

    //First Level Tree
    const buildTree = async (treeData, keys) => {

        let newResponse = [];

        let drillDownDataColor = {
            borderColor: '#1B55AF',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#1B55AF',
            symbolSize: 7,
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#1B55AF',
                borderColor: '30px solid rgba(27, 85, 175, 0.8)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#1B55AF",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#1B55AF',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#1B55AF',
        }

        let sumFromArr = sumFromArray("target_value", treeData)
        for (let i = 0; i < treeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = treeData[i][level1.value]
            if (checkDuplicate.length > 0) {
                let check_duplicateData = checkDuplicate.every((e) => e.name !== response)

                if (check_duplicateData === true) {
                    let levelTwoResponse = await buildSecondTree(treeData, response)
                    let data = {
                        name: response,
                        totalTargetValue: Math.round(sumFromArr),
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`asha`)
                                    }
                                }
                            }
                        },
                        children: levelTwoResponse,

                    }
                    newResponse.push(data)
                }
            }
            else {
                let levelTwoResponse = await buildSecondTree(treeData, response)
                let data = {
                    name: response,
                    totalTargetValue: Math.round(sumFromArr),
                    itemStyle: drillDownDataColorItemStyle,
                    "lineStyle": drillDownDataColor,
                    label: {
                        normal: {
                            verticalAlign: 'middle',
                            fontSize: 14,
                            formatter: function (d) {
                                if (d.value) {
                                    return (`asha`)
                                }
                            }
                        }
                    },
                    children: levelTwoResponse,

                }
                newResponse.push(data)
            }
            if (i === treeData.length - 1) {

                newResponse.map((item, index) => {
                    let getChildData = item.children
                    let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    if ((level2.value === "" || level2.value === undefined) && (level3.value === "" || level3.value === undefined) && (level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        item.totalTargetValue = treeData[i].target_value;
                        // item.name = response + "(" + treeData[i].target_value + ")"
                        item.name = item.name + " " + commaSeparated(Math.round(treeData[i].target_value))
                    } else {
                        item.children = item.children;
                        item.totalTargetValue = totalOfTargets;
                        // item.name = response + "(" + totalOfTargets + ")"
                        item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    }
                })

            }
        }
        return newResponse
    }

    //Second Level Tree
    const buildSecondTree = async (secondTreeData, levelOneValue) => {

        let drillDownDataColor = {
            borderColor: '#136EF8',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#136EF8',
            symbolSize: 7,
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#136EF8',
                borderColor: '30px solid rgba(19, 110, 248, 0.75)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#136EF8",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#136EF8',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#136EF8',
        }

        let newResponse = [];
        for (let i = 0; i < secondTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = secondTreeData[i][level2.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = secondTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = secondTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = secondTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = secondTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = secondTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = secondTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = secondTreeData[i].Customer; }

            // const key = Object.keys(secondTreeData[i]).find(key => secondTreeData[i][key] === levelOneValue);

            if (firstLevelKeyValue === levelOneValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {

                        let getTargetValue = secondTreeData.filter((e) => e[level2.value] === response)
                        let sumFromArr = sumFromArray("target_value", getTargetValue)

                        let levelThreeResponse = await buildThirdTree(secondTreeData, levelOneValue, response)
                        let data = {
                            name: response,
                            totalTargetValue: Math.round(sumFromArr),
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`asha`)
                                        }
                                    }
                                }
                            },
                            children: levelThreeResponse,

                        }
                        newResponse.push(data)
                    }
                }
                else {

                    let getTargetValue = secondTreeData.filter((e) => e[level2.value] === response)
                    let sumFromArr = sumFromArray("target_value", getTargetValue)

                    let levelThreeResponse = await buildThirdTree(secondTreeData, levelOneValue, response)
                    let data = {
                        name: response,
                        totalTargetValue: Math.round(sumFromArr),
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`asha`)
                                    }
                                }
                            }
                        },
                        children: levelThreeResponse
                    }
                    newResponse.push(data)
                }
            }

            if (i === secondTreeData.length - 1) {

                newResponse.map((item, index) => {
                    let getChildData = item.children
                    let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    // item.name = item.name + "(" + totalOfTargets + ")"
                    item.totalTargetValue = totalOfTargets;
                    if ((level3.value === "" || level3.value === undefined) && (level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        item.totalTargetValue = secondTreeData[i].target_value;
                        item.name = item.name + " " + commaSeparated(Math.round(secondTreeData[i].target_value))

                    } else {
                        item.children = item.children;
                        item.totalTargetValue = totalOfTargets;
                        item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    }
                })
            }
        }
        return newResponse
    }

    //Third Level Tree
    const buildThirdTree = async (thirdTreeData, levelOneValue, levelTwoValue) => {

        let drillDownDataColor = {
            borderColor: '#F0074D',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            symbolSize: 7,
            color: '#F0074D',
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#F0074D',
                borderColor: '20px solid rgba(240, 7, 77, 0.75)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#F0074D",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#F0074D',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#F0074D',
        }

        let newResponse = [];
        for (let i = 0; i < thirdTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = thirdTreeData[i][level3.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = thirdTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = thirdTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = thirdTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = thirdTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = thirdTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = thirdTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = thirdTreeData[i].Customer; }

            let secondLevelKeyValue;
            if (level2.value === "BU") { secondLevelKeyValue = thirdTreeData[i].BU; } else if (level2.value === "Region") { secondLevelKeyValue = thirdTreeData[i].Region; }
            else if (level2.value === "Country") { secondLevelKeyValue = thirdTreeData[i].Country; } else if (level2.value === "Brand") { secondLevelKeyValue = thirdTreeData[i].Brand; } else if (level2.value === "ProductGroup") { secondLevelKeyValue = thirdTreeData[i].ProductGroup; }
            else if (level2.value === "CustomerGroup") { secondLevelKeyValue = thirdTreeData[i].CustomerGroup; } else if (level2.value === "Customer") { secondLevelKeyValue = thirdTreeData[i].Customer; }

            // const firstLevelKey = Object.keys(thirdTreeData[i]).find(key => thirdTreeData[i][key] === levelOneValue);
            // const secondLevelKey = Object.keys(thirdTreeData[i]).find(key => thirdTreeData[i][key] === levelTwoValue);

            if (firstLevelKeyValue === levelOneValue && secondLevelKeyValue === levelTwoValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {

                        let levelForthResponse = await buildForthTree(thirdTreeData, levelOneValue, levelTwoValue, response)

                        let getTargetValue = thirdTreeData.filter((e) => e[level3.value] === response)
                        let sumFromArr = sumFromArray("target_value", getTargetValue)

                        let data = {
                            name: response,
                            totalTargetValue: Math.round(sumFromArr),
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`asha`)
                                        }
                                    }
                                }
                            },
                            children: levelForthResponse,
                        }
                        newResponse.push(data)
                    }
                }
                else {
                    let levelForthResponse = await buildForthTree(thirdTreeData, levelOneValue, levelTwoValue, response)
                    let getTargetValue = thirdTreeData.filter((e) => e[level3.value] === response)
                    let sumFromArr = sumFromArray("target_value", getTargetValue)
                    let data = {
                        name: response,
                        totalTargetValue: Math.round(sumFromArr),
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`asha`)
                                    }
                                }
                            }
                        },
                        children: levelForthResponse,
                    }
                    newResponse.push(data)
                }
            }

            if (i === thirdTreeData.length - 1) {
                newResponse.map((item, index) => {
                    let getChildData = item.children
                    let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    // item.name = item.name + "(" + totalOfTargets + ")"
                    item.totalTargetValue = totalOfTargets;
                    if ((level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        item.totalTargetValue = thirdTreeData[i].target_value;
                        item.name = item.name + " " + commaSeparated(Math.round(thirdTreeData[i].target_value))
                    } else {
                        item.children = item.children;
                        item.totalTargetValue = totalOfTargets;
                        item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    }
                })
            }
        }
        return newResponse
    }


    //Forth Level Tree
    const buildForthTree = async (forthTreeData, levelOneValue, levelTwoValue, levelThreeValue) => {

        let drillDownDataColor = {
            borderColor: '#FF7F01',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#FF7F01',
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#FF7F01',
                borderColor: '30px solid #FF7F01'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#FF7F01",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#FF7F01',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#FF7F01',
            width: 20,
        }

        let newResponse = [];

        for (let i = 0; i < forthTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = forthTreeData[i][level4.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = forthTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = forthTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = forthTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = forthTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = forthTreeData[i].Customer; }

            let secondLevelKeyValue;
            if (level2.value === "BU") { secondLevelKeyValue = forthTreeData[i].BU; } else if (level2.value === "Region") { secondLevelKeyValue = forthTreeData[i].Region; }
            else if (level2.value === "Country") { secondLevelKeyValue = forthTreeData[i].Country; } else if (level2.value === "Brand") { secondLevelKeyValue = forthTreeData[i].Brand; } else if (level2.value === "ProductGroup") { secondLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level2.value === "CustomerGroup") { secondLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level2.value === "Customer") { secondLevelKeyValue = forthTreeData[i].Customer; }

            let thirdLevelKeyValue;
            if (level3.value === "BU") { thirdLevelKeyValue = forthTreeData[i].BU; } else if (level3.value === "Region") { thirdLevelKeyValue = forthTreeData[i].Region; }
            else if (level3.value === "Country") { thirdLevelKeyValue = forthTreeData[i].Country; } else if (level3.value === "Brand") { thirdLevelKeyValue = forthTreeData[i].Brand; } else if (level3.value === "ProductGroup") { thirdLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level3.value === "CustomerGroup") { thirdLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level3.value === "Customer") { thirdLevelKeyValue = forthTreeData[i].Customer; }


            if (firstLevelKeyValue === levelOneValue && secondLevelKeyValue === levelTwoValue && thirdLevelKeyValue === levelThreeValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {
                        let data = {
                            name: response,
                            targetValue: [forthTreeData[i].target_value],
                            totalTargetValue: "",
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`asha`)
                                        }
                                    }
                                }
                            },
                            children: [],

                        }
                        newResponse.push(data)
                    } else {
                        checkDuplicate[0].targetValue.push(forthTreeData[i].target_value)
                    }
                }
                else {
                    let data = {
                        name: response,
                        targetValue: [forthTreeData[i].target_value],
                        totalTargetValue: "",
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`asha`)
                                    }
                                }
                            }
                        },
                        children: [],
                    }
                    newResponse.push(data)
                }
            }

            if (i === forthTreeData.length - 1) {
                newResponse.map((item, index) => {
                    let totalOfTargets = item.targetValue.reduce((total, num) => total + parseInt(num), 0);
                    // item.name = item.name + "(" + totalOfTargets + ")"
                    item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    item.totalTargetValue = totalOfTargets;
                })
            }
        }
        return newResponse

    }


    const option = {
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series: [
            {
                type: 'tree',
                data: [drillDownData],
                top: '1%',
                left: '7%',
                bottom: '1%',
                edgeShape: 'curve',
                right: '20%',
                symbolSize: 80,
                height: 500,
                label: {
                    normal: {
                        verticalAlign: 'middle',
                        align: 'middle',
                        fontSize: 14,
                        formatter: function (d) {
                            if (d.value) {
                                return (`asha`)
                            }
                        }
                    }
                },
                lineStyle: {
                    width: 20,
                    color: '#FF7F01CC'
                },
                leaves: {
                    label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left'
                    }
                },
                initialTreeDepth: 1,
                expandAndCollapse: true,
                animationDuration: 550,
                animationDurationUpdate: 750
            },

        ]
    };


    //Bar chart
    const barChartOption = {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            data: ['Qtr 1', 'Qtr 2', 'Qtr 3', 'Qtr 4']
        },
        series: [
            {
                name: 'Target',
                type: 'bar',
                data: quarterTargetValue,
                itemStyle: {
                    color: '#4FB155' // Change the color to blue
                }
            },
            {
                name: 'Actuals',
                type: 'bar',
                data: quarterActualsValue,
                itemStyle: {
                    color: '#268AFF' // Change the color to blue
                }
            }
        ]
    };

    //onClick Parameters
    function onClickParameter(data) {
        LoadBrandsData(data)
        setQuarterTargetValue([parseFloat(data.q1_target_value).toFixed(2), parseFloat(data.q2_target_value).toFixed(2), parseFloat(data.q3_target_value).toFixed(2), parseFloat(data.q4_target_value).toFixed(2)])
        setQuarterActualsValue([parseFloat(data.q1_actual_value).toFixed(2), parseFloat(data.q2_actual_value).toFixed(2), parseFloat(data.q3_actual_value).toFixed(2), parseFloat(data.q4_actual_value).toFixed(2)])
        setParameterName(data.parameter_name)
    }
    const validateData = (rowData) => {
        // return !isNaN(options.target_value) ? (options.target_value) : 0
        if (rowData.target_value !== "") {
            return (
                <React.Fragment>
                    {rowData.parameter_type === PARAMETERS_TYPES_NAMES.A ? (
                        <label>
                            {rowData.parameter_type}
                            {commaSeparated(
                                parseFloat(Math.round(rowData.target_value)),
                            )}{" "}
                        </label>
                    ) : rowData.parameter_type === PARAMETERS_TYPES_NAMES.P ? (
                        <label>
                            {parseFloat(Math.round(rowData.target_value))}
                            {rowData.parameter_type}
                        </label>
                    ) : (
                        <label>
                            {parseFloat(Math.round(rowData.target_value))}{" "}
                            {rowData.parameter_type}
                        </label>
                    )}
                </React.Fragment>
            );
        }
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <React.StrictMode>
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                    <div className="p-5 pb-5">
                        {
                            showData &&
                            <div>
                                <div className="sm:flex items-center space-x-8">
                                    <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                                    <div className="tabs_two">
                                        <ul className="flex">
                                            {/* <li><Link to="/employee/dashboard/business" className="active">Business</Link></li>
                                            {!checkEmployeeTeamType() && <li><Link to="/employee/dashboard/sales/planning">Sales</Link></li>} */}
                                            {checkEmployeeTeamType() ? <li><Link to="/employee/dashboard/business" className="active">Business</Link></li> :
                                                <li><Link to="/employee/dashboard/sales/planning">Sales</Link></li>}

                                        </ul>
                                    </div>
                                </div>
                                <div className="flex justify-end my-6 items-center align-center">
                                    <div className="mt-5 lg:mt-0 flex justify-end gap-2 items-center">
                                        {
                                            activeIndex === 2 &&
                                            <div className="w-40">
                                                <Select
                                                    options={parameterList}
                                                    value={parameter}
                                                    onChange={(e) => {
                                                        handleClickParameterDropdown(e);
                                                    }}
                                                    maxMenuHeight={200} placeholder="Parameters"
                                                    className="my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>
                                        }

                                        <div className="w-40">
                                            <Select
                                                options={financialYearList}
                                                value={financialYear}
                                                onChange={(e) => {
                                                    handleClickFinanCialYearDropdown(e);
                                                }}
                                                maxMenuHeight={200} placeholder="Select Year"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        {
                                            activeIndex !== 2 &&
                                            <div className="w-40">
                                                <Select
                                                    options={quarterList}
                                                    value={quarter}
                                                    onChange={(e) => {
                                                        setQuarter(e)
                                                        handleClickQuarterDropdown(e)
                                                    }}
                                                    maxMenuHeight={200} placeholder="Select Quarter"
                                                    className="my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>
                                        }

                                        {

                                            activeIndex === 2 &&
                                            <div className="w-40">
                                                <Select
                                                    options={monthList}
                                                    value={selectedMonth}
                                                    onChange={(e) => {
                                                        setSelectedMonth(e)
                                                        handleClickMonthDropDown(e)
                                                    }}
                                                    maxMenuHeight={200} placeholder="Select Month"
                                                    className="my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>
                                        }

                                        {isDataLoadedForDetailedView &&
                                            isDetailedViewLoaded === true ?
                                            <div>
                                                <Link to='' className="border border-gray-300 shadow-md rounded-lg text-sm px-2 py-3 font-semibold text-white
                                              bg-[#019049]                                               " onClick={handleDownload}>
                                                    <i className='icon download_icon'></i>
                                                    Download
                                                </Link>
                                                <CSVLink
                                                    {...csvReport}
                                                    ref={csvLinkRef}>
                                                    <></>
                                                </CSVLink>
                                            </div> : null
                                        }

                                    </div>
                                </div>

                                {
                                    summaryData.length > 0 ?
                                        <div className='circulartab mt-5'>
                                            <TabView activeIndex={activeIndex} onTabChange={(e) => {
                                                setActiveIndex(e.index)
                                            }}>
                                                <TabPanel header="Summary">
                                                    <div className="bg-white rounded-lg p-3 px-5 dark:bg-[#2A2E32] shadow-lg">
                                                        <div className="flex gap-8 h-auto divide-x-2 divide-[#E4E7EC] dark:divide-[#1b1f22]">
                                                            <div className='flex  items-center gap-4'>
                                                                <img src={loggedEmployeeProfile} alt="" className='w-14 h-14 rounded-full' />
                                                                <div>
                                                                    <p className="text-[#667085] text-[13px] font-normal"> Emp ID: {loggedEmployeeCode}</p>
                                                                    <h3 className="text-[#101828] text-[16px] font-semibold dark:text-white">{loggedEmployeeName}</h3>
                                                                </div>
                                                            </div>
                                                            <div className='flex  items-center gap-3 px-4'>
                                                                <div>
                                                                    <p className="text-[#667085] text-[13px] font-normal"> Role</p>
                                                                    <h3 className="text-[#101828] text-[16px] font-semibold  dark:text-white">{loggedEmployeeRole}</h3>
                                                                </div>
                                                            </div>
                                                            <div className='flex  items-center gap-3 px-4'>
                                                                <div>
                                                                    <p className="text-[#667085] text-[13px] font-normal"> SBU</p>
                                                                    <h3 className="text-[#101828] text-[16px] font-semibold  dark:text-white">{sbu}</h3>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5">
                                                        <div className="flex-row grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">

                                                            <div className='border border-[#E4E7EC] py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                                <h4 className='text-[#101828] text-[#101828] text-[16px] mb-2  font-medium dark:text-[#fff]'>{"Consolidated Score"}</h4>
                                                                <div className='flex mt-5 gap-6 items-center'>
                                                                    <div className='grow'>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[30px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {consolidatedValue + "%"}
                                                                        </h3>
                                                                        <h6 className='text-[#667085] text-[14px] text-[14px] dark:text-[#f2f2f2]'>
                                                                            Weightage
                                                                        </h6>
                                                                    </div>
                                                                    <div className='w-24 h-24 mx-auto'>
                                                                        <CircularProgressBar value={parseInt(consolidatedScore)} text={isFinite(consolidatedScore) ? consolidatedScore + "%" : "0%"} pathColor="#1882FF" trailColor="#83C888" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='border border-[#E4E7EC] py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                                <h4 className='text-[#101828] text-[#101828] text-[14px] mb-2  font-medium dark:text-[#fff]'>{"Overall Score (Capped to 80)"}</h4>
                                                                <div className='flex mt-5 gap-6 items-center'>
                                                                    {/* <div className='grow'>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[30px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {consolidatedScore >= 80 ? 80 : consolidatedScore + "%"}
                                                                        </h3>
                                                                        <h6 className='text-[#667085] text-[14px] text-[14px] dark:text-[#f2f2f2]'>
                                                                            Weightage
                                                                        </h6>
                                                                    </div> */}
                                                                    <div className='w-24 h-24 mx-auto'>
                                                                        <CircularProgressBar value={parseInt(consolidatedScore)} text={consolidatedScore >= 80 ? 80 : consolidatedScore + "%"} pathColor="#1882FF" trailColor="#83C888" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {

                                                                summaryData.map((item, index) => {
                                                                    return (
                                                                        <Link key={index.toString()} id={index.toString()} onClick={() => { onClickParameter(item); }}
                                                                            className={
                                                                                item.parameter_name !== parameterName ?
                                                                                    "border border-[#E4E7EC]  py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]" :
                                                                                    "border border-[green] border-2 bg-[#f3fffd] py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]"
                                                                            }>
                                                                            <h4 className='  text-[16px] mb-2  font-medium dark:text-[#fff]'>{item.parameter_name}</h4>
                                                                            <div className='flex mt-5 gap-6 items-center'>
                                                                                <div className='grow'>
                                                                                    <h3 className='text-[#344054]  text-[30px] font-semibold leading-none dark:text-[#fff]'>
                                                                                        {item.parameter_weightage === "-" ? "-" : parseInt(item?.parameter_weightage) + "%"}
                                                                                    </h3>
                                                                                    <h6 className='text-[14px] dark:text-[#f2f2f2]'>
                                                                                        Weightage
                                                                                    </h6>
                                                                                </div>
                                                                                <div className='w-24 h-24 mx-auto'>
                                                                                    <CircularProgressBar value={parseInt(item.originalWtAch)} text={item.weightPercentage} pathColor={"#1882FF"} trailColor="#83C888" />
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    )

                                                                })

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>

                                                        <div className="flex-row grid 2xl:grid-cols-7 xl:grid-cols-7 lg:grid-cols-7 md:grid-cols-6 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">
                                                            <div className='bg-white col-span-3 border border-[#E4E7EC] py-4 px-4 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                                <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'> Actual vs Target - {parameterName}</h6>

                                                                <div>
                                                                    <ReactEcharts option={barChartOption} />
                                                                </div>
                                                            </div>
                                                            {
                                                                parameterName !== "NWC Days" ?
                                                                    <div className='col-span-4 bg-white border border-[#E4E7EC] py-4 px-4 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                                        {brandsDataSpinner === false ?
                                                                            <div>
                                                                                <div className='flex justify-between'>
                                                                                    <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'> Brands (Actual Ach.%) - {parameterName}</h6>
                                                                                </div>
                                                                                <div className={'flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap- gap-y-4'}>
                                                                                    {brandsData.length > 0 ?
                                                                                        brandsData.map((item, index) => {
                                                                                            // if (index >= 5) {
                                                                                            //     return null
                                                                                            // } else {
                                                                                            return (
                                                                                                <div key={index.toString()}>
                                                                                                    <GaugeChart
                                                                                                        key={index}
                                                                                                        name={item.Brand}
                                                                                                        value={item.Percentage}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                            // }
                                                                                        }) : "No data found"
                                                                                    }
                                                                                </div>

                                                                            </div> :

                                                                            <div className='text-center my-auto py-8' ><ProgressSpinner /></div>
                                                                        }
                                                                    </div> :
                                                                    <div className='col-span-4 bg-white border border-[#E4E7EC] py-4 px-4 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                                        <div className='flex justify-between'>
                                                                            <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'> Brands (Actual Ach.%) - {parameterName}</h6>
                                                                        </div>
                                                                        <div className='flex justify-center align-center'>
                                                                            <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'>No Brands Available for NWC Days</h6>
                                                                        </div>
                                                                    </div>

                                                            }

                                                        </div>
                                                    </div>
                                                    <div className='accordionmulti mt-5'>
                                                        <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>
                                                            <DataTable
                                                                paginator value={summaryData} responsiveLayout="scroll" style={{ width: '93vw' }}
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                                            >
                                                                {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                                                                <Column field="parameter_name" footer="Total" header="Parameter" sortable filter filterPlaceholder="Search by Name"></Column>
                                                                <Column field="parameter_type" header="Unit" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                                                <Column field="parameter_weightage" header="Weightage" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                                                <Column field="target_value" header="Target" sortable filter filterPlaceholder="Search by Name" body={validateData}></Column>
                                                                <Column body={actualPerformanceTemplate} header="Actual Performance" sortable filter filterPlaceholder="Search by Role"></Column>
                                                                <Column field="actualPercentage" header="Actual %Ach" sortable filter filterPlaceholder="Search by Role"></Column>
                                                                <Column field="weightPercentage" header="Wt Ach(%)" sortable filter filterPlaceholder="Search by Role"></Column>
                                                                {/* <Column field="" header="Score %" sortable filter filterPlaceholder="Search by Role"></Column> */}
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Drilldown Analysis">
                                                    <div className='flex gap-2 justify-end'>
                                                        <div className='width-20'>
                                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'>Choose Parameter</label>
                                                            {/* /*********** dummy select2 ********** */}
                                                            <Select
                                                                options={parametersForSelect}
                                                                value={selectedParameter}
                                                                onChange={e => { setSelectedParameter(e) }}
                                                                maxMenuHeight={200}
                                                                placeholder="Select Parameter"
                                                                className="my-react-select-container"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>
                                                        <div className='width-20'>
                                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 1</label>
                                                            <Select
                                                                options={levelList}
                                                                value={level1}
                                                                onChange={e => { setLevel1(e) }}
                                                                maxMenuHeight={200}
                                                                placeholder="Select"
                                                                className="my-react-select-container"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div className='width-20'>
                                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 2</label>
                                                            <Select
                                                                options={levelList}
                                                                value={level2}
                                                                onChange={e => { setLevel2(e) }}
                                                                maxMenuHeight={200}
                                                                placeholder="Select"
                                                                className="my-react-select-container"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>
                                                        <div className='width-20'>
                                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 3</label>
                                                            <Select
                                                                options={levelList}
                                                                value={level3}
                                                                onChange={e => { setLevel3(e) }}
                                                                maxMenuHeight={200}
                                                                placeholder="Select"
                                                                className="my-react-select-container"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div className='width-20'>
                                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 4</label>
                                                            <Select
                                                                options={levelList}
                                                                value={level4}
                                                                onChange={e => { setLevel4(e) }}
                                                                maxMenuHeight={200}
                                                                placeholder="Select"
                                                                className="my-react-select-container"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div className='mt-8'>
                                                            <Link onClick={() => { show_DrillDownChart() }} className={"bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2"}>
                                                                Go
                                                            </Link>
                                                        </div>

                                                    </div>
                                                    <div className='drilldown'>
                                                        {
                                                            showDrillDownChart &&
                                                            <ReactEcharts option={option} />
                                                        }
                                                    </div>

                                                </TabPanel>
                                                {/* // LAST TAB */}
                                                <TabPanel header="Detailed View">
                                                    <div className='accordionmulti mt-5'>
                                                        <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>

                                                            {isDataLoadedForDetailedView &&
                                                                isDetailedViewLoaded === true ?
                                                                <DataTable
                                                                    paginator value={parameterDetailViewList} responsiveLayout="scroll" style={{ width: '93vw' }}
                                                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 200]}
                                                                >
                                                                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                                                                    <Column field="buName" header="BU" sortable filter filterPlaceholder="Search by BU"></Column>
                                                                    <Column field="regionName" header="Region Name" sortable filter filterPlaceholder="Search by Region Name"></Column>
                                                                    <Column field="countryName" header="Country Name" sortable filter filterPlaceholder="Search by Country Name"></Column>
                                                                    <Column field="brandName" header="Brand Name" sortable filter filterPlaceholder="Search by Brand Name"></Column>
                                                                    <Column field="pGName" header="Product Group" sortable filter filterPlaceholder="Search by Product Group"></Column>
                                                                    <Column field="custCatName" header="Customer Cat" sortable filter filterPlaceholder="Search by Customer Cat"></Column>
                                                                    <Column field="customerName" header="Customer" sortable filter filterPlaceholder="Search by Customer"></Column>
                                                                    <Column field="month" header="Month" sortable filter filterPlaceholder="Search by Month"></Column>
                                                                    <Column body={getParameterName} header="Parameter Name" sortable filter filterPlaceholder="Search by Parameter Name"></Column>
                                                                    <Column field="target_Actual_value" header="Actual Value" sortable filter filterPlaceholder="Search by Actual"></Column>
                                                                </DataTable>

                                                                :

                                                                <div className='text-center py-8' ><ProgressSpinner /></div>
                                                            }


                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            </TabView>
                                        </div> :
                                        <div className='items-center justify-center text-center text-[22px]'>No Data Found</div>
                                }

                            </div>
                        }

                        {
                            !showData &&
                            <div className='text-center py-8' ><ProgressSpinner /></div>
                        }

                    </div>

                </div>


            </React.StrictMode >
        </div >
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}