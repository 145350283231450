import { Avatar } from "@mui/material";
import popupHeaderImg from "../../../assets/images/popup_header_img.png";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from 'primereact/progressspinner';
import {
  ShowInMillion,
  commaSeparated,
} from "../../../components/helper/maths";
import React, { useEffect, useState } from "react";
import {
  PARAMETERS_TYPES,
  PARAMETERS_TYPES_NAMES,
  PARAMETER_IDs,
  QUARTERS_NAME,
} from "../../../components/helper/enum";
import {
  Targets,
  TargetDetails,
  Plan,
  Parameters,
  Relationship,
  RelationshipDetails,
  Employee,
  BIUnit,
} from "../../../models";
import { DataStore, SortDirection } from "aws-amplify";
import { ObjectToArray } from "../../../components/helper/commonfunctions";
import { getActualValue } from "./getActualValue";
import {
  calculateConsolidatedScore,
  calculateWeightageAchievement,
  checkIfNegativeValue,
} from "../../../components/helper/dashboardCalCulations";
import { getTargetBasedOnParameterType, getTargetBasedOnParameterTypeForEvalPopup } from "../../../components/common/getTargetBasedOnParameterType";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../components/helper/graphQLFunctions';

export default function EvaluationEmployeePopUp(props) {


  const [selectedEmployeeTarget, setSelectedEmployeeTarget] = useState([]);
  const [isPageLoaded, setPageLoaded] = useState(false);

  const showParameterWeightage = (rowData) => {
    if (rowData.parameter_weightage !== "") {
      return (
        <React.Fragment>
          <label>{rowData.parameter_weightage}</label>
        </React.Fragment>
      );
    }
  };

  const showAchiievementWeightPercentage = (rowData) => {
    let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
    let targetValue = rowData.target_value;

    let achievementPercentatage = 0;
    if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
      achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
    }
    else {
      achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
    }

    if (achievementPercentatage > 200) {
      achievementPercentatage = 200;
    } else if (achievementPercentatage < 50) {
      achievementPercentatage = 0;
    }

    let weightPercentage = (rowData.parameter_weightage * achievementPercentatage) / 100;

    if (!weightPercentage) {
      weightPercentage = 0;
    }
    //parseFloat(NWCDaysValue).toFixed(2)
    return (
      <React.Fragment>
        <label>
          {/* {isFinite(rowData.actualAch) ? rowData.actualAch : "0%"} */}
          {parseFloat(weightPercentage).toFixed(2)}%
        </label>
      </React.Fragment>
    );
  }

  const showPlanActual_valuePercentage = (rowData) => {

    let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
    let targetValue = rowData.target_value;

    let achievementPercentatage = 0;

    if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
      achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
    }
    else {
      achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
    }

    if (rowData.parameter_id === PARAMETER_IDs["Overdue Receivable"]) {
      if (achievementPercentatage <= 0) {
        achievementPercentatage = 120
      } else if (achievementPercentatage <= 5) {
        achievementPercentatage = 100
      } else if (achievementPercentatage > 5 && achievementPercentatage < 10) {
        achievementPercentatage = 80
      } else {
        achievementPercentatage = 0
      }

    }
    else {
      if (achievementPercentatage > 200) {
        achievementPercentatage = 200;
      } else if (achievementPercentatage < 50) {
        achievementPercentatage = 0;
      }
    }


    if (!achievementPercentatage) {
      achievementPercentatage = 0;
    }

    return (
      <React.Fragment>
        <label>
          {/* {isFinite(rowData.actualAch) ? rowData.actualAch : "0%"} */}
          {/* {achievementPercentatage}% */}
          {parseFloat(Math.round(achievementPercentatage))}%
        </label>
      </React.Fragment>
    );
  };

  const showTarget = (rowData) => {
    if (rowData.target_value !== "") {
      return (
        <React.Fragment>
          {rowData.parameter_type === PARAMETERS_TYPES_NAMES.A ? (
            <label>
              {rowData.parameter_type}
              {commaSeparated(
                parseFloat(Math.round(rowData.target_value)),
              )}{" "}
            </label>
          ) : rowData.parameter_type === PARAMETERS_TYPES_NAMES.P ? (
            <label>
              {parseFloat(Math.round(rowData.target_value))}
              {rowData.parameter_type}
            </label>
          ) : (
            <label>
              {parseFloat(Math.round(rowData.target_value))}{" "}
              {rowData.parameter_type}
            </label>
          )}
        </React.Fragment>
      );
    }
  };

  const showActualTarget = (rowData) => {
    if (rowData.actualPerformance !== "") {
      return (
        <React.Fragment>
          {rowData.parameter_type === PARAMETERS_TYPES_NAMES.A ? (
            <label>
              {rowData.parameter_type}
              {commaSeparated(parseFloat(rowData.actualPerformance))}{" "}
            </label>
          ) : rowData.parameter_type === PARAMETERS_TYPES_NAMES.P ? (
            <label>
              {rowData.actualPerformance}
              {rowData.parameter_type}
            </label>
          ) : (
            <label>
              {rowData.actualPerformance} {rowData.parameter_type}
            </label>
          )}
        </React.Fragment>
      );
    }
  };

  useEffect(() => {
    bindData();
  }, [props.selectedEmployeeId, props.financialYearId]);

  const bindData = async () => {

    var selectedEmpTarget = await graphQLGetAllData('listTargets', [{ employee_id: { eq: props.selectedEmployeeId }, financial_year_id: { eq: props.financialYearId } }])


    /*  var selectedEmpTarget = await DataStore.query(Targets, (c) =>
     c.and((c) => [
       c.employee_id.eq(props.selectedEmployeeId),
       c.financial_year_id.eq(props.financialYearId),
     ]),
   ); */

    // //Target Details

    /* var currentTargetDetails = await DataStore.query(TargetDetails, (c) =>
      c.and((c) => [
        c.target_id.eq(selectedEmpTarget[1].id),
        c.parameter_id.notContains(PARAMETER_IDs.DIO),
        c.parameter_id.notContains(PARAMETER_IDs.DPO),
        c.parameter_id.notContains(PARAMETER_IDs.DVRO),
        c.parameter_id.notContains(PARAMETER_IDs.DSO),
      ]),
    ); */

    /* var currentTargetDetails = await DataStore.query(TargetDetails, (c) =>
      c.and((c) => [
        c.target_id.eq(selectedEmpTarget[1].id),
        c.parameter_id.notContains(PARAMETER_IDs.DIO),
        c.parameter_id.notContains(PARAMETER_IDs.DPO),
        c.parameter_id.notContains(PARAMETER_IDs.DVRO),
        c.parameter_id.notContains(PARAMETER_IDs.DSO),
      ]), {
      sort: s => s.createdAt(SortDirection.DESCENDING)
    }
    ); */
    var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: selectedEmpTarget[0].id }, parameter_id: { notContains: PARAMETER_IDs.DIO }, parameter_id: { notContains: PARAMETER_IDs.DPO }, parameter_id: { notContains: PARAMETER_IDs.DVRO }, parameter_id: { notContains: PARAMETER_IDs.DSO } }])
    currentTargetDetails = currentTargetDetails.sort((b, a) => a.createdAt.localeCompare(b.createdAt));

    //get parameters weightage

    var relationshipResponse = await graphQLGetAllData('listRelationships', [{ role_id: { eq: selectedEmpTarget[0].role_id }, financial_year_id: { eq: props.financialYearId } }])
    relationshipResponse = relationshipResponse.sort((b, a) => a.createdAt.localeCompare(b.createdAt));
    relationshipResponse = relationshipResponse.reduce((prev, current) => {
      return prev._lastChangedAt > current._lastChangedAt ? prev : current;
    }
    );
    /* 
    var relationshipResponse = await DataStore.query(Relationship, (c) =>
      c.and((c) => [
        c.role_id.eq(selectedEmpTarget[0].role_id),
        c.financial_year_id.eq(props.financialYearId),
      ]),
    ).then(response => response.reduce((prev, current) => {
      return prev._lastChangedAt > current._lastChangedAt ? prev : current;
    }, {})); */

    var relationshipDetailResponses = [];
    if (relationshipResponse) {

      relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipResponse.id } }])

      /* relationshipDetailResponses = await DataStore.query(
        RelationshipDetails,
        (c) => c.relationship_id.eq(relationshipResponse.id),
      ); */
    }

    let newResponse = [];
    // currentTargetDetails.forEach(async (currentItem, i) => {

    for (let i = 0; i < currentTargetDetails.length; i++) {
      //Status
      let status = "";
      if (currentTargetDetails[i].is_accepted_by_employee && !currentTargetDetails[i].is_approve) {
        status = "Awaiting approval";
      } else if (currentTargetDetails[i].isSendForRevisionByReportingManage) {
        status = "Revision";
      } else if (currentTargetDetails[i].is_approve) {
        status = "Approved";
      } else if (currentTargetDetails[i].is_revision_by_employee) {
        status = "Requested for Revision";
      } else {
        status = "Open";
      }

      //get parameters details
      // const parameterResponses = await graphQLGetAllData('listParameters', [{ id: { eq: currentTargetDetails[i].parameter_id } }])

      const parameterResponses = await graphQLFindRecordById('getParameters', currentTargetDetails[i].parameter_id)

      /*  var parameterResponses = await DataStore.query(Parameters, (c) =>
         c.id.eq(currentItem.parameter_id),
       ); */

      var getWeightage = relationshipDetailResponses.filter(
        (e) => e.parameter_id === currentTargetDetails[i].parameter_id,
      );

      var weightageForWtAch =
        getWeightage.length > 0 ? getWeightage[0].weightage : 1;

      var parameterBaseType =
        parameterResponses.type === PARAMETERS_TYPES.Days
          ? PARAMETERS_TYPES_NAMES.D
          : parameterResponses.type === PARAMETERS_TYPES.Amount
            ? PARAMETERS_TYPES_NAMES.A
            : parameterResponses.type === PARAMETERS_TYPES.Number
              ? PARAMETERS_TYPES_NAMES.N
              : PARAMETERS_TYPES_NAMES.P;


      var targetValue = 0;
      var actualPerformance = 0;
      var actualAch = 0;
      var wtAch = 0;

      var planActual = getActualValue(
        currentTargetDetails[i],
        props.quarter,
        parameterBaseType,
      );

      // OLD CODE converting negative value to positive
      // Math.abs(Math.round(planActual))

      if (planActual) {
        if (parameterBaseType === PARAMETERS_TYPES_NAMES.D) {
          actualPerformance = parseFloat(planActual).toFixed(2);
        } else {
          actualPerformance =
            parseInt(planActual) < 0
              ? Math.round(planActual)
              : Math.round(planActual);
        }

      } else {
        actualPerformance = 0;
      }

      var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentTargetDetails[i].id } }])
      // console.log('currentTargetDetails[i].id',currentTargetDetails[i].id);

      // console.log('getPlanDetails.length',getPlanDetails.length);

      /* var getPlanDetails = await DataStore.query(Plan, (c) =>
        c.target_detail_id.eq(currentItem.id),
      ); */

      // var getTargetOnParameterType = getTargetBasedOnParameterType(
      //   getPlanDetails,
      //   props.quarter,
      //   parameterBaseType,
      //   currentTargetDetails[i]
      // );

       var getTargetOnParameterType = getTargetBasedOnParameterTypeForEvalPopup(
        getPlanDetails,
        props.quarter,
        parameterBaseType,
        currentTargetDetails[i]
      );

      // console.log(' currentTargetDetails[i]', currentTargetDetails[i]);
      // console.log('getTargetOnParameterType',getTargetOnParameterType);

      actualAch = await calculateConsolidatedScore(
        planActual,
        getTargetOnParameterType,
      );
      wtAch = await calculateWeightageAchievement(weightageForWtAch, actualAch);

      //Check iF Negative
      actualAch = await checkIfNegativeValue(actualAch);
      wtAch = await checkIfNegativeValue(wtAch);

      // if (getWeightage.length > 0) {
      if (1) {
        if (parameterResponses) {
          let data = {
            target_detail_id: currentTargetDetails[i].id,
            financial_year_id: props.financialYearId,
            parameter_id: currentTargetDetails[i].parameter_id,
            parameter_name: parameterResponses.name,
            parameter_type:
              parameterResponses.type === "A"
                ? "$"
                : parameterResponses.type === "D"
                  ? "Days"
                  : parameterResponses.type === "P"
                    ? "%"
                    : parameterResponses.type === "N"
                      ? "Number"
                      : "",
            target_value: getTargetOnParameterType
              ? getTargetOnParameterType
              : 0,
            actualPerformance:
              actualPerformance !== undefined ? actualPerformance : 0,
            // actualPerformance:
            //   actualPerformance !== undefined ? commaSeparated(parseFloat(Math.round(actualPerformance))) : 0,
            actualPerformanceValue: actualPerformance,
            actualAch: actualAch ? actualAch + "%" : "0%",
            wtAch: isFinite(wtAch) ? wtAch + "%" : "0%",
            isMarkAsComplete: currentTargetDetails[i].isMarkAsComplete,
            is_accepted_by_employee: currentTargetDetails[i].is_accepted_by_employee,
            is_approve: currentTargetDetails[i].is_approve,
            is_revision_by_employee: currentTargetDetails[i].is_revision_by_employee,
            isSendForRevisionByReportingManage:
              currentTargetDetails[i].isSendForRevisionByReportingManage,
            parameter_weightage:
              getWeightage.length > 0
                ? parseInt(getWeightage[0].weightage)
                : "-",
            isPlanMandatory: getWeightage.length > 0 ? 1 : 0,
            status: status,
            q1_target_value: currentTargetDetails[i].q1_target_value,
            q2_target_value: currentTargetDetails[i].q2_target_value,
            q3_target_value: currentTargetDetails[i].q3_target_value,
            q4_target_value: currentTargetDetails[i].q4_target_value,
            q1_actual_value: getActualValue(
              currentTargetDetails[i],
              "Quarter 1",
              parameterBaseType,
            ),
            q2_actual_value: getActualValue(
              currentTargetDetails[i],
              "Quarter 2",
              parameterBaseType,
            ),
            q3_actual_value: getActualValue(
              currentTargetDetails[i],
              "Quarter 3",
              parameterBaseType,
            ),
            q4_actual_value: getActualValue(
              currentTargetDetails[i],
              "Quarter 4",
              parameterBaseType,
            ),
          };

          // if (getWeightage.length > 0)
          newResponse.push(data);
        }
      }

      if (i === currentTargetDetails.length - 1) {
        let sortArray = newResponse.sort((a, b) =>
          a.parameter_name > b.parameter_name ? 1 : -1,
        );

        let dollerValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs["Gross Margin $"])
        let revenueValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs.Revenue)
        let gmPercentageValue = revenueValue.actualPerformanceValue ? (parseInt(dollerValue ? dollerValue?.actualPerformanceValue :0) / parseInt(revenueValue.actualPerformanceValue)) * 100 : 0;
        let filteredData = sortArray.filter(e => e.isPlanMandatory)

        let objIndex = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin %"]));

        if (objIndex >= 0)
          filteredData[objIndex].actualPerformance = gmPercentageValue.toFixed(2) * -1;

        let objIndexforGM$ = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin $"]));

        if (objIndexforGM$ >= 0) {
          filteredData[objIndexforGM$].actualPerformance = filteredData[objIndexforGM$].actualPerformance * -1;
        }

        let checkNWCIsPrsesnt = filteredData.find(c => c.parameter_id === PARAMETER_IDs["NWC DAYS"])
        if (checkNWCIsPrsesnt) {
          filteredData = filteredData.filter(c => !(c.parameter_id === PARAMETER_IDs.DIO || c.parameter_id === PARAMETER_IDs.DPO || c.parameter_id === PARAMETER_IDs.DSO || c.parameter_id === PARAMETER_IDs.DVRO))
        }else{
          filteredData = filteredData.filter( c=> 
           !(c.parameter_id === PARAMETER_IDs.DIO && Number(c.target_value) === 0 ||
            c.parameter_id === PARAMETER_IDs.DPO && Number(c.target_value) === 0 ||
            c.parameter_id === PARAMETER_IDs.DSO && Number(c.target_value) === 0 ||
            c.parameter_id === PARAMETER_IDs.DVRO && Number(c.target_value) === 0 )
            )
        }

        setSelectedEmployeeTarget(filteredData);
        // console.log('filteredData',filteredData);
        setPageLoaded(true);
      }
    }
  };

  return (
    <div>
      <div
        style={{ width: "40vw", animation: "popupDiv-slide-in 0.5s forwards" }}
        id="createRecordCont"
        className="sidenav dashboard-main-wrapper"
      >
        <div className="relative">
          <img src={popupHeaderImg} alt="" className="w-full" />
          <div className="absolute right-0 left-0 top-0 w-full text-white">
            <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
              <div className="text-md">Employee Details</div>
              <div>
                <Link
                  onClick={() => props.closeEmpEvaluation()}
                  className="closebtn text-2xl leading-none removeClass"
                >
                  &times;
                </Link>
              </div>
            </div>
            <div className="px-6 mt-8 flex items-center ">
              <Avatar
                style={{ width: 40, height: 40 }}
                src={props.selectedEmployeeProfilePic}
              />
              <div className="text-sm ml-3">{props.selectedEmployeeName}</div>
            </div>
          </div>
        </div>

        <div className="p-5">
          <div className="height-custtom">
            <div className="bg-[#EEF8F4] p-4">
              <div className="grid grid-cols-4 lg:grid-cols-4 2xl:grid-cols-4 gap-2">
                <div className="text-[#98A2B3] text-[12px] mb-2">
                  Employee ID
                </div>
                <div className="text-[#101828] text-[14px] mb-2">
                  {props.selectedEmployeeCode}
                </div>

                <div className="text-[#98A2B3] text-[12px] mb-2">
                  Employee Name
                </div>
                <div className="text-[#101828] text-[14px] mb-2">
                  {props.selectedEmployeeName}
                </div>

                <div className="text-[#98A2B3] text-[12px] mb-2">
                  Designation
                </div>
                <div className="text-[#101828] text-[14px] mb-2">
                  {props.selectedEmployeeRole}
                </div>

                <div className="text-[#98A2B3] text-[12px] mb-2">
                  Reporting Manager
                </div>
                <div className="text-[#101828] text-[14px] mb-2">
                  {props.selectedParentEmployeeName
                    ? props.selectedParentEmployeeName
                    : "-"}
                </div>
              </div>
              {
                !isPageLoaded &&
                <div className='text-center py-8' ><ProgressSpinner /></div>
              }
              {
                isPageLoaded ?
                  <>
                    <div className="grid grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 gap-5 gap-y-8 mt-5">
                      {selectedEmployeeTarget.length > 0
                        ? selectedEmployeeTarget.map((item, i) => {
                          return (
                            <div
                              key={i.toString()}
                              className="bg-[#fff] p-4  rounded-lg text-center"
                            >
                              <h3 className="text-[#101828] text-[22px] font-semibold">
                                {item.actualPerformance !== ""
                                  ? item.parameter_type === PARAMETERS_TYPES_NAMES.A
                                    ? item.parameter_type +
                                    "" +
                                    commaSeparated(item.actualPerformance)
                                    : item.actualPerformance +
                                    " " +
                                    item.parameter_type
                                  : "-"}
                              </h3>
                              <p>{item.parameter_name} </p>
                            </div>
                          );
                        })
                        : ""}
                    </div>

                    <div className="mt-5 mb-10">
                      <div className="mt-3 mb-3">
                        <h2 className="font-medium">
                          Performance Summary: FY {props.financialYear},{" "}
                          {props.quarter}{" "}
                        </h2>
                      </div>
                      <DataTable
                        value={selectedEmployeeTarget}
                        showGridlines
                        editMode="cell"
                        editable="true"
                        responsiveLayout="scroll"
                      >
                        <Column
                          field="parameter_name"
                          header="Parameters"
                          style={{ width: "8%" }}
                        ></Column>
                        <Column
                          field="parameter_weightage"
                          header="Weight%"
                          body={showParameterWeightage}
                          style={{ width: "8%" }}
                        ></Column>
                        <Column
                          field="target_value"
                          header="Target"
                          body={showTarget}
                          style={{ width: "12%" }}
                        ></Column>
                        <Column
                          field="actualPerformance"
                          header="Actual"
                          body={showActualTarget}
                          style={{ width: "5%" }}
                        ></Column>
                        <Column
                          field="actualPerformance"
                          header="Achievement"
                          body={showPlanActual_valuePercentage}
                          style={{ width: "5%" }}
                        ></Column>
                        <Column
                          field="wtAch"
                          header="Wt_Achievement"
                          body={showAchiievementWeightPercentage}
                          style={{ width: "8%" }}
                        ></Column>
                      </DataTable>

                    </div>
                  </>
                  : ''
              }

            </div>
          </div>
        </div>

        <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
          <div className="flex justify-end gap-3">
            <div>
              <Link
                onClick={() => {
                  props.closeEmpEvaluation();
                }}
                className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white"
              >
                Close
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
