
const DIO = process.env.REACT_APP_DIO;
const DSO = process.env.REACT_APP_DSO;
const DVRO = process.env.REACT_APP_DVRO;
const DPO = process.env.REACT_APP_DPO;
const NWCDAYS = process.env.REACT_APP_NWCDAYS;
const Revenue = process.env.REACT_APP_Revenue;
const GrossMarginPercentage = process.env.REACT_APP_GrossMarginPercentage;
const GrossMarginDoller = process.env.REACT_APP_GM_DOLLER;
const OverdueReceivable = process.env.REACT_APP_OVERDUE_RECEIVABLE;

export const USER_TYPES = {
    HR: "H",
    Employee: "E",
}

export const USER_TYPES_NAMES = {
    H: "HR",
    E: "Employee",
}

export const PARAMETERS_TYPES = {
    Days: "D",
    Percentage: "P",
    Amount: "A",
    Number: "N",
}

export const PARAMETERS_TYPES_NAMES = {
    D: "Days",
    P: "%",
    A: "$",
    N: "Number",
}

export const CHANGE_REQUEST_TYPES = {
    PlanningPeriod: "PP",
    OverrideActual: "OA",
}

export const CHANGE_REQUEST_TYPES_NAMES = {
    PP: "Planning Period",
    OA: "Override Actual",
}

export const CHANGE_REQUEST_STATUS = {
    REPORTEE: "Open",
    REPORTING_MANAGER: "Approved",
    HR: "Accepted",
}

export const HR_CHANGE_REQUEST_STATUS = {
    Open: "Open",
    Approved: "Approved",
    Declined: "Declined",
}

export const NOTIFICATIONS_TYPES = {
    AssignTarget: "Assign_Target",
    ChangeRequestPP: "PP",
    ChangeRequestOA: "OA",
    ChangeRequestApproveFromRP: "CHANGE_REQUEST_APPROVE_FROM_RP",
    ChangeRequestApproveFromHR: "CHANGE_REQUEST_APPROVE_FROM_HR",
    SubmitPlan: "Submit_Plan",
    ApproveTarget: "Approve_Target",
    SendForRevision: "Send_For_Revision",
    InitiatePlan: "Initiate_Plan",
    InitiateEvaluationPlan: "Initiate_Evaluation_Plan",
    //SendForRevision : "Send For Revision",
}

export const EMAIL_SUBJECTS = {
    AssignTarget: "Redington-Target Assigned",
    ChangeRequest: "Redington-Change Request",
    ChangeRequestApprove: "Redington-Change Request Accepted",
    SubmitPlan: "Redington-Plan Submitted",
    ApproveTarget: "Redington-Approve Target",
    SendForRevision: "Redington-Send for revision",
    InitiatePlan: "Redington-Initiate Plan",
    InitiateEvaluationPlan: "Redington-Initiate Evaluation Plan",
}

export const QUARTERS_NUMBERS = {
    "Quarter 1": 1,
    "Quarter 2": 2,
    "Quarter 3": 3,
    "Quarter 4": 4
}

export const QUARTERS_NAME = {
    1: "Quarter 1",
    2: "Quarter 2",
    3: "Quarter 3",
    4: "Quarter 4"
}

export const EVALUATION_STATUS = {
    1: "Open",
    2: "Upcoming",
    3: "Completed",
}

export const PARAMETER_IDs = {
    "Revenue": Revenue,
    "NWC DAYS" : NWCDAYS,
    "Gross Margin %" : GrossMarginPercentage,
    "Gross Margin $" : GrossMarginDoller,
    "DIO" : DIO,
    "DPO" : DPO,
    "DVRO" : DVRO,
    "DSO" : DSO,
    "Overdue Receivable": OverdueReceivable
}

export const Google_API_KEY = 'AIzaSyDmHcZjsvYmfI5cJ6ucGsdKx1NmLu2DcJQ'
