import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import 'react-phone-input-2/lib/style.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import uniqid from 'uniqid';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import popupHeader from '../../assets/images/popup_header_img.png';
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { ConvertResponseForSelectWithRefCode } from '../../components/helper/commonfunctions';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";
import { Dialog } from 'primereact/dialog';
import { checkEmployeeTeamType } from './helper';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../src/components/helper/graphQLFunctions'

export default function EmployeeParametersUpdates() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const navigateTo = useNavigate()
    const location = useLocation();


    const [parameterData, setParameterData] = useState([]);
    const [isPageLoaded, setPageLoaded] = useState(false);
    const [hideSelection, setHideSelection] = useState(false);

    const [companyList, SetCompanyList] = useState([]);
    const [profitCenterList, SetProfitCenterList] = useState([]);
    const [plantStorageLocationList, SetPlantStorageLocationList] = useState([]);
    const [divisionList, SetDivisionList] = useState([]);
    const [customerList, SetCustomerList] = useState([]);
    const [customerCategoryList, SetCustomerCategoryList] = useState([]);
    const [countryList, SetCountryList] = useState([]);
    const [regionList, SetRegionList] = useState([]);
    const [materialList, SetMaterialList] = useState([]);
    const [isShowImportPopup, setIsShowImportPopup] = useState("false");
    const [parametersData, setParametersData] = useState([]);
    const [transformParametersData, setTransformParametersData] = useStateWithCallbackLazy([]);
    const [parametersDropDownArray, SetParametersDropDownArray] = useState([]);
    const [buCode, setBUCode] = useState("");
    const [buParamCodeId, setBUParamCodeId] = useState("");
    const [companyCode, setCompanyCode] = useState("");
    const [companyParamCodeId, setCompanyParamCodeId] = useState("");
    const [region, setRegion] = useState("");
    const [paramRegionId, setParamRegionId] = useState("");
    const [country, setCountry] = useState("");
    const [paramCountryId, setParamCountryId] = useState("");
    const [profitCenter, setProfitCenter] = useState("");
    const [paramProfitCenterId, setParamProfitCenterId] = useState("");
    const [plantStorage, setPlantStorage] = useState("");
    const [paramPlantStorageId, setParamPlantStorageId] = useState("");
    const [division, setDivision] = useState("");
    const [paramDivisionId, setParamDivisionId] = useState("");
    const [customerGroup, setCustomerGroup] = useState("");
    const [paramCustomerGroupId, setParamCustomerGroupId] = useState("");
    const [customer, setCustomer] = useState("");
    const [paramCustomerId, setParamCustomerId] = useState("");
    const [materialNo, setMaterialNo] = useState("");
    const [paramMaterialNo, setParamMaterialNo] = useState("");
    const [selectCountryList, SetSelectCountryList] = useState([]);
    const [buList, SetBuList] = useState([]);
    const [sbuId, setSbuId] = useState("");
    const [sbuName, setSbuName] = useState("");
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [allBiUnits, setAllBiUnits] = useState([]);
    const [allBrands, setAllBrands] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [allProficCenters, setAllProfitCenters] = useState([]);
    const [allStorageLocs, setAllStorageLocs] = useState([]);
    const [allPgs, setAllPgs] = useState([]);
    const [allRegions, setAllRegions] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [allCustCatgories, setAllCustCategories] = useState([]);
    const [allDivisions, setAllDivisions] = useState([]);
    const [allGtms, setAllGtms] = useState([]);
    const [allSubGtms, setAllSubGtms] = useState([]);
    const [allProductGroups, setAllProductGroups] = useState([]);

    // ----------- SET ALL MASTER DATA ------------

    const [allBU, setAllBU] = useState([]);
    const [allCompany, setAllCompany] = useState([]);
    const [allRegion, setAllRegion] = useState([]);
    const [allCountry, setAllCountry] = useState([]);
    const [allProfitCenter, setAllProfitCenter] = useState([]);
    const [allPlantStorage, setAllPlantStorage] = useState([]);
    const [allDivision, setAllDivision] = useState([]);
    const [allCustomerGroup, setAllCustomerGroup] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);
    const [allMaterials, setAllMaterials] = useState([]);
    const [allGtm, setAllGtm] = useState([]);
    const [allSubGtm, setAllSubGtm] = useState([]);
    const [allSBUs, setAllSBUs] = useState([]);

    // --------------------------------------------

    const [selectedParameters, setSelectedParameters] = useState([]);

    const [isDeleteParameter, setDeleteParameter] = useState(false);

    const loggedEmployeeTeamType = reactLocalStorage.get('loggedEmployeeTeamType');

    //load Countries
    async function onClickRegion(e) {
        //Country
        // var countryResponses = await DataStore.query(Country, (c) => c.region_id.eq(e.value), {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        //check Empty
        // if (countryResponses.length > 0) {
        //     var myArray = []; myArray = ConvertResponseForSelectWithRefCode(countryResponses); SetCountryList(myArray);
        // } else { SetCountryList([]); }
    }

    //load Customers 
    async function onClickCustomerGroup(e) {

        // var customerResponses = await DataStore.query(Customer, (c) => c.customer_category_id.eq(e.value), {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var customerResponses = graphQLGetAllData('listCustomers', [{ customer_category_id: { eq: e.value } }])


        //check Empty
        if (customerResponses.length > 0) {
            var myArray = []; myArray = ConvertResponseForSelectWithRefCode(customerResponses); SetCustomerList(myArray);
        } else {
            SetCustomerList([]);
        }
    }

    //onClick Select Doc
    async function fileHandler(event) {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        if (fileObj.size === 0) {
            toast.error('Do not select empty file.');
        } else {
            ExcelRenderer(fileObj, (err, resp) => {
                if (err) {
                    console.log(err);
                }
                else {
                    reactLocalStorage.set('importParametersTotalLength', resp.rows.length - 1);
                    var parameterData = resp.rows
                    var newParameterArray = parameterData.slice(1);
                    setParametersData(newParameterArray)
                    let paramArray = resp.rows[0]
                    let finalData = [];
                    for (let i = 0; i < paramArray.length; i++) {

                        finalData.push({ label: paramArray[i], value: i })
                        if (i === paramArray.length - 1) {
                            let sortArray = finalData.sort((a, b) => (a.label > b.label ? 1 : -1))
                            SetParametersDropDownArray(sortArray)
                            setIsShowImportPopup(true)
                        }
                    }
                }
            });
        }
    }

    //onClick Preview
    async function onClickParameterPreview() {
        let paramData = parametersData
        let finalResponseData = [];
        for (let i = 0; i < paramData.length; i++) {
            let buId = ""; let companyCodeId = ""; let regionId = ""; let countryId = ""; let profitCenterId = ""; let plantStorageId = "";
            let divisionId = ""; let customerGroupId = ""; let customerId = ""; let materialNoId = "";

            let buText = ""; let companyCodeText = ""; let regionText = ""; let countryText = ""; let profitCenterText = ""; let plantStorageText = "";
            let divisionText = ""; let customerGroupText = ""; let customerText = ""; let materialNoText = "";

            let buArray = []; let companyCodeArray = []; let regionArray = []; let countryArray = []; let profitCenterArray = []; let plantStorageArray = [];
            let divisionArray = []; let customerGroupArray = []; let customerArray = []; let materialNoArray = [];

            var strike = 0;
            let product_group_REF_CODE = '';
            let brand_REF_CODE = "";
            let product_group_Name = '';
            let brand_REF_Name = "";

            if (buParamCodeId !== "") {
                if (buParamCodeId !== "" && buCode) {
                    if (paramData[i][buParamCodeId] !== undefined) {

                        var getBuId = getBUId(paramData[i][buParamCodeId])
                        if (getBuId !== "") {
                            buId = getBuId; buArray.push(getBuId)
                            // buText = paramData[i][buParamCodeId];

                            buText = getBUName(paramData[i][buParamCodeId])

                            if (companyParamCodeId !== "" && companyCode) {
                                if (paramData[i][companyParamCodeId] !== undefined) {
                                    var getCompanyId = getData(paramData[i][companyParamCodeId], allCompany)
                                    if (getCompanyId) { companyCodeId = getCompanyId.id; companyCodeText = getName(paramData[i][companyParamCodeId], allCompany); companyCodeArray.push(getCompanyId.id); } else { companyCodeId = ""; companyCodeText = "" }
                                }

                            }

                            //Took region on the basis of employee SBU_ID
                            if (paramRegionId !== "" && region) {
                                if (paramData[i][paramRegionId] !== undefined) {
                                    var getDataFromTable = getRegionId(sbuId, paramData[i][paramRegionId])
                                    if (getDataFromTable) { regionId = getDataFromTable; regionText = getRegionName(sbuId, paramData[i][paramRegionId]); regionArray.push(getDataFromTable); }
                                    else { regionId = ""; regionText = "" }
                                }
                            }

                            //Took country on the basis of employee regionId
                            if (paramCountryId !== "" && country) {
                                // if (regionId) { 
                                countryId = getCountryId(paramData[i][paramCountryId])
                                //  }
                                if (countryId) { countryId = countryId; countryText = getCountryName(paramData[i][paramCountryId]); countryArray.push(countryId); } else { countryId = ""; countryText = ""; }
                            }

                            if (paramProfitCenterId !== "" && profitCenter) {
                                if (paramData[i][paramProfitCenterId]) {
                                    // var profitCenterResponses = await DataStore.query(ProfitCenter, (c) => c.name.contains(paramData[i][paramProfitCenterId]));

                                    var profitCenterResponses = await graphQLGetAllData('listProfitCenters', [{ name: { contains: paramData[i][paramProfitCenterId] } }])
                                    if (profitCenterResponses.length > 0) {
                                        profitCenterId = profitCenterResponses[0].id; profitCenterText = profitCenterResponses[0].name + "(" + profitCenterResponses[0].REF_CODE + ")"; profitCenterArray.push(profitCenterResponses[0].id);
                                        // let currentResponse = await DataStore.query(ProfitCenter, profitCenterId);
                                        let currentResponse = await graphQLFindRecordById('getProfitCenter', profitCenterId)
                                        if (currentResponse) {
                                            var product_group_id = currentResponse.product_group_id;
                                            if (product_group_id !== "") {
                                                // currentResponse = await DataStore.query(ProductGroup, product_group_id);
                                                currentResponse = await graphQLFindRecordById('getProductGroup', product_group_id)
                                                if (currentResponse) {
                                                    product_group_REF_CODE = currentResponse.REF_CODE;
                                                    product_group_Name = currentResponse.name + "(" + currentResponse.REF_CODE + ")";
                                                    let brand_id = currentResponse.brand_id;
                                                    if (brand_id !== "") {
                                                        // currentResponse = await DataStore.query(Brand, brand_id);
                                                        currentResponse = await graphQLFindRecordById('getBrand', brand_id)
                                                        brand_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";
                                                        brand_REF_Name = currentResponse ? currentResponse.name + "(" + currentResponse.REF_CODE + ")" : "";
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        profitCenterId = "";
                                        profitCenterText = paramData[i][paramProfitCenterId];
                                        product_group_REF_CODE = "";
                                        product_group_Name = "";
                                        brand_REF_CODE = "";
                                        brand_REF_Name = "";
                                    }

                                }
                            }

                            if (paramPlantStorageId !== "" && plantStorage) {
                                if (paramData[i][paramPlantStorageId] !== undefined) {
                                    // var PlantStorageResponses = await DataStore.query(PlantStorageLocation, (c) => c.name.contains(paramData[i][paramPlantStorageId]));

                                    var PlantStorageResponses = graphQLGetAllData('listPlantStorageLocations', [{ name: { contains: paramData[i][paramPlantStorageId] } }])

                                    if (PlantStorageResponses.length > 0) { plantStorageId = PlantStorageResponses[0].id; plantStorageText = PlantStorageResponses[0].name + "(" + PlantStorageResponses[0].REF_CODE + ")"; plantStorageArray.push(PlantStorageResponses[0].id); } else { plantStorageId = ""; plantStorageText = ""; }
                                }
                            }

                            if (paramDivisionId !== "" && division) {
                                if (paramData[i][paramDivisionId] !== undefined) {
                                    var getDataFromTable = getData(paramData[i][paramDivisionId], allDivision)
                                    if (getDataFromTable) { divisionId = getDataFromTable.id; divisionText = getName(paramData[i][paramDivisionId], allDivision); divisionArray.push(getDataFromTable.id); } else { divisionId = ""; divisionText = ""; }
                                }
                            }

                            if (paramCustomerGroupId !== "" && customerGroup) {
                                if (paramData[i][paramCustomerGroupId] !== undefined) {
                                    var getDataFromTable = getData(paramData[i][paramCustomerGroupId], allCustomerGroup)
                                    if (getDataFromTable) { customerGroupId = getDataFromTable.id; customerGroupText = getName(paramData[i][paramCustomerGroupId], allCustomerGroup); customerGroupArray.push(getDataFromTable.id); } else { customerGroupId = ""; customerGroupText = ""; }
                                }
                            }

                            if (paramCustomerId !== "" && customer) {
                                if (paramData[i][paramCustomerId] !== undefined) {
                                    // var customerResponses = await DataStore.query(Customer, (c) => c.name.contains(paramData[i][paramCustomerId]));
                                    var customerResponses = graphQLGetAllData('listCustomers', [{ name: { contains: paramData[i][paramCustomerId] } }]);

                                    if (customerResponses.length > 0) { customerId = customerResponses[0].id; customerText = customerResponses[0].name + "(" + customerResponses[0].REF_CODE + ")"; customerArray.push(customerResponses[0].id); } else { customerId = ""; customerText = ""; }
                                }
                            }

                            if (paramMaterialNo !== "" && materialNo) {
                                if (paramData[i][paramMaterialNo] !== undefined) {
                                    var getDataFromTable = getData(paramData[i][paramMaterialNo], allMaterials)
                                    if (getDataFromTable) { materialNoId = getDataFromTable.id; materialNoText = getName(paramData[i][paramMaterialNo], allMaterials); materialNoArray.push(getDataFromTable.id); } else { materialNoId = ""; materialNoText = ""; }
                                }
                            }


                            // if (buId === "" && companyCodeId && regionId === "" && countryId === "" && profitCenterId === "" && plantStorageId === "" && divisionId === "" && customerGroupId === "" && customerId === "" && materialNoId === "") {
                            //     //  toast.error('No Record Match.');
                            // }
                            // else {
                            //check Duplicates
                            let _finalResponseData = [...finalResponseData];
                            let check_parameterData = _finalResponseData.every((e) => e.buId !== buId || e.companyId !== companyCodeId || e.regionId !== regionId || e.countryId !== countryId ||
                                e.profitCenterId !== profitCenterId || e.plantLocationId !== plantStorageId || e.divisionId !== divisionId || e.customerCategoryId !== customerGroupId || e.customerId !== customerId || e.materialId !== materialNoId || e.buText !== buText
                                || e.companyText !== companyCodeText || e.regionText !== regionText || e.countryText !== countryText || e.profitCenterText !== profitCenterText || e.plantlocationText !== plantStorageText || e.divisionText !== divisionText || e.customerCategoryText !== customerGroupText
                                || e.customerText !== customerText || e.materialText !== materialNoText
                            )

                            if (check_parameterData === true) { strike = 0 } else { strike = 1 }
                            finalResponseData.push({
                                "id": uniqid(),
                                "buId": buId, "companyId": companyCodeId, "regionId": regionId, "countryId": countryId, "profitCenterId": profitCenterId, "plantLocationId": plantStorageId,
                                "divisionId": divisionId, "customerCategoryId": customerGroupId, "customerId": customerId, "materialId": materialNoId,
                                "buText": buText, "companyText": companyCodeText, "regionText": regionText, "countryText": countryText, "profitCenterText": profitCenterText,
                                "plantlocationText": plantStorageText, "divisionText": divisionText, "customerCategoryText": customerGroupText,
                                "customerText": customerText, "materialText": materialNoText, "strike": strike, "product_group_REF_CODE": product_group_REF_CODE, "brand_REF_CODE": brand_REF_CODE,
                                "product_group_Name": product_group_Name, "brand_REF_Name": brand_REF_Name
                            });
                            // }
                        }

                        if (i === paramData.length - 1) {
                            //check empty
                            if (buArray.length === 0 && companyCodeArray.length === 0 && regionArray.length === 0 && countryArray.length === 0 && profitCenterArray.length === 0
                                && plantStorageArray.length === 0 && divisionArray.length === 0 && customerGroupArray.length === 0 && customerArray.length === 0 && materialNoArray.length === 0) {
                                toast.error('No Record Match.');
                                finalResponseData = [];
                                setIsShowImportPopup(false);
                                onClickClearParameter();
                            } else {
                                setTransformParametersData(finalResponseData, (finalResponseData) => { navigateTo("/employee/importparametersdetails", { state: finalResponseData }) });
                                setIsShowImportPopup(false);
                                onClickClearParameter();
                            }
                        }
                    }
                }
            } else {
                toast.error('BU should not be empty field.');
                return false;
            }
        }
    }

    //// ------------------------------------------------------------ MASTER DATA  **** START ***** --------------------------------------------------------------

    //*get BuId________
    const getBUId = (name) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.label.toLowerCase().trim().includes(name.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.value; } else { return ""; }
    }

    const getBUName = (name) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.label.toLowerCase().trim().includes(name.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.label } else { return ""; }
    }

    const getData = (name, tableName) => {
        let _List = [...tableName];
        var returnRecord = _List.find(o => o.name.trim().includes(name.trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord; } else { return ""; }
    }

    const getName = (name, tableName) => {
        let _List = [...tableName];
        var returnRecord = _List.find(o => o.name.trim().includes(name.trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.label; } else { return ""; }
    }


    const getRegionId = (sbuId, regionId) => {
        let _regionList = [...allRegion];
        if (regionId) {
            var returnRecord = _regionList.find(o => (o.name.toLowerCase().trim() === regionId.toLowerCase().trim() && o.SBU_id === sbuId.trim()));
            if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
        }
    }

    const getRegionName = (sbuId, regionId) => {
        let _regionList = [...allRegion];
        if (regionId) {
            var returnRecord = _regionList.find(o => (o.name.toLowerCase().trim() === regionId.toLowerCase().trim() && o.SBU_id === sbuId.trim()));
            if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.name + "(" + returnRecord.REF_CODE + ")"; } else { return ""; }
        }
    }

    const getCountryId = (name) => {
        let _countryList = [...allCountry];
        var returnRecord = _countryList.find(o => (o.name.toLowerCase().trim() === name.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    const getCountryName = (name) => {
        let _countryList = [...allCountry];
        var returnRecord = _countryList.find(o => (o.name.toLowerCase().trim() === name.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.name + "(" + returnRecord.REF_CODE + ")" } else { return ""; }
    }


    //// ------------------------------------------------------------ MASTER DATA  **** END ***** --------------------------------------------------------------


    //clear all parameters
    async function onClickClearParameter() {

        setBUParamCodeId(""); setCompanyCode(""); setCompanyParamCodeId(""); setParamRegionId(""); setParamCountryId("");
        setParamProfitCenterId(""); setParamPlantStorageId(""); setParamDivisionId(""); setParamCustomerGroupId(""); setParamCustomerId("");

        setBUCode(null); setCompanyCode(null); setRegion(null); setCountry(null); setProfitCenter(null); setPlantStorage(null);
        setDivision(null); setCustomerGroup(null); setCustomer(null); setMaterialNo(null);
    }

    async function BindList() {
        let parameterDetailsFinalData = location.state

        //get logged Employee Id
        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
        // var loggedEmployeeResponses = await DataStore.query(Employee, loggedEmployeeId)
        var loggedEmployeeResponses = await graphQLFindRecordById('getEmployee', loggedEmployeeId)
        if (loggedEmployeeResponses.param_sbu_id) {

            //var sbuResponses = await DataStore.query(SBU, loggedEmployeeResponses.param_sbu_id);
            // var sbuResponses = await API.graphql({ query: queries.getSBU, variables: { id: loggedEmployeeResponses.param_sbu_id } });
            // sbuResponses = sbuResponses.data.getSBU
            var sbuResponses = allSBUs.find(c => c.id === loggedEmployeeResponses.param_sbu_id);
            setSbuId(loggedEmployeeResponses.param_sbu_id)
            setSbuName(sbuResponses?.name)

            //get BU
            // var buResponses = await DataStore.query(BIUnit, (c) => c.sbu_id.eq(loggedEmployeeResponses.param_sbu_id), {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });

            var buResponses = allBiUnits.filter(c => c.sbu_id === loggedEmployeeResponses.param_sbu_id);
            buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));
            var myArray = []; myArray = ConvertResponseForSelectWithRefCode(buResponses); SetBuList(myArray); setAllBU(buResponses)

            // //Region
            // var regionResponses = await DataStore.query(Region, (c) => c.SBU_id.eq(loggedEmployeeResponses.param_sbu_id), { sort: s => s.name(SortDirection.ASCENDING) });

            // let regionResponses = await graphQLGetAllData('listRegions', [{ SBU_id: { eq: loggedEmployeeResponses.param_sbu_id } }])
            let regionResponses = allRegions.filter(c => c.SBU_id === loggedEmployeeResponses.param_sbu_id);

            regionResponses = regionResponses.sort((a, b) => a.name.localeCompare(b.name))
            myArray = []; myArray = ConvertResponseForSelectWithRefCode(regionResponses); SetRegionList(myArray); setAllRegion(regionResponses)



        } else {
            SetBuList([])
            SetRegionList([])
        }

        //Country
        // var countryResponses = await DataStore.query(Country, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        var countryResponses = await API.graphql(graphqlOperation(queries.listCountries), { filter: { _deleted: { attributeExists: false } } });

        countryResponses = countryResponses.data.listCountries.items;
        countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));

        myArray = []; myArray = ConvertResponseForSelectWithRefCode(countryResponses); SetCountryList(myArray);

        // let responses = await DataStore.query(Company, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        var companyResponses = await API.graphql(graphqlOperation(queries.listCompanies), { filter: { _deleted: { attributeExists: false } } });

        companyResponses = companyResponses.data.listCompanies;
        //companyResponses = companyResponses.sort((a, b) => a.name.localeCompare(b.name));

        myArray = [];
        if (companyResponses.length > 0) {
            myArray = ConvertResponseForSelectWithRefCode(companyResponses);
        }
        SetCompanyList(myArray);
        setAllCompany(companyResponses)

        let responses = [];
        // responses = await DataStore.query(ProfitCenter, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        responses = await API.graphql(graphqlOperation(queries.listProfitCenters), { filter: { _deleted: { attributeExists: false } } });

        responses = responses.data.listProfitCenters.items;
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));

        myArray = []; myArray = ConvertResponseForSelectWithRefCode(responses); SetProfitCenterList(myArray); setAllProfitCenter(responses)

        // responses = await DataStore.query(PlantStorageLocation, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        responses = await API.graphql(graphqlOperation(queries.listPlantStorageLocations), { filter: { _deleted: { attributeExists: false } } });

        responses = responses.data.listPlantStorageLocations.items;
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));


        myArray = []; myArray = ConvertResponseForSelectWithRefCode(responses); SetPlantStorageLocationList(myArray); setAllPlantStorage(responses)

        // responses = await DataStore.query(Division, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        responses = await API.graphql(graphqlOperation(queries.listDivisions), { filter: { _deleted: { attributeExists: false } } });

        responses = responses.data.listDivisions.items;
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));

        myArray = []; myArray = ConvertResponseForSelectWithRefCode(responses); SetDivisionList(myArray); setAllDivision(responses)

        // responses = await DataStore.query(CustomerCategory, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        responses = await API.graphql(graphqlOperation(queries.listCustomerCategories), { filter: { _deleted: { attributeExists: false } } });

        responses = responses.data.listCustomerCategories.items;
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));

        myArray = []; myArray = ConvertResponseForSelectWithRefCode(responses); SetCustomerCategoryList(myArray); setAllCustomerGroup(responses)

        // responses = await DataStore.query(Materials, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        responses = await API.graphql(graphqlOperation(queries.listMaterials), { filter: { _deleted: { attributeExists: false } } });

        responses = responses.data.listMaterials.items;
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));

        myArray = []; myArray = ConvertResponseForSelectWithRefCode(responses); SetMaterialList(myArray); setAllMaterials(responses)

        // responses = await DataStore.query(Country, Predicates.ALL); setAllCountry(responses)

        responses = await API.graphql(graphqlOperation(queries.listCountries), { filter: { _deleted: { attributeExists: false } } });

        responses = responses.data.listCountries.items;
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        setAllCountry(responses)

        // responses = await DataStore.query(Customer, Predicates.ALL); setAllCustomers(responses)
        responses = await API.graphql(graphqlOperation(queries.listCustomers), { filter: { _deleted: { attributeExists: false } } });

        responses = responses.data.listCustomers.items;
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        setAllCustomers(responses)

        // //GTM
        // responses = await DataStore.query(GTM, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        // responses = await API.graphql(graphqlOperation(queries.listGTMS), { filter: { _deleted: { attributeExists: false } } });
        // responses = responses.data.listGTMS.items;

        responses = allGtms;
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));

        let finalData = [];

        responses.forEach((item, i) => {
            let obj = finalData.find(o => o.name.trim() == item.name.trim());
            if (!obj) {
                finalData.push(item)
            }
        })

        responses = [...finalData];
        myArray = []; myArray = ConvertResponseForSelectWithRefCode(responses); setAllGtm(myArray)


        await BindParameters();
    }

    const addItem = () => {
        if (companyId === '' && profitCenterId === '' && plantLocationId === '' && divisionId === '' && customerCategoryId === '' && customerId === '' && regionId === '' && materialId === '' && countryId === '') {
            toast.error('Please select Parameters.');
            return;
        }

        let _parameterData = [...parameterData];
        let check_parameterData = _parameterData.every((e) => e.buId !== buId || e.companyId !== companyId || e.profitCenterId !== profitCenterId || e.plantLocationId !== plantLocationId ||
            e.divisionId !== divisionId || e.customerCategoryId !== customerCategoryId || e.customerId !== customerId || e.regionId !== regionId || e.countryId !== countryId || e.companyText !== companyText || e.profitCenterText !== profitCenterText
            || e.plantlocationText !== plantlocationText || e.divisionText !== divisionText || e.customerCategoryText !== customerCategoryText || e.customerText !== customerText || e.regionText !== regionText || e.materialText !== materialText || e.countryText !== countryText
        )
        if (check_parameterData === true) {
            _parameterData.push({
                "id": uniqid(),
                "buId": buId, "buText": buText, "companyId": companyId, "profitCenterId": profitCenterId, "plantLocationId": plantLocationId,
                "divisionId": divisionId, "customerCategoryId": customerCategoryId,
                "customerId": customerId, "regionId": regionId, "countryId": countryId, "materialId": materialId,
                "companyText": companyText, "profitCenterText": profitCenterText, "plantlocationText": plantlocationText,
                "divisionText": divisionText, "customerCategoryText": customerCategoryText,
                "customerText": customerText, "regionText": regionText, "countryText": countryText, "materialText": materialText,
                "product_group_REF_CODE": productGroup_Ref_Code, "brand_REF_CODE": brand_Ref_Code,
                "product_group_Name": productGroupText, "brand_REF_Name": brandText,
                "gtmId": gtmId, "gtmText": gtmText,
                "subGtmId": subGtmId, "subGtmText": subGtmText,
            });
            setParameterData(_parameterData);
            setPageLoaded(true);

        } else {
            toast.error('Duplicate Parameters.');
        }
    }

    const deleteBodyTemplate = (rowData) => {
        return <i onClick={(e) => removeItem(rowData)} style={{ cursor: 'pointer' }} className='icon delete_icon'></i>;
    }

    const removeItem = (removeId) => {
        // alert(removeId)
        setSelectedParameters(removeId)
        // let _parameterData = [...parameterData];
        // let _newParameterData = RemoveItemFromArrayByIdValue(_parameterData, removeId);
        // setParameterData(_newParameterData);
        // setPageLoaded(true);
    }

    const [buId, setBUId] = useState(""); const [buText, setBUText] = useState("");
    const [companyId, setCompanyId] = useState(""); const [companyText, setCompanyText] = useState("");
    const [profitCenterId, setProfitCenterId] = useState(""); const [profitCenterText, setProfitCenterText] = useState("");
    const [brandText, setBrandText] = useState(""); const [brand_Ref_Code, setBrand_Ref_Code] = useState("");
    const [productGroupText, setProductGroupText] = useState(""); const [productGroup_Ref_Code, setProductGroup_Ref_Code] = useState("");
    const [plantLocationId, setPlantLocationId] = useState(""); const [plantlocationText, setPlantLocationText] = useState("");
    const [divisionId, setDivisionId] = useState(""); const [divisionText, setDivisionText] = useState("");
    const [customerCategoryId, setCustomerCategoryId] = useState(""); const [customerCategoryText, setCustomerCategoryText] = useState("");
    const [customerId, setCustomerId] = useState(""); const [customerText, setCustomerText] = useState("");
    const [regionId, setRegionId] = useState(""); const [regionText, setRegionText] = useState("");
    const [countryId, setCountryId] = useState(""); const [countryText, setCountryText] = useState("");
    const [materialId, setMaterialId] = useState(""); const [materialText, setMaterialText] = useState("");
    const [gtmId, setGtmId] = useState(""); const [gtmText, setGtmText] = useState("");
    const [subGtmId, setSubGtmId] = useState(""); const [subGtmText, setSubGtmText] = useState("");

    const getBrandAndPg = async (profitCenterId) => {
        // let currentResponse = await DataStore.query(ProfitCenter, profitCenterId);
        let currentResponse = await graphQLFindRecordById('getProfitCenter', profitCenterId);
        if (currentResponse) {
            var product_group_id = currentResponse.product_group_id;
            if (product_group_id !== "") {
                // let currentResponse = await DataStore.query(ProductGroup, product_group_id);
                let currentResponse = await graphQLFindRecordById('getProductGroup', product_group_id);
                if (currentResponse) {
                    setProductGroup_Ref_Code(currentResponse.REF_CODE);
                    setProductGroupText(currentResponse.name + "(" + currentResponse.REF_CODE + ")");
                    let brand_id = currentResponse.brand_id;
                    if (brand_id !== "") {
                        //currentResponse = await DataStore.query(Brand, brand_id); 
                        currentResponse = await graphQLFindRecordById('getBrand', brand_id);
                        setBrand_Ref_Code(currentResponse.REF_CODE);
                        setBrandText(currentResponse.name + "(" + currentResponse.REF_CODE + ")");
                    }
                }
            }
        }
    }


    const onParameterItemSelectedChange = async (type, e) => {
        if (type === 'BU') { if (e != null) { setBUId(e.value); setBUText(e.label); } else { setBUId(""); setBUText(""); } }
        else if (type === 'CO') { if (e != null) { setCompanyId(e.value); setCompanyText(e.label); } else { setCompanyId(""); setCompanyText(""); } }
        else if (type === 'PC') {
            if (e != null) {
                setProfitCenterId(e.value); setProfitCenterText(e.label);
                getBrandAndPg(e.value)
            } else {
                setProfitCenterId(""); setProfitCenterText("");
                setBrandText(""); setBrand_Ref_Code("");
                setProductGroupText(""); setProductGroup_Ref_Code("");
            }
        }
        else if (type === 'PSL') { if (e != null) { setPlantLocationId(e.value); setPlantLocationText(e.label); } else { setPlantLocationId(""); setPlantLocationText(""); } }
        else if (type === 'DIV') { if (e != null) { setDivisionId(e.value); setDivisionText(e.label); } else { setDivisionId(""); setDivisionText(""); } }
        else if (type === 'CUSG') { if (e != null) { setCustomerCategoryId(e.value); setCustomerCategoryText(e.label); onClickCustomerGroup(e); } else { setCustomerCategoryId(""); setCustomerCategoryText(""); } }
        else if (type === 'CUS') { if (e != null) { setCustomerId(e.value); setCustomerText(e.label); } else { setCustomerId(""); setCustomerText(""); } }
        else if (type === 'Cluster') {
            if (e != null) {
                setRegionId(e.value); setRegionText(e.label);// onClickRegion(e);
            } else { setRegionId(""); setRegionText(""); }
        }
        else if (type === 'COUNTRY') { if (e != null) { setCountryId(e.value); setCountryText(e.label); } else { setCountryId(""); setCountryText(""); } }
        else if (type === 'MATERIAL') { if (e != null) { setMaterialId(e.value); setMaterialText(e.label); } else { setMaterialId(""); setMaterialText(""); } }
        else if (type === 'GTM') { if (e != null) { setGtmId(e.value); setGtmText(e.label); onClickGTM(e) } else { setGtmId(""); setGtmText(""); } }
        else if (type === 'SubGTM') { if (e != null) { setSubGtmId(e.value); setSubGtmText(e.label); } else { setSubGtmId(""); setSubGtmText(""); } }
    }

    async function onClickGTM(e) {
        // var gtmResponse = await DataStore.query(SubGTM, (c) => c.gtm_id.eq(e.value), 
        // { sort: s => s.name(SortDirection.ASCENDING) });
        var gtmResponse = graphQLGetAllData('listSubGTMS', [{ gtm_id: { eq: e.value } }]);
        gtmResponse = gtmResponse.sort((a, b) => a.name.localeCompare(b.name));
        if (gtmResponse.length > 0) {
            var myArray = []; myArray = ConvertResponseForSelectWithRefCode(gtmResponse); setAllSubGtm(myArray);
        } else {
            setAllSubGtm([]);
        }
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }


    const BindParameters = async () => {

        let parameterDetailsFinalData = location.state

        //Bind Plan
        //---------------------
        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

        let existingParameterData = await graphQLGetAllData('listEmployeeParameters', [{ employee_id: { eq: loggedEmployeeId } }])

        var existingFormatData = [];
        if (existingParameterData.length > 0) {
            // existingParameterData.map(async (currentItem, index) => {

            //     var buText = '';
            //     if (currentItem.bu_id) {
            //         // await DataStore.query(BIUnit, currentItem.bu_id).then(async (responses) => { 
            //         let responses = await graphQLFindRecordById('getBIUnit', currentItem.bu_id);
            //         buText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //         // }) 
            //     }

            //     var companyText = '';
            //     if (currentItem.company_id) {
            //         // await DataStore.query(Company, currentItem.company_id).then(async (responses) => { 
            //         //     companyText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

            //         let responses = await graphQLFindRecordById('getCompany', currentItem.company_id);
            //         companyText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //     }

            //     var profitCenterText = '';
            //     if (currentItem.profile_center_id) {
            //         // await DataStore.query(ProfitCenter, currentItem.profile_center_id).then(async (responses) => { 
            //         //     profitCenterText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

            //         let responses = await graphQLFindRecordById('getProfitCenter', currentItem.profile_center_id);
            //         profitCenterText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //     }

            //     var plantLocationText = '';
            //     if (currentItem.plant_storage_location_id) {
            //         // await DataStore.query(PlantStorageLocation, currentItem.plant_storage_location_id).then(async (responses) => { 
            //         // plantLocationText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

            //         let responses = await graphQLFindRecordById('getPlantStorageLocation', currentItem.plant_storage_location_id);
            //         plantLocationText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //     }

            //     var divisionText = '';
            //     if (currentItem.division_id) {
            //         // await DataStore.query(Division, currentItem.division_id).then(async (responses) => { 
            //         //     console.log('responses',responses)
            //         //     divisionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

            //         let responses = await graphQLFindRecordById('getDivision', currentItem.division_id);
            //         divisionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //     }

            //     var customerCategoryText = '';
            //     if (currentItem.customer_group_id) {
            //         // await DataStore.query(CustomerCategory, currentItem.customer_group_id).then(async (responses) => { 
            //         //     console.log('responses',responses);
            //         //     customerCategoryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

            //         let responses = await graphQLFindRecordById('getCustomerCategory', currentItem.customer_group_id);
            //         customerCategoryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //     }

            //     var customerText = '';
            //     if (currentItem.customer_id) {
            //         // await DataStore.query(Customer, currentItem.customer_id).then(async (responses) => {
            //         //     console.log('responses', responses);
            //         //     customerText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //         // })

            //         let responses = await graphQLFindRecordById('getCustomer', currentItem.customer_id);
            //         customerText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";

            //     }

            //     var regionText = '';
            //     if (currentItem.region_id) {
            //         //  await DataStore.query(Region, currentItem.region_id).then(async (responses) => { 
            //         //     console.log('responses - region text', responses);
            //         //     regionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

            //         let responses = await graphQLFindRecordById('getRegion', currentItem.region_id);
            //         regionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";

            //     }

            //     var countryText = '';
            //     if (currentItem.country_id) {
            //         // await DataStore.query(Country, currentItem.country_id).then(async (responses) => { 
            //         //     console.log('responses - countryText', responses);
            //         //     countryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

            //         let responses = await graphQLFindRecordById('getCountry', currentItem.country_id);
            //         countryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //     }

            //     var materialText = '';
            //     if (currentItem.material_id) {
            //         // await DataStore.query(Materials, currentItem.material_id).then(async (responses) => { 
            //         //     console.log('responses - materialText', responses);
            //         //     materialText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; })

            //         let responses = await graphQLFindRecordById('getMaterials', currentItem.material_id);
            //         materialText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
            //     }


            //     let brandText = ""
            //     // var brandResponse = await DataStore.query(Brand, (c) => c.REF_CODE.eq(currentItem.brand_REF_CODE))
            //     //  console.log('brandResponse',brandResponse);
            //     var brandResponse = await graphQLGetAllData('listBrands', [{ REF_CODE: { eq: currentItem.brand_REF_CODE } }])
            //       console.log('brandResponse',brandResponse);
            //       console.log('currentItem.brand_REF_CODE',currentItem.brand_REF_CODE)
            //     if (brandResponse.length > 0) { brandText = brandResponse[0].name + "(" + brandResponse[0].REF_CODE + ")"; } else { brandText = "" }


            //     let pGText = "";
            //     //var pGResponse = await DataStore.query(ProductGroup, (c) => c.REF_CODE.eq(currentItem.product_group_REF_CODE))
            //     var pGResponse = await graphQLGetAllData('listProductGroups', [{ REF_CODE: { eq: currentItem.product_group_REF_CODE } }])
            //     if (pGResponse.length > 0) { pGText = pGResponse[0].name + "(" + pGResponse[0].REF_CODE + ")"; } else { pGText = "" }

            //     let gtmText = ""
            //     //  var gtmResponse = await DataStore.query(GTM, (c) => c.REF_CODE.eq(currentItem.gtm_REF_CODE))
            //     //  console.log('gtmResponse',gtmResponse)
            //     var gtmResponse = await graphQLGetAllData('listGTMS', [{ REF_CODE: { eq: currentItem.gtm_REF_CODE } }])
            //     if (gtmResponse.length > 0) { gtmText = gtmResponse[0].name + "(" + gtmResponse[0].REF_CODE + ")"; } else { gtmText = "" }


            //     let subGtmText = ""
            //     // var subGtmResponse = await DataStore.query(SubGTM, (c) => c.REF_CODE.eq(currentItem.sub_gtm_REF_CODE));
            //     //  console.log('subGtmResponse',subGtmResponse)

            //     var subGtmResponse = await graphQLGetAllData('listSubGTMS', [{ REF_CODE: { eq: currentItem.sub_gtm_REF_CODE } }])
            //     if (subGtmResponse.length > 0) { subGtmText = subGtmResponse[0].name + "(" + subGtmResponse[0].REF_CODE + ")"; } else { subGtmText = "" }

            //     existingFormatData.push({
            //         "id": currentItem.id,
            //         "buId": currentItem.bu_id, "buText": buText,
            //         "companyId": currentItem.company_id, "companyText": companyText,
            //         "profitCenterId": currentItem.profile_center_id, "profitCenterText": profitCenterText,
            //         "plantLocationId": currentItem.plant_storage_location_id, "plantlocationText": plantLocationText,
            //         "divisionId": currentItem.division_id, "divisionText": divisionText,
            //         "customerCategoryId": currentItem.customer_group_id, "customerCategoryText": customerCategoryText,
            //         "customerId": currentItem.customer_id, "customerText": customerText,
            //         "regionId": currentItem.region_id, "regionText": regionText,
            //         "countryId": currentItem.country_id, "countryText": countryText,
            //         "materialText": materialText ? materialText : "",
            //         "product_group_REF_CODE": currentItem.product_group_REF_CODE, "brand_REF_CODE": currentItem.brand_REF_CODE,
            //         "product_group_Name": pGText, "brand_REF_Name": brandText,
            //         "gtmId": currentItem.gtm_id, "gtmText": gtmText,
            //         "subGtmId": currentItem.sub_gtm_id, "subGtmText": subGtmText,
            //     })

            //     if (index === existingParameterData.length - 1) {
            //         if (parameterDetailsFinalData !== null) {
            //             setTimeout(() => {
            //                 let merged = [...existingFormatData, ...parameterDetailsFinalData];
            //                 // console.log('merged', merged);
            //                 setParameterData(merged);
            //             }, 200);
            //         }
            //         else {
            //             setTimeout(() => {
            //                 console.log('existingFormatData', existingFormatData);
            //                 setParameterData(existingFormatData);
            //             }, 200);

            //         }
            //         setTimeout(() => {
            //             setPageLoaded(true);
            //             setHideSelection(false)
            //         }, 200);
            //     }
            // });
            for (var i = 0; i < existingParameterData.length; i++) {

                var buText = '';
                if (existingParameterData[i].bu_id) {
                    // await DataStore.query(BIUnit, existingParameterData[i].bu_id).then(async (responses) => { 
                    let responses = allBiUnits.find(c => c.id === existingParameterData[i].bu_id);
                    buText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                    // }) 
                }

                var companyText = '';
                if (existingParameterData[i].company_id) {
                    // await DataStore.query(Company, existingParameterData[i].company_id).then(async (responses) => { 
                    //     companyText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

                    // let responses = await graphQLFindRecordById('getCompany', existingParameterData[i].company_id);
                    let responses = allCompanies.find(c => c.id === existingParameterData[i].company_id)
                    companyText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                }

                var profitCenterText = '';
                if (existingParameterData[i].profile_center_id) {
                    // await DataStore.query(ProfitCenter, existingParameterData[i].profile_center_id).then(async (responses) => { 
                    //     profitCenterText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

                    // let responses = await graphQLFindRecordById('getProfitCenter', existingParameterData[i].profile_center_id);
                    let responses = allProficCenters.find(c => c.id === existingParameterData[i].profile_center_id)
                    profitCenterText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                }

                var plantLocationText = '';
                if (existingParameterData[i].plant_storage_location_id) {
                    // await DataStore.query(PlantStorageLocation, existingParameterData[i].plant_storage_location_id).then(async (responses) => { 
                    // plantLocationText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

                    // let responses = await graphQLFindRecordById('getPlantStorageLocation', existingParameterData[i].plant_storage_location_id);
                    let responses = allStorageLocs.find(c => c.id === existingParameterData[i].plant_storage_location_id)
                    plantLocationText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                }

                var divisionText = '';
                if (existingParameterData[i].division_id) {
                    // await DataStore.query(Division, existingParameterData[i].division_id).then(async (responses) => { 
                    //     divisionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

                    // let responses = await graphQLFindRecordById('getDivision', existingParameterData[i].division_id);
                    let responses = allDivisions.find(c => c.id === existingParameterData[i].division_id)
                    divisionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                }

                var customerCategoryText = '';
                if (existingParameterData[i].customer_group_id) {
                    // await DataStore.query(CustomerCategory, existingParameterData[i].customer_group_id).then(async (responses) => { 
                    //     customerCategoryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

                    // let responses = await graphQLFindRecordById('getCustomerCategory', existingParameterData[i].customer_group_id);
                    let responses = allCustCatgories.find(c => c.id === existingParameterData[i].customer_group_id)
                    customerCategoryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                }

                var customerText = '';
                if (existingParameterData[i].customer_id) {
                    // await DataStore.query(Customer, existingParameterData[i].customer_id).then(async (responses) => {
                    //     customerText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                    // })

                    // let responses = await graphQLFindRecordById('getCustomer', existingParameterData[i].customer_id);
                    let responses = allCustomers.find(c => c.id === existingParameterData[i].customer_id)
                    customerText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";

                }

                var regionText = '';
                if (existingParameterData[i].region_id) {
                    //  await DataStore.query(Region, existingParameterData[i].region_id).then(async (responses) => { 
                    //     regionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

                    // let responses = await graphQLFindRecordById('getRegion', existingParameterData[i].region_id);
                    let responses = allRegions.find(c => c.id === existingParameterData[i].region_id)
                    regionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";

                }

                var countryText = '';
                if (existingParameterData[i].country_id) {
                    // await DataStore.query(Country, existingParameterData[i].country_id).then(async (responses) => { 
                    //     countryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) 

                    // let responses = await graphQLFindRecordById('getCountry', existingParameterData[i].country_id);
                    let responses = allCountries.find(c => c.id === existingParameterData[i].country_id)
                    countryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                }

                var materialText = '';
                if (existingParameterData[i].material_id) {
                    // await DataStore.query(Materials, existingParameterData[i].material_id).then(async (responses) => { 
                    //     materialText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; })

                    // let responses = await graphQLFindRecordById('getMaterials', existingParameterData[i].material_id);
                    let responses = allMaterials.find(c => c.id === existingParameterData[i].material_id)
                    materialText = responses ? responses.name + "(" + responses.REF_CODE + ")" : "";
                }


                let brandText = ""
                // var brandResponse = await DataStore.query(Brand, (c) => c.REF_CODE.eq(existingParameterData[i].brand_REF_CODE))
                // var brandResponse = await graphQLGetAllData('listBrands', [{ REF_CODE: { eq: existingParameterData[i].brand_REF_CODE } }])
                var brandResponse = allBrands.find(c => c.REF_CODE === existingParameterData[i].brand_REF_CODE)
                if (brandResponse) { brandText = brandResponse.name + "(" + brandResponse.REF_CODE + ")"; } else { brandText = "" }


                let pGText = "";
                //var pGResponse = await DataStore.query(ProductGroup, (c) => c.REF_CODE.eq(existingParameterData[i].product_group_REF_CODE))
                // var pGResponse = await graphQLGetAllData('listProductGroups', [{ REF_CODE: { eq: existingParameterData[i].product_group_REF_CODE } }])
                var pGResponse = allProductGroups.find(c => c.REF_CODE === existingParameterData[i].product_group_REF_CODE);
                if (pGResponse) { pGText = pGResponse.name + "(" + pGResponse.REF_CODE + ")"; } else { pGText = "" }

                let gtmText = ""
                //  var gtmResponse = await DataStore.query(GTM, (c) => c.REF_CODE.eq(existingParameterData[i].gtm_REF_CODE))
                // var gtmResponse = await graphQLGetAllData('listGTMS', [{ REF_CODE: { eq: existingParameterData[i].gtm_REF_CODE } }])
                var gtmResponse = allGtms.find(c => c.REF_CODE === existingParameterData[i].gtm_REF_CODE)
                if (gtmResponse) { gtmText = gtmResponse.name + "(" + gtmResponse.REF_CODE + ")"; } else { gtmText = "" }


                let subGtmText = ""
                // var subGtmResponse = await DataStore.query(SubGTM, (c) => c.REF_CODE.eq(existingParameterData[i].sub_gtm_REF_CODE));

                // var subGtmResponse = await graphQLGetAllData('listSubGTMS', [{ REF_CODE: { eq: existingParameterData[i].sub_gtm_REF_CODE } }])

                var subGtmResponse = allSubGtms.find(c => c.REF_CODE === existingParameterData[i].sub_gtm_REF_CODE)
                if (subGtmResponse) { subGtmText = subGtmResponse.name + "(" + subGtmResponse.REF_CODE + ")"; } else { subGtmText = "" }

                existingFormatData.push({
                    "id": existingParameterData[i].id,
                    "buId": existingParameterData[i].bu_id, "buText": buText,
                    "companyId": existingParameterData[i].company_id, "companyText": companyText,
                    "profitCenterId": existingParameterData[i].profile_center_id, "profitCenterText": profitCenterText,
                    "plantLocationId": existingParameterData[i].plant_storage_location_id, "plantlocationText": plantLocationText,
                    "divisionId": existingParameterData[i].division_id, "divisionText": divisionText,
                    "customerCategoryId": existingParameterData[i].customer_group_id, "customerCategoryText": customerCategoryText,
                    "customerId": existingParameterData[i].customer_id, "customerText": customerText,
                    "regionId": existingParameterData[i].region_id, "regionText": regionText,
                    "countryId": existingParameterData[i].country_id, "countryText": countryText,
                    "materialText": materialText ? materialText : "",
                    "product_group_REF_CODE": existingParameterData[i].product_group_REF_CODE, "brand_REF_CODE": existingParameterData[i].brand_REF_CODE,
                    "product_group_Name": pGText, "brand_REF_Name": brandText,
                    "gtmId": existingParameterData[i].gtm_id, "gtmText": gtmText,
                    "subGtmId": existingParameterData[i].sub_gtm_id, "subGtmText": subGtmText,
                })

                if (i === existingParameterData.length - 1) {
                    if (parameterDetailsFinalData !== null) {
                        setTimeout(() => {
                            let merged = [...existingFormatData, ...parameterDetailsFinalData];
                            setParameterData(merged);
                        }, 200);
                    }
                    else {
                        setTimeout(() => {
                            // console.log('existingFormatData', existingFormatData);
                            setParameterData(existingFormatData);
                        }, 200);

                    }
                    setTimeout(() => {
                        setPageLoaded(true);
                        setHideSelection(false)
                    }, 200);
                }
            }
        } else {
            if (parameterDetailsFinalData !== null) {
                setParameterData(parameterDetailsFinalData);
                setPageLoaded(true)
                setHideSelection(true)
            }
            else {
                setPageLoaded(true)
                setHideSelection(false)
            }
        }



        // await DataStore.query(EmployeeParameters, (c) => c.employee_id.eq(loggedEmployeeId)).then((existingParameterData) => {

        //     var existingFormatData = [];
        //     if (existingParameterData.length > 0) {
        //         existingParameterData.map(async (currentItem, index) => {

        //             var buText = '';
        //             if (currentItem.bu_id) { await DataStore.query(BIUnit, currentItem.bu_id).then(async (responses) => { buText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var companyText = '';
        //             if (currentItem.company_id) { await DataStore.query(Company, currentItem.company_id).then(async (responses) => { companyText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var profitCenterText = '';
        //             if (currentItem.profile_center_id) { await DataStore.query(ProfitCenter, currentItem.profile_center_id).then(async (responses) => { profitCenterText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var plantLocationText = '';
        //             if (currentItem.plant_storage_location_id) { await DataStore.query(PlantStorageLocation, currentItem.plant_storage_location_id).then(async (responses) => { plantLocationText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var divisionText = '';
        //             if (currentItem.division_id) { await DataStore.query(Division, currentItem.division_id).then(async (responses) => { divisionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var customerCategoryText = '';
        //             if (currentItem.customer_group_id) { await DataStore.query(CustomerCategory, currentItem.customer_group_id).then(async (responses) => { customerCategoryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var customerText = '';
        //             if (currentItem.customer_id) { await DataStore.query(Customer, currentItem.customer_id).then(async (responses) => { customerText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var regionText = '';
        //             if (currentItem.region_id) { await DataStore.query(Region, currentItem.region_id).then(async (responses) => { regionText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var countryText = '';
        //             if (currentItem.country_id) { await DataStore.query(Country, currentItem.country_id).then(async (responses) => { countryText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             var materialText = '';
        //             if (currentItem.material_id) { await DataStore.query(Materials, currentItem.material_id).then(async (responses) => { materialText = responses ? responses.name + "(" + responses.REF_CODE + ")" : ""; }) }

        //             let brandText = ""
        //             var brandResponse = await DataStore.query(Brand, (c) => c.REF_CODE.eq(currentItem.brand_REF_CODE))
        //             if (brandResponse.length > 0) { brandText = brandResponse[0].name + "(" + brandResponse[0].REF_CODE + ")"; } else { brandText = "" }

        //             let pGText = "";
        //             var pGResponse = await DataStore.query(ProductGroup, (c) => c.REF_CODE.eq(currentItem.product_group_REF_CODE))
        //             if (pGResponse.length > 0) { pGText = pGResponse[0].name + "(" + pGResponse[0].REF_CODE + ")"; } else { pGText = "" }

        //             let gtmText = ""
        //             var gtmResponse = await DataStore.query(GTM, (c) => c.REF_CODE.eq(currentItem.gtm_REF_CODE))
        //             if (gtmResponse.length > 0) { gtmText = gtmResponse[0].name + "(" + gtmResponse[0].REF_CODE + ")"; } else { gtmText = "" }

        //             let subGtmText = ""
        //             var subGtmResponse = await DataStore.query(SubGTM, (c) => c.REF_CODE.eq(currentItem.sub_gtm_REF_CODE))
        //             if (subGtmResponse.length > 0) { subGtmText = subGtmResponse[0].name + "(" + subGtmResponse[0].REF_CODE + ")"; } else { subGtmText = "" }

        //             existingFormatData.push({
        //                 "id": currentItem.id,
        //                 "buId": currentItem.bu_id, "buText": buText,
        //                 "companyId": currentItem.company_id, "companyText": companyText,
        //                 "profitCenterId": currentItem.profile_center_id, "profitCenterText": profitCenterText,
        //                 "plantLocationId": currentItem.plant_storage_location_id, "plantlocationText": plantLocationText,
        //                 "divisionId": currentItem.division_id, "divisionText": divisionText,
        //                 "customerCategoryId": currentItem.customer_group_id, "customerCategoryText": customerCategoryText,
        //                 "customerId": currentItem.customer_id, "customerText": customerText,
        //                 "regionId": currentItem.region_id, "regionText": regionText,
        //                 "countryId": currentItem.country_id, "countryText": countryText,
        //                 "materialText": materialText ? materialText : "",
        //                 "product_group_REF_CODE": currentItem.product_group_REF_CODE, "brand_REF_CODE": currentItem.brand_REF_CODE,
        //                 "product_group_Name": pGText, "brand_REF_Name": brandText,
        //                 "gtmId": currentItem.gtm_id, "gtmText": gtmText,
        //                 "subGtmId": currentItem.sub_gtm_id, "subGtmText": subGtmText,
        //             });

        //             if (index === existingParameterData.length - 1) {
        //                 if (parameterDetailsFinalData !== null) {
        //                     let merged = [...existingFormatData, ...parameterDetailsFinalData];
        //                     setParameterData(merged);
        //                 }
        //                 else {
        //                     setParameterData(existingFormatData);
        //                 }
        //                 setTimeout(() => {
        //                     setPageLoaded(true);
        //                     setHideSelection(false)
        //                 }, 100);
        //             }
        //         });
        //     } else {

        //         if (parameterDetailsFinalData !== null) {
        //             setParameterData(parameterDetailsFinalData);
        //             setPageLoaded(true)
        //             setHideSelection(true)
        //         }
        //         else {
        //             setPageLoaded(true)
        //             setHideSelection(false)
        //         }
        //     }
        // })
        //---------------------
    }

    const SaveParameters = async () => {

        if (parameterData == null || parameterData.length === 0) { toast.error('Please setup your parameters.'); return; }

        //Delete Existing Employee Parameters
        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

        //Employee REF CODE
        let param_sbu_id = ''; let sbu_REF_CODE = '';
        // let currentResponse = await DataStore.query(Employee, loggedEmployeeId); 
        let currentResponse = await graphQLFindRecordById('getEmployee', loggedEmployeeId);
        param_sbu_id = currentResponse ? currentResponse.param_sbu_id : "";
        if (param_sbu_id !== '') {
            //let currentResponse = await DataStore.query(SBU, param_sbu_id); 
            let currentResponse = await graphQLFindRecordById('getSBU', param_sbu_id);
            sbu_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";
        }




        //await DataStore.delete(EmployeeParameters, (c) => c.employee_id.eq(loggedEmployeeId))
        //.then  was starting after above function

        let deleteData = await graphQLGetAllData('listEmployeeParameters', [{ employee_id: { eq: loggedEmployeeId } }]);

        deleteData.forEach(async (p) => {
            await API.graphql({
                query: mutations.deleteEmployeeParameters,
                variables: {
                    input: {
                        id: p.id,
                        _version: p._version,
                    }
                }
            })
        })
        //.then(() => {
        // parameterData.map(async (currentItem, i) => {
        //     //BU REF CODE
        //     // let bUResponse = await DataStore.query(BIUnit, currentItem.buId); 
        //     let bUResponse = await graphQLFindRecordById('getBIUnit', currentItem.buId);
        //     let bU_REF_CODE = bUResponse ? bUResponse.REF_CODE : "";

        //     //Company REF CODE
        //     // let currentResponse = await DataStore.query(Company, currentItem.companyId); 
        //     let currentResponse = await graphQLFindRecordById('getCompany', currentItem.companyId);
        //     let company_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        //     //ProfitCenter REF CODE -> Product Group REF CODE -> Brand REF CODE
        //     let profit_center_REF_CODE = '';
        //     //currentResponse = await DataStore.query(ProfitCenter, currentItem.profitCenterId);
        //     currentResponse = await graphQLFindRecordById('getProfitCenter', currentItem.profitCenterId);
        //     if (currentResponse) {
        //         profit_center_REF_CODE = currentResponse.REF_CODE;
        //     }

        //     //PlantStorageLocation REF CODE
        //     // currentResponse = await DataStore.query(PlantStorageLocation, currentItem.plantLocationId);
        //     currentResponse = await graphQLFindRecordById('getPlantStorageLocation', currentItem.plantLocationId);
        //     let plan_storage_location_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        //     //Material REF CODE
        //     //currentResponse = await DataStore.query(Materials, currentItem.materialId);
        //     currentResponse = await graphQLFindRecordById('getMaterials', currentItem.materialId);
        //     let material_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        //     //Division REF CODE
        //     //currentResponse = await DataStore.query(Division, currentItem.divisionId); 
        //     currentResponse = await graphQLFindRecordById('getDivision', currentItem.divisionId);
        //     let division_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        //     //CustomerCategory REF CODE -> Customer Segment
        //     let customer_segment_REF_CODE = '';
        //     let customer_group_REF_CODE = '';
        //     // currentResponse = await DataStore.query(CustomerCategory, currentItem.customerCategoryId);
        //     currentResponse = await graphQLFindRecordById('getCustomerCategory', currentItem.customerCategoryId);


        //     if (currentResponse) {
        //         customer_group_REF_CODE = currentResponse.REF_CODE;
        //         let customer_segment_id = currentResponse.customer_segment_id;
        //         if (customer_segment_id !== "") {
        //             // currentResponse = await DataStore.query(CustomerSegment, customer_segment_id); 
        //             currentResponse = await graphQLFindRecordById('getCustomerSegment', customer_segment_id);

        //             customer_segment_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        //         }
        //     }

        //     //Customer REF CODE
        //     //currentResponse = await DataStore.query(Customer, currentItem.customerId); 
        //     currentResponse = await graphQLFindRecordById('getCustomer', currentItem.customerId);
        //     let customer_REF_CODE = currentResponse ? currentResponse.name : "";

        //     //Country REF CODE
        //     //currentResponse = await DataStore.query(Country, currentItem.countryId); 
        //     currentResponse = await graphQLFindRecordById('getCountry', currentItem.countryId);
        //     let country_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        //     //Region REF CODE
        //     //currentResponse = await DataStore.query(Region, currentItem.regionId); 
        //     currentResponse = await graphQLFindRecordById('getRegion', currentItem.regionId);
        //     let region_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        //     //GTM REF CODE
        //     //currentResponse = await DataStore.query(GTM, currentItem.gtmId); 
        //     currentResponse = await graphQLFindRecordById('getGTM', currentItem.gtmId);
        //     let gtm_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        //     //SUbGTM REF CODE
        //     //currentResponse = await DataStore.query(SubGTM, currentItem.subGtmId); 
        //     currentResponse = await graphQLFindRecordById('getSubGTM', currentItem.subGtmId);
        //     let subGtm_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";


        //     // //Save Record Now.
        //     // await DataStore.save(
        //     //     new EmployeeParameters({
        //     //         "employee_id": loggedEmployeeId,
        //     //         "bu_id": currentItem.buId,
        //     //         "company_id": currentItem.companyId,
        //     //         "profile_center_id": currentItem.profitCenterId,
        //     //         "plant_storage_location_id": currentItem.plantLocationId,
        //     //         "division_id": currentItem.divisionId,
        //     //         "customer_group_id": currentItem.customerCategoryId,
        //     //         "customer_id": currentItem.customerId,
        //     //         "country_id": currentItem.countryId,
        //     //         "region_id": currentItem.regionId,
        //     //         "material_id": currentItem.materialId,
        //     //         "bu_REF_CODE": bU_REF_CODE,
        //     //         "company_REF_CODE": company_REF_CODE,
        //     //         "profit_center_REF_CODE": profit_center_REF_CODE,
        //     //         "division_REF_CODE": division_REF_CODE,
        //     //         "customer_group_REF_CODE": customer_group_REF_CODE,
        //     //         "customer_REF_CODE": customer_REF_CODE,
        //     //         "country_REF_CODE": country_REF_CODE,
        //     //         "region_REF_CODE": region_REF_CODE,
        //     //         "plan_storage_location_REF_CODE": plan_storage_location_REF_CODE,
        //     //         "customer_segment_REF_CODE": customer_segment_REF_CODE,
        //     //         "product_group_REF_CODE": currentItem.product_group_REF_CODE,
        //     //         "brand_REF_CODE": currentItem.brand_REF_CODE,
        //     //         "sbu_REF_CODE": sbu_REF_CODE,
        //     //         "material_REF_CODE": material_REF_CODE,
        //     //         "gtm_id": currentItem.gtmId,
        //     //         "gtm_REF_CODE": gtm_REF_CODE,
        //     //         "sub_gtm_id": currentItem.subGtmId,
        //     //         "sub_gtm_REF_CODE": subGtm_REF_CODE
        //     //     })
        //     // );

        //     await API.graphql(
        //         graphqlOperation(mutations.createEmployeeParameters, {
        //             input: {
        //                 "employee_id": loggedEmployeeId,
        //                 "bu_id": currentItem.buId,
        //                 "company_id": currentItem.companyId,
        //                 "profile_center_id": currentItem.profitCenterId,
        //                 "plant_storage_location_id": currentItem.plantLocationId,
        //                 "division_id": currentItem.divisionId,
        //                 "customer_group_id": currentItem.customerCategoryId,
        //                 "customer_id": currentItem.customerId,
        //                 "country_id": currentItem.countryId,
        //                 "region_id": currentItem.regionId,
        //                 "material_id": currentItem.materialId,
        //                 "bu_REF_CODE": bU_REF_CODE,
        //                 "company_REF_CODE": company_REF_CODE,
        //                 "profit_center_REF_CODE": profit_center_REF_CODE,
        //                 "division_REF_CODE": division_REF_CODE,
        //                 "customer_group_REF_CODE": customer_group_REF_CODE,
        //                 "customer_REF_CODE": customer_REF_CODE,
        //                 "country_REF_CODE": country_REF_CODE,
        //                 "region_REF_CODE": region_REF_CODE,
        //                 "plan_storage_location_REF_CODE": plan_storage_location_REF_CODE,
        //                 "customer_segment_REF_CODE": customer_segment_REF_CODE,
        //                 "product_group_REF_CODE": currentItem.product_group_REF_CODE,
        //                 "brand_REF_CODE": currentItem.brand_REF_CODE,
        //                 "sbu_REF_CODE": sbu_REF_CODE,
        //                 "material_REF_CODE": material_REF_CODE,
        //                 "gtm_id": currentItem.gtmId,
        //                 "gtm_REF_CODE": gtm_REF_CODE,
        //                 "sub_gtm_id": currentItem.subGtmId,
        //                 "sub_gtm_REF_CODE": subGtm_REF_CODE
        //             }
        //         })
        //     );

        //     if (i === parameterData.length - 1) {
        //         setPageLoaded(false)
        //         toast.success('Saved.');
        //         BindParameters().then(() => {
        //             navigateTo("/employee/parameters")
        //             navigateTo(0)
        //         })
        //     }
        // });
        for (var i = 0; i < parameterData.length; i++) {
            //BU REF CODE
            // let bUResponse = await DataStore.query(BIUnit, parameterData[i].buId); 
            let bUResponse = await graphQLFindRecordById('getBIUnit', parameterData[i].buId);
            let bU_REF_CODE = bUResponse ? bUResponse.REF_CODE : "";

            //Company REF CODE
            // let currentResponse = await DataStore.query(Company, parameterData[i].companyId); 
            let currentResponse = await graphQLFindRecordById('getCompany', parameterData[i].companyId);
            let company_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

            //ProfitCenter REF CODE -> Product Group REF CODE -> Brand REF CODE
            let profit_center_REF_CODE = '';
            //currentResponse = await DataStore.query(ProfitCenter, parameterData[i].profitCenterId);
            currentResponse = await graphQLFindRecordById('getProfitCenter', parameterData[i].profitCenterId);
            if (currentResponse) {
                profit_center_REF_CODE = currentResponse.REF_CODE;
            }

            //PlantStorageLocation REF CODE
            // currentResponse = await DataStore.query(PlantStorageLocation, parameterData[i].plantLocationId);
            currentResponse = await graphQLFindRecordById('getPlantStorageLocation', parameterData[i].plantLocationId);
            let plan_storage_location_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

            //Material REF CODE
            //currentResponse = await DataStore.query(Materials, parameterData[i].materialId);
            currentResponse = await graphQLFindRecordById('getMaterials', parameterData[i].materialId);
            let material_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

            //Division REF CODE
            //currentResponse = await DataStore.query(Division, parameterData[i].divisionId); 
            currentResponse = await graphQLFindRecordById('getDivision', parameterData[i].divisionId);
            let division_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

            //CustomerCategory REF CODE -> Customer Segment
            let customer_segment_REF_CODE = '';
            let customer_group_REF_CODE = '';
            // currentResponse = await DataStore.query(CustomerCategory, parameterData[i].customerCategoryId);
            currentResponse = await graphQLFindRecordById('getCustomerCategory', parameterData[i].customerCategoryId);


            if (currentResponse) {
                customer_group_REF_CODE = currentResponse.REF_CODE;
                let customer_segment_id = currentResponse.customer_segment_id;
                if (customer_segment_id !== "") {
                    // currentResponse = await DataStore.query(CustomerSegment, customer_segment_id); 
                    currentResponse = await graphQLFindRecordById('getCustomerSegment', customer_segment_id);

                    customer_segment_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

                }
            }

            //Customer REF CODE
            //currentResponse = await DataStore.query(Customer, parameterData[i].customerId); 
            currentResponse = await graphQLFindRecordById('getCustomer', parameterData[i].customerId);
            let customer_REF_CODE = currentResponse ? currentResponse.name : "";

            //Country REF CODE
            //currentResponse = await DataStore.query(Country, parameterData[i].countryId); 
            currentResponse = await graphQLFindRecordById('getCountry', parameterData[i].countryId);
            let country_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

            //Region REF CODE
            //currentResponse = await DataStore.query(Region, parameterData[i].regionId); 
            currentResponse = await graphQLFindRecordById('getRegion', parameterData[i].regionId);
            let region_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

            //GTM REF CODE
            //currentResponse = await DataStore.query(GTM, parameterData[i].gtmId); 
            currentResponse = await graphQLFindRecordById('getGTM', parameterData[i].gtmId);
            let gtm_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

            //SUbGTM REF CODE
            //currentResponse = await DataStore.query(SubGTM, parameterData[i].subGtmId); 
            currentResponse = await graphQLFindRecordById('getSubGTM', parameterData[i].subGtmId);
            let subGtm_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";


            // //Save Record Now.
            // await DataStore.save(
            //     new EmployeeParameters({
            //         "employee_id": loggedEmployeeId,
            //         "bu_id": parameterData[i].buId,
            //         "company_id": parameterData[i].companyId,
            //         "profile_center_id": parameterData[i].profitCenterId,
            //         "plant_storage_location_id": parameterData[i].plantLocationId,
            //         "division_id": parameterData[i].divisionId,
            //         "customer_group_id": parameterData[i].customerCategoryId,
            //         "customer_id": parameterData[i].customerId,
            //         "country_id": parameterData[i].countryId,
            //         "region_id": parameterData[i].regionId,
            //         "material_id": parameterData[i].materialId,
            //         "bu_REF_CODE": bU_REF_CODE,
            //         "company_REF_CODE": company_REF_CODE,
            //         "profit_center_REF_CODE": profit_center_REF_CODE,
            //         "division_REF_CODE": division_REF_CODE,
            //         "customer_group_REF_CODE": customer_group_REF_CODE,
            //         "customer_REF_CODE": customer_REF_CODE,
            //         "country_REF_CODE": country_REF_CODE,
            //         "region_REF_CODE": region_REF_CODE,
            //         "plan_storage_location_REF_CODE": plan_storage_location_REF_CODE,
            //         "customer_segment_REF_CODE": customer_segment_REF_CODE,
            //         "product_group_REF_CODE": parameterData[i].product_group_REF_CODE,
            //         "brand_REF_CODE": parameterData[i].brand_REF_CODE,
            //         "sbu_REF_CODE": sbu_REF_CODE,
            //         "material_REF_CODE": material_REF_CODE,
            //         "gtm_id": parameterData[i].gtmId,
            //         "gtm_REF_CODE": gtm_REF_CODE,
            //         "sub_gtm_id": parameterData[i].subGtmId,
            //         "sub_gtm_REF_CODE": subGtm_REF_CODE
            //     })
            // );

            await API.graphql(
                graphqlOperation(mutations.createEmployeeParameters, {
                    input: {
                        "employee_id": loggedEmployeeId,
                        "bu_id": parameterData[i].buId,
                        "company_id": parameterData[i].companyId,
                        "profile_center_id": parameterData[i].profitCenterId,
                        "plant_storage_location_id": parameterData[i].plantLocationId,
                        "division_id": parameterData[i].divisionId,
                        "customer_group_id": parameterData[i].customerCategoryId,
                        "customer_id": parameterData[i].customerId,
                        "country_id": parameterData[i].countryId,
                        "region_id": parameterData[i].regionId,
                        "material_id": parameterData[i].materialId,
                        "bu_REF_CODE": bU_REF_CODE,
                        "company_REF_CODE": company_REF_CODE,
                        "profit_center_REF_CODE": profit_center_REF_CODE,
                        "division_REF_CODE": division_REF_CODE,
                        "customer_group_REF_CODE": customer_group_REF_CODE,
                        "customer_REF_CODE": customer_REF_CODE,
                        "country_REF_CODE": country_REF_CODE,
                        "region_REF_CODE": region_REF_CODE,
                        "plan_storage_location_REF_CODE": plan_storage_location_REF_CODE,
                        "customer_segment_REF_CODE": customer_segment_REF_CODE,
                        "product_group_REF_CODE": parameterData[i].product_group_REF_CODE,
                        "brand_REF_CODE": parameterData[i].brand_REF_CODE,
                        "sbu_REF_CODE": sbu_REF_CODE,
                        "material_REF_CODE": material_REF_CODE,
                        "gtm_id": parameterData[i].gtmId,
                        "gtm_REF_CODE": gtm_REF_CODE,
                        "sub_gtm_id": parameterData[i].subGtmId,
                        "sub_gtm_REF_CODE": subGtm_REF_CODE
                    }
                })
            );

            if (i === parameterData.length - 1) {
                setPageLoaded(false)
                toast.success('Saved.');
                BindParameters().then(() => {
                    navigateTo("/employee/parameters")
                    navigateTo(0)
                })
            }
        }
        // })

    }

    const confirmDelete = async () => {
        const filteredRecords = parameterData.filter(record => !selectedParameters.some(selected => selected.id === record.id));
        alertify.confirm('Confirmation', 'Do you want to delete this record?', async function () {
            setDeleteParameter(true)

            for (var i = 0; i < selectedParameters.length; i++) {
                //var originalObject = await DataStore.query(EmployeeParameters, selectedParameters[i].id);
                var originalObject = await graphQLFindRecordById('getEmployeeParameters', selectedParameters[i].id);
                if (originalObject) {
                    //await DataStore.delete(originalObject)
                    await API.graphql({
                        query: mutations.deleteEmployeeParameters,
                        variables: {
                            input: {
                                id: originalObject.id,
                                _version: originalObject._version,
                            }
                        }
                    });

                }


                if (i === selectedParameters.length - 1) {
                    toast.success("Deleted");
                    setDeleteParameter(false)
                    // await BindParameters()
                    setSelectedParameters([])
                    setParameterData(filteredRecords);
                }
            }
        }
            , function () { setSelectedParameters([]) });
    }


    const getMasterData = async () => {
        setDataLoaded(false);

        var SBUResponses = await graphQLGetAllData('listSBUS')
        SBUResponses = SBUResponses.sort((a, b) => a.name.localeCompare(b.name));
        setAllSBUs(SBUResponses);


        var buResponses = await graphQLGetAllData('listBIUnits')
        buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));
        setAllBiUnits(buResponses);

        var regionResponses = await graphQLGetAllData('listRegions')
        regionResponses = regionResponses.sort((a, b) => a.name.localeCompare(b.name));
        setAllRegions(regionResponses)

        var countryResponses = await graphQLGetAllData('listCountries')
        countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));
        setAllCountries(countryResponses)

        var brandResponses = await graphQLGetAllData('listBrands')
        brandResponses = brandResponses.sort((a, b) => a.name.localeCompare(b.name));
        setAllBrands(brandResponses);

        var customerCategoryResponse = await graphQLGetAllData('listCustomerCategories')
        customerCategoryResponse = customerCategoryResponse.sort((a, b) => a.name.localeCompare(b.name));
        setAllCustCategories(customerCategoryResponse)

        var customerResponse = await graphQLGetAllData('listCustomers')
        customerResponse = customerResponse.sort((a, b) => a.name.localeCompare(b.name));
        setAllCustomers(customerResponse)

        var gtmResponse = await graphQLGetAllData('listGTMS');
        setAllGtms(gtmResponse)

        var subGtmResponses = await graphQLGetAllData('listSubGTMS');
        setAllSubGtms(subGtmResponses)

        var materialResponses = await graphQLGetAllData('listMaterials');
        setAllMaterials(materialResponses)

        var divisionResponses = await graphQLGetAllData('listDivisions');
        setAllDivisions(divisionResponses)

        var profitCenterResponses = await graphQLGetAllData('listProfitCenters');
        setAllProfitCenters(profitCenterResponses);

        var plantStorageLocResponses = await graphQLGetAllData('listPlantStorageLocations');
        setAllStorageLocs(plantStorageLocResponses);

        var companyResponses = await graphQLGetAllData('listCompanies');
        setAllCompanies(companyResponses);

        var productGroupResponses = await graphQLGetAllData('listProductGroups');
        setAllProductGroups(productGroupResponses)

        setDataLoaded(true);
    }

    useEffect(() => {
        if (isDataLoaded === true) {
            onLoad()
            BindList();
        }
    }, [isDataLoaded]);

    useEffect(() => {
        getMasterData();
    }, [])

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div
                        className="flex flex-1 flex-wrap flex-row justify-center align-center grid 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 gap-4 pl-5 pr-5 contract-grid-wrapper mt-5 p-5">
                        <div className="col-span-12">
                            <div className="bg-white dark:bg-[#2A2E32] rounded-md shadow p-5 py-5 ">
                                <form autoComplete="off">
                                    <div className="flex justify-between items-center mb-10  mt-3  mb-3">
                                        <h2 className="page-title">My Parameters ({parameterData.length})</h2>
                                        <div className='flex'>
                                            <h2 className="page-title mr-5">{"SBU :" + " " + sbuName}</h2>
                                            <div
                                                className="w-32 py-1.5 px-4 border border-[#4FB155] text-[#4FB155] transition ease-in duration-200 text-[14px] text-center font-medium  
                                            focus:outline-none leading-6  justify-center rounded-lg dark:border-[#4FB155] rounded-lg cursor-pointer relative">
                                                <div className="absolute cursor-pointer left-0 top-0  opacity-0 w-32 h-[40px] "><input
                                                    onChange={(e) => { fileHandler(e,) }}
                                                    accept=".csv"
                                                    multiple="multiple" type="file" className='w-32 cursor-pointer' /></div>
                                                <i className='btnbtn import_icon mr-6'></i> Import</div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-5 mt-5 lg:grid-cols-5 2xl:grid-cols-5 gap-4 h-auto divSmallControls">
                                        <Select isClearable={true} options={buList} onChange={(e) => { onParameterItemSelectedChange('BU', e) }} placeholder={"BU"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />
                                        <Select isClearable={true} options={companyList} onChange={(e) => { onParameterItemSelectedChange('CO', e) }} placeholder={"Company Code"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />
                                        <Select isClearable={true} options={regionList} onChange={(e) => { onParameterItemSelectedChange('Cluster', e) }} placeholder={"Cluster"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />
                                        <Select isClearable={true} options={countryList} onChange={(e) => { onParameterItemSelectedChange('COUNTRY', e) }} placeholder={"Country"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />
                                        <Select isClearable={true} options={profitCenterList} onChange={(e) => { onParameterItemSelectedChange('PC', e) }} placeholder={"Profit Center"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />
                                    </div>

                                    <div className="grid grid-cols-5 mt-5 lg:grid-cols-5 2xl:grid-cols-5 gap-4 h-auto divSmallControls">
                                        <Select isClearable={true} options={plantStorageLocationList} onChange={(e) => { onParameterItemSelectedChange('PSL', e) }} placeholder={"Plant Storage"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />
                                        <Select isClearable={true} options={divisionList} onChange={(e) => { onParameterItemSelectedChange('DIV', e) }} placeholder={"Division"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />
                                        {!checkEmployeeTeamType() && <Select isClearable={true} options={customerCategoryList} onChange={(e) => { onParameterItemSelectedChange('CUSG', e) }} placeholder={"Customer Group"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />}
                                        {!checkEmployeeTeamType() && <Select isClearable={true} options={customerList} onChange={(e) => { onParameterItemSelectedChange('CUS', e) }} placeholder={"Customer"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />}
                                        <Select isClearable={true} options={materialList} onChange={(e) => { onParameterItemSelectedChange('MATERIAL', e) }} placeholder={"Material No"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />

                                        {!checkEmployeeTeamType() && <Select isClearable={true} options={allGtm} onChange={(e) => { onParameterItemSelectedChange('GTM', e) }} placeholder={"GTM"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />}
                                        {!checkEmployeeTeamType() && <Select isClearable={true} options={allSubGtm} onChange={(e) => { onParameterItemSelectedChange('SubGTM', e) }} placeholder={"Sub GTM"} className="my-react-select-container  w-full" classNamePrefix="my-react-select" />}
                                        <button type="button" onClick={() => addItem()} className="py-1 px-2 text-[#4FB155] transition ease-in duration-200 width-[200] text-[14px] text-center font-medium focus:outline-none leading-6  justify-center rounded-lg border border-[#4FB155] rounded-md">Add</button>

                                    </div>

                                    {
                                        selectedParameters.length > 0 ?
                                            <div style={{ marginLeft: '96%' }}>
                                                <button
                                                    type="button"
                                                    onClick={() => confirmDelete()}
                                                    className="py-1 px-2 text-[#4FB155] text-[14px] text-center font-medium focus:outline-none leading-6  justify-center rounded-lg border border-[#4FB155] rounded-md"
                                                >Delete</button>
                                            </div>
                                            : ""
                                    }


                                    <div className="dashboard-main-wrapper rounded-lg mt-5 divSmallTableControls ">
                                        {
                                            isPageLoaded &&
                                            <DataTable value={parameterData} showGridlines selection={selectedParameters} onSelectionChange={(e) => setSelectedParameters(e.value)}>
                                                {
                                                    // !hideSelection &&
                                                    <Column selectionMode={"multiple"} headerStyle={{ width: '3rem' }}></Column>
                                                }
                                                <Column field="buText" header="BU" ></Column>
                                                <Column field="regionText" header="Cluster" ></Column>
                                                <Column field="countryText" header="Country" ></Column>
                                                <Column field="brand_REF_Name" header="Brand" ></Column>
                                                {!checkEmployeeTeamType() && <Column field="gtmText" header="GTM" ></Column>}
                                                {!checkEmployeeTeamType() && <Column field="subGtmText" header="Sub GTM" ></Column>}
                                                <Column field="product_group_Name" header="Product Group" ></Column>
                                                <Column field="profitCenterText" header="Profit Center" ></Column>
                                                <Column field="companyText" header="Company Code" ></Column>
                                                <Column field="plantlocationText" header="Plant Storage Location" ></Column>
                                                <Column field="divisionText" header="Division" ></Column>
                                                {!checkEmployeeTeamType() && <Column field="customerCategoryText" header="Customer Group" ></Column>}
                                                {!checkEmployeeTeamType() && <Column field="customerText" header="Customer" ></Column>}
                                                <Column field="materialText" header="Material" ></Column>
                                                {/* <Column header="Delete" headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={deleteBodyTemplate} /> */}
                                            </DataTable>
                                        }

                                        {
                                            !isPageLoaded &&
                                            <div className='text-center py-8' >
                                                <ProgressSpinner style={{ width: '80px', height: '80px', zIndex: '9999' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".8s" />
                                            </div>
                                        }
                                    </div>

                                    {
                                        isShowImportPopup === true ?
                                            <div style={{ width: "44.271vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="editPopupCont" className="sidenav dashboard-main-wrapper">
                                                <div className="popup_header relative ">
                                                    <img src={popupHeader} alt="" />
                                                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                                                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                                            <div className="text-md">Parameters</div>
                                                            <div><Link onClick={() => setIsShowImportPopup(false)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                                        </div>
                                                        <div className="px-6 mt-2">
                                                            <div className="text-sm text-[#80C7A2]">Parameters</div>
                                                            <div className="text-2xl mt-6">{"Select Parameters"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-6 height-custtom" >

                                                    <div className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  ">
                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">BU <label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label>
                                                        </label>
                                                        <div>
                                                            <Select
                                                                value={buCode}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setBUCode(e);
                                                                    setBUParamCodeId(e.value);
                                                                }}
                                                                placeholder={"Select BU"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Company Code</label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={companyCode}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setCompanyCode(e);
                                                                    setCompanyParamCodeId(e.value);
                                                                }}
                                                                placeholder={"Select Company Code"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Region</label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={region}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setRegion(e);
                                                                    setParamRegionId(e.value);
                                                                }}
                                                                placeholder={"Select Region"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Country </label>
                                                        </div>

                                                        <div>
                                                            <Select
                                                                value={country}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setCountry(e);
                                                                    setParamCountryId(e.value);
                                                                }}
                                                                placeholder={"Select Country"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Profit Center</label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={profitCenter}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setProfitCenter(e);
                                                                    setParamProfitCenterId(e.value);
                                                                }}
                                                                placeholder={"Select Profit Center"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Plant Storage </label>
                                                        </div>

                                                        <div>
                                                            <Select
                                                                value={plantStorage}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setPlantStorage(e);
                                                                    setParamPlantStorageId(e.value);
                                                                }}
                                                                placeholder={"Select Plant Storage"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Division</label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={division}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setDivision(e);
                                                                    setParamDivisionId(e.value);
                                                                }}
                                                                placeholder={"Select Division"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Customer Group</label>
                                                        </div>

                                                        <div>
                                                            <Select
                                                                value={customerGroup}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setCustomerGroup(e);
                                                                    setParamCustomerGroupId(e.value);
                                                                }}
                                                                placeholder={"Select Customer Group"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Customer</label>
                                                        </div>

                                                        <div>
                                                            <Select
                                                                value={customer}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setCustomer(e);
                                                                    setParamCustomerId(e.value);
                                                                }}
                                                                placeholder={"Select Customer"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Material No</label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={materialNo}
                                                                options={parametersDropDownArray}
                                                                onChange={e => {
                                                                    setMaterialNo(e);
                                                                    setParamMaterialNo(e.value);
                                                                }}
                                                                placeholder={"Select Material No"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                                    <ul className="flex justify-end gap-3">
                                                        <li><button onClick={() => {
                                                            setIsShowImportPopup(false)
                                                        }} className="bg-[#FFFFFF] border border-[#C6CBD2] py-2.5 px-3 text-black text-[15px] rounded-md active addClass dark:bg-[#44494E] dark:text-[#FFFFFF] dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white">Cancel</button></li>
                                                        <li><button
                                                            type='button'
                                                            onClick={() => {
                                                                onClickParameterPreview();
                                                            }} className="bg-btn-blue border border-[#C6CBD2] text-[#fff] py-2.5 px-3  text-[15px] rounded-md active addClass dark:bg-[#44494E] dark:text-[#FFFFFF] dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white">Preview</button></li>
                                                    </ul>
                                                </div>
                                            </div> : null
                                    }
                                </form>
                            </div>

                            {
                                isPageLoaded &&
                                <div className="grow flex justify-center gap-3 mb-3 mt-5 ">
                                    <Link to='/employee/dashboard/business/planning' className="py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white">Cancel</Link>
                                    {
                                        parameterData.length > 0 ?
                                            <button onClick={async () => await SaveParameters()} type="button" className="bg-btn-blue py-2 px-6 text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Save</button>
                                            :
                                            <button disabled={parameterData.length > 0 ? false : true} type="button" className="disabled bg-[#e1e1e1] py-2 px-6 text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Save</button>

                                    }
                                </div>
                            }

                            {
                                <Dialog visible={isDeleteParameter} style={{ width: '40vw' }} showHeader={false}>
                                    <div className='text-center py-8' ><ProgressSpinner /></div>
                                    <h2 className="page-title text-center">Please wait while deleting parameters.</h2>
                                </Dialog>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );

}